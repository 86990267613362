.search-results {
  background-color: $neutral-white;
  flex: 1;
  padding: 2.25rem .25rem;

  @media ($min-lg) {
    padding: 3rem 3.75rem;
  }

  &__container {
    width: 100%;
    max-width: calc(1150px + 2rem);
    margin-inline: auto;
    padding-inline: .5rem;
  }


  &__title {
    color: $brand-primary-dark;
    font-size: 1.25rem;
    font-weight: 900;
    line-height: 1rem;
    margin-block-end: 1.5rem;
    text-transform: uppercase;

    @media ($min-lg) {
      font-size: 1.625rem;
      line-height: 2rem;
      margin-block-end: 2rem;
    }
  }

  &__form {
    display: flex;
    align-items: center;
    border-radius: 30px;
    margin-block-end: 1.5rem;

    @media ($min-lg) {
      margin-block-end: 2rem;
    }

    &--header {
      margin: 0;
      align-items: center;
      display: none;
      overflow: hidden;
      border: 0;
      border-radius: 0;
    }

    & input[type="text"] {
      color: $brand-primary-dark-40;
      font-size: .875rem;
      font-weight: 400;
      line-height: 1.125rem;
      width: 100%;
      padding-inline-end: 54px;

      @media ($min-lg) {
        font-size: 1rem;
        bottom: 0.5rem;
        padding-inline-end: 70px;
      }

      &:focus,
      &:active {
        outline: none;
      }

      position: absolute;
      border: 0;
      line-height: 1;
      color: $neutral-dark;
      bottom: 0.5rem;
      font-size: 0.875rem;

      &:focus {
        outline: 0;
      }

      &::placeholder {
        opacity: 0;
      }

      &:not(:placeholder-shown)~.search-results__label,
      &:focus~.search-results__label {
        color: $brand-primary-medium;
        font-size: .625rem;
        font-weight: 700;
        position: relative;
        transform: translate(0, -0.75rem);
      }

      &:not(:placeholder-shown)~.search-results__label--header,
      &:focus~.search-results__label--header {
        color: $neutral-dark-10;
        font-size: .625rem;
        font-weight: 700;
        position: relative;
        transform: translate(0, -0.75rem);
      }


    }

    &-button {
      border: 0;
      background-color: transparent;
      padding: 4px 6px;
      transition: all 0.3s;
      border-radius: 50%;
      line-height: 0;
      position: absolute;
      right: 0;

      @media($min-lg) {
        right: 10px;
      }
    }
  }

  &__input-container {
    display: flex;
    align-items: center;
    position: relative;
    background: $neutral-white;
    border: 1px solid $neutral-lightest;
    border-radius: 10px;
    cursor: text;
    padding: 6px 16px;
    width: 636px;
    height: 48px;

    &--header {
      border-radius: 0;
      border: 0;
      border-bottom: 1px solid rgba($color: $neutral-medium, $alpha: .4);
      width: 100%;
      padding: 0;
    }

  }

  &__label {
    font-weight: 500;
    font-size: .75rem;
    line-height: .9375rem;
    color: $brand-primary-medium;
    text-transform: uppercase;
    transition: all 0.3s;
    z-index: 10;

    &--header {
      font-weight: 700;
      font-size: .875rem;
      line-height: 1.125rem;
      color: $neutral-dark-10;
    }
  }

  &__no-result p {
    font-size: 1.375rem;
    line-height: 1.6;
    text-align: center;

    @media ($min-lg) {
      font-size: 1.5625rem;
    }
  }

  &__quantity-text {
    color: $brand-secondary-dark;
    font-size: 1rem;
    font-weight: 800;
    line-height: 1.3125rem;
    margin-bottom: 2.5rem;
    text-transform: uppercase;

    @media($min-lg) {
      font-size: 1.25rem;
      line-height: 1.625rem;
    }
  }

  &__results-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 1.875rem;
    padding-block: 1.875rem;

    @media ($min-lg) {
      padding-block: 2rem;
      gap: 30px;
    }
  }

  &__result {
    text-decoration: none;
    border-bottom: 1px solid #C4C4C4;
    padding-block-end: 20px;

    @media ($min-lg) {
      padding-block-end: 30px;
    }

    &-title {
      color: $brand-primary-dark;
      font-size: 1rem;
      font-weight: 700;
      line-height: 1.25rem;
      text-decoration-line: underline;
      margin-bottom: 10px;

      @media ($min-lg) {
        font-size: 1.25rem;
        line-height: 1.5rem;
        margin-bottom: 20px;

      }
    }

    &-description {
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.5rem;
      color: $neutral-dark;

      & em {
        font-weight: 700;
        font-style: normal;
      }

      @media ($min-lg) {
        font-size: 1.125rem;
      }
    }
  }

  &__pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #C4C4C4;

    max-width: 160px;
    margin: 0 auto;
    border-radius: 20px;
    overflow: hidden;

    background-color: $neutral-white;
  }

  &__actual-page {
    flex: 1;
    font-size: .8125rem;
    line-height: 1.3;
    color: $neutral-darkest;
    text-align: center;
  }

  &__previous-page,
  &__next-page {
    padding: 14px 18px;
    line-height: 0;
    transition: all 0.2s;
    cursor: pointer;


    &:hover {
      background-color: $brand-primary-dark;
      border-radius: 3px;

      .search-results__previous-arrow:before,
      .search-results__previous-arrow:after,
      .search-results__next-arrow:before,
      .search-results__next-arrow:after {
        background-color: $neutral-white;
      }
    }

    &--disabled {
      pointer-events: none;

      .search-results__previous-arrow:before,
      .search-results__previous-arrow:after,
      .search-results__next-arrow:before,
      .search-results__next-arrow:after {
        background-color: $neutral-medium;
      }
    }
  }

  &__previous-arrow,
  &__next-arrow {
    width: 6px;
    height: 12px;
    display: inline-block;
    position: relative;

    &:before,
    &:after {
      left: 0;
      position: absolute;
      width: 8px;
      height: 2px;
      background-color: $brand-primary-darkest;
      display: inline-block;
      transition: all .2s ease;
      border-radius: 25px;
    }

    &:after {
      content: "";
      top: 8px;
    }

    &:before {
      content: "";
      top: 3px;
    }
  }

  &__previous-arrow {
    &:after {
      transform: rotate(45deg);
    }

    &:before {
      transform: rotate(-45deg);
    }
  }

  &__next-arrow {
    &:after {
      transform: rotate(135deg);
    }

    &:before {
      transform: rotate(-135deg);
    }
  }
}