// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

// BREAKPOINTS - MOBILE FIRST
$xs: 360px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;
$min-xs: "min-width: #{$xs}";
$min-sm: "min-width: #{$sm}";
$min-md: "min-width: #{$md}";
$min-lg: "min-width: #{$lg}";
$min-xl: "min-width: #{$xl}";
$min-hd: "min-width: #{$xxl}";
$mobile-default: "max-width: #{$lg}";

$max-width: 1280px;

// -----------------------------------------------------------------------------
// Gênese design system - Safra
// -----------------------------------------------------------------------------

// BRAND COLORS > PRIMARY COLORS
$brand-primary-darkest: #002c37;
$brand-primary-dark: #0a4a5b;
$brand-primary-medium: #00586f;
$brand-primary-light: #11728b;
$brand-primary-lightest: #7fabb7;

// BRAND COLORS > SECONDARY COLORS
$brand-secondary-darkest: #075060;
$brand-secondary-dark: #0b8099;
$brand-secondary-medium: #0ea0c0;
$brand-secondary-light: #56bcd2;
$brand-secondary-lightest: #9ed9e5;

// SUPPORT COLORS > SUPPORT 01
$support-01-darkest: #075060;
$support-01-dark: #0b8099;
$support-01-medium: #0ea0c0;
$support-01-light: #56bcd2;
$support-01-lightest: #9ed9e5;

// SUPPORT COLORS > SUPPORT 02
$support-02-darkest: #7a6019;
$support-02-dark: #c49a28;
$support-02-medium: #f5c132;
$support-02-light: #f8d36f;
$support-02-lightest: #fbe6ab;

$support-03-darkest: #396c29;
$support-03-dark: #5cac41;
$support-03-medium: #73d852;
$support-03-light: #9de385;
$support-03-lightest: #c7efb9;

// FEEDBACK COLORS > SUCESS
$feedback-success-darkest: #225230;
$feedback-success-dark: #337b47;
$feedback-success-medium: #44a45f;
$feedback-success-light: #8fc89f;
$feedback-success-lightest: #daeddf;

// FEEDBACK COLORS > WARNING
$feedback-warning-darkest: #736a11;
$feedback-warning-dark: #ad9f1a;
$feedback-warning-medium: #e6d422;
$feedback-warning-light: #f0e57a;
$feedback-warning-lightest: #faf6d3;

// FEEDBACK COLORS > DANGER
$feedback-danger-darkest: #730c1f;
$feedback-danger-dark: #ad112f;
$feedback-danger-medium: #e6173e;
$feedback-danger-light: #f0748b;
$feedback-danger-lightest: #fad1d8;

$feedback-info-darkest: #1e536b;
$feedback-info-dark: #2d7da1;
$feedback-info-medium: #3ca6d6;
$feedback-info-light: #8acae6;
$feedback-info-lightest: #d8edf7;

// NEUTRAL COLORS
$neutral-darkest: #1a1a1a;
$neutral-dark: #4f4f4f;
$neutral-medium: #9e9e9e;
$neutral-light: #d8d8d8;
$neutral-lightest: #ededed;
$neutral-white: #fff;

// Cores que não estão no design system
$support-02-medium-05: #fcb516;
$support-02-medium-10: #f7b130;
$brand-primary-dark-05: #125a70;
$brand-primary-dark-10: #0b4051;
$brand-primary-dark-15: #005970;
$brand-primary-dark-20: #2a596e;
$brand-primary-dark-30: #053846;
$brand-primary-dark-40: #053542;
$brand-primary-light-05: #4f9cb0;
$brand-primary-light-10: #0091a5;
$brand-primary-light-15: #3f8fa2;
$brand-primary-light-20: #97dbef;
$brand-primary-light-25: #3a7688;
$neutral-dark-10: #4C4F4F;

// OPACITY COLORS
$white-10: rgba(255, 255, 255, .9);

// FOCUS
$focus: 4px solid #fdd;

// SHADOWS
$shadow-drop: 0 0 20px rgba(0, 0, 0, .15);
$shadow-focus: 0 0 0 4px #fdd;
$drop-shadow: drop-shadow(3px 3px 3px #444);

//BORDER RADIUS
$border-radius: 5px;
$border-radius-sm: 3px;
// FONTS
$primary-font: "Montserrat",
sans-serif;

// HEADER SIZE
$header-mobile: 55.55px;
$header-tablet: 71.6px;
$header-desktop: 63px;

// z-index
$hyperlink-menu-index: 20;
$header-index: 30;
$modal-index: 40;

/// Container's maximum width
/// @type Length
$max-width: 1180px !default;
