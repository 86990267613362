// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// -----------------------------------------------------------------------------

// Seção Carousel do header
.carousel-header {
  position: relative;
  display: flex;
  justify-content: center;
  background-color: $neutral-lightest;

  @media ($min-md) {
    // background: linear-gradient(90deg, $brand-secondary-darkest 0%, $brand-secondary-dark 55%, $neutral-lightest 55%);
    background: linear-gradient(
      90deg,
      $brand-secondary-darkest 0%,
      $brand-secondary-dark 100%
    );
  }

  // desktop
  @media ($min-xl) {
    margin-top: 0;
  }

  // mobile
  @media (max-width: $lg) {
    margin-top: 60px;
  }

  &__parallax {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: absolute;
    top: 0;
    right: 0;

    @media ($min-md) {
      top: unset;
      right: unset;
    }

    & > img {
      height: 200px;

      @media ($min-xl) {
        height: 300px;
      }
    }
  }

  // CUSTOM GLIDER DOTS
  & .dots {
    position: absolute;
    bottom: 10px;

    @media ($min-md) {
      bottom: 35px;
    }

    & .glider-dot.active {
      background: $brand-secondary-medium;
    }
  }

  &__container {
    width: 100%;
    margin-inline: auto;
  }

  &__card {
    display: flex;
    position: relative;
    background: $brand-secondary-darkest;
    border-radius: 0px 0px 90px 0px;

    @media ($min-md) {
      background-color: transparent;
      border-radius: 0;
    }

    & picture {
      img {
        height: 100%;
        min-width: 100%;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 85%);
        object-fit: cover;
        object-position: center;

        @media ($min-md) {
          clip-path: polygon(100% 0, 100% 100%, 15% 100%, 0 0);
        }

        @media ($min-xl) {
          border-radius: 0px 0px 186px 0px;
        }
      }
    }
  }

  &__card-container {
    display: flex;
    height: 100%;
    width: 100%;
    max-width: 1280px;
    margin-inline: auto;
    flex-direction: column-reverse;
    justify-content: flex-end;

    @media ($min-md) {
      flex-direction: row;
    }
  }

  &__text {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    padding: 0 16px 48px 16px;
    border-radius: 0px 0px 90px 0px;

    @media ($min-md) {
      width: 498px;
      padding: 48px 16px;
      border-radius: 0;
    }
    @media(max-width: $sm){
      margin: auto;
    }
  }

  &__title {
    font-weight: 900;
    font-size: 1.125rem;
    line-height: 22px;
    text-transform: uppercase;
    color: $neutral-white;

    @media ($min-md) {
      font-size: 2.125rem;
      line-height: 41px;
    }
  }

  &__description {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 17px;
    color: $neutral-white;

    @media ($min-md) {
      font-size: 1.375rem;
      line-height: 27px;
    }
  }

  &__btn-link {
    display: none;
    font-weight: 800;
    border-radius: 200px;
    font-size: 0.75rem;
    max-width: 320px;

    &:focus,
    &:hover,
    &:active {
      text-decoration: underline;
    }

    @media ($min-md) {
      font-size: 0.875rem;
      line-height: 1.625rem;
      margin-top: 0.5rem;
    }

    &.btn-light-gentle,
    &.btn-pastel-green-gentle,
    &.btn-support-02-medium {
      display: block;
    }
  }
}

// Seção contratar
.cke_editable .product__container {
  margin-top: 0;
}

.product {
  padding-bottom: 1rem;
  background-color: $neutral-lightest;

  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEwIiBoZWlnaHQ9IjcyMiIgdmlld0JveD0iMCAwIDExMCA3MjIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxnIG9wYWNpdHk9IjAuNSI+CjxwYXRoIGQ9Ik0xMDguODk4IDE0NS44TC0yNS4wMDA0IDE0NS44TC0yNS4wMDA0IDEuOTk5OTlDNDguNjYyNSAyLjY4NjI3IDEwOC4yNTkgNjYuNjkwMSAxMDguODk4IDE0NS44WiIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiLz4KPHBhdGggZD0iTTEwOC44OTggMTQ1LjhMLTI1LjAwMDQgMTQ1LjhMLTI1LjAwMDQgMjg5LjZDNDguNjYyNSAyODguOTE0IDEwOC4yNTkgMjI0LjkxIDEwOC44OTggMTQ1LjhaIiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIvPgo8cGF0aCBkPSJNMTA4Ljg5OCA0MzMuNEwxMDguODk4IDI4OS42TC0yNS4wMDA0IDI4OS42Qy0yNC4zNjE0IDM2OC43MSAzNS4yMzU2IDQzMi43MTQgMTA4Ljg5OCA0MzMuNFY0MzMuNFoiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIi8+CjxwYXRoIGQ9Ik0tMjUuMDAwNCA1NzcuMkwtMjUuMDAwNCA0MzMuNEwxMDguODk4IDQzMy40QzEwOC4yNTkgNTEyLjUwOSA0OC42NjI1IDU3Ni41MTMgLTI1LjAwMDQgNTc3LjJaIiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIvPgo8cGF0aCBkPSJNMTA4Ljg5OCA3MjFMMTA4Ljg5OCA1NzcuMkwtMjUuMDAwNCA1NzcuMkMtMjQuMzYxNCA2NTYuMzEgMzUuMjM1NiA3MjAuMzE0IDEwOC44OTggNzIxVjcyMVoiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIi8+CjwvZz4KPC9zdmc+Cg==");
  background-position: 0 1rem;
  background-repeat: no-repeat;

  &__container {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 12px;
    margin-top: 25px;

    @media ($min-xl) {
      gap: 18px;
      margin-top: -25px;
    }
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 282px;
    height: auto;
    background: $neutral-white;
    box-shadow: $shadow-drop;
    border-radius: 0px 45px 51px 35px;
    padding-inline: 15px;
    padding-block: 15px;
    gap: 12px;
    position: relative;

    &::before {
      position: absolute;
      content: "";
      width: 4px;
      height: 90px;
      left: -4px;
      top: 0;
      border-radius: 5px 0 0 0;

      @media ($min-lg) {
        width: 6px;
        left: -6px;
        height: 120px;
      }
    }

    &--green::before {
      background-color: $support-03-medium;
    }

    &--yellow::before {
      background-color: $support-02-medium;
    }

    @media ($min-xs) {
      width: 155px;
      background: $neutral-white;
    }

    @media ($min-sm) {
      width: calc((100% / 3) - 39px);
      height: auto;
      padding-inline: 35px;
      padding-block: 30px;
    }

    @media ($min-lg) {
      width: calc((100% / 4) - 39px);
    }
  }

  &__item-title {
    font-weight: 900;
    font-size: 0.6875rem;
    line-height: 13px;
    text-align: center;
    text-transform: uppercase;
    color: $brand-primary-darkest;
    text-align: initial;

    @media ($min-lg) {
      font-size: 1rem;
      line-height: 20px;
    }
  }

  &__item-description {
    font-weight: 400;
    font-size: 0.625rem;
    line-height: 12px;
    text-align: center;
    color: $brand-primary-darkest;
    text-align: initial;

    @media ($min-lg) {
      font-size: 0.875rem;
      line-height: 17px;
    }
  }

  &__item-link {
    padding: 8px 16px;

    @media ($min-lg) {
      min-width: 100%;
      height: auto;
      padding: 10px 16px;
    }
  }
}

// Seção Carousel Institucional
.carousel-institucional {
  padding: 1rem 0;
  position: relative;
  background-color: $neutral-white;

  &:before {
    content: "";
    top: -200px;
    right: 0;
    width: 0;
    height: 0;
    border-width: 0 0 200px 100vw;
    border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #fff rgba(0, 0, 0, 0);
    border-style: solid;
    position: absolute;
  }

  &__container {
    display: flex;
    flex-direction: column;

    & .glider-contain {
      @media ($min-xl) {
        width: 980px;
      }

      // GLIDER DOTS
      & .carousel-institucional-dots {
        position: relative;
        bottom: -10px;

        @media ($min-md) {
          bottom: 30px;
        }

        & .glider-dot.active {
          background: $brand-secondary-medium;
        }
      }

      & .glider-prev,
      .glider-next {
        top: 48%;

        @media ($min-md) {
          top: 38%;
        }
      }

      & .glider-prev {
        left: 0;
      }

      & .glider-next {
        right: 0;
      }
    }

    & .carousel-institucional-glider {
      &::-webkit-scrollbar {
        display: none;
      }
    }

    & > h2 {
      font-weight: 900;
      font-size: 1.125rem;
      line-height: 22px;
      text-align: center;
      text-transform: uppercase;
      color: $brand-primary-dark;
      padding-block: 8px;
      padding-inline: 32px;

      @media ($min-md) {
        font-size: 1.625rem;
        line-height: 32px;
      }
    }

    & > p {
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 17px;
      text-align: center;
      color: $neutral-dark;
      padding-inline: 12px;
      padding-block: 8px 12px;

      @media ($min-md) {
        font-size: 1.125rem;
        line-height: 24px;
        padding-block-end: 36px;
      }
    }
  }

  &__carousel {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media ($min-md) {
      flex-direction: row;
    }

    & picture {
      @media ($min-md) {
        margin-left: 50px;
      }
    }

    & img {
      width: 202px;
      height: 191px;

      @media ($min-xs) {
        width: 221px;
        height: 209px;
      }

      @media ($min-md) {
        width: max-content;
        height: max-content;
      }

      clip-path: polygon(85% 0, 100% 100%, 0 100%, 0 0);
      border-radius: 45px 0px 0px 35px;
    }
  }

  &__content {
    @media (max-width: $sm) {
      max-width: 343px;
      min-width: 343px;
      min-height: 148px;
    }
    @media ($min-md) {
      width: 400px;
    }

    @media ($min-lg) {
      width: 500px;
    }

    @media ($min-xl) {
      width: 600px;
    }
  }

  &__content-text {
    display: flex;
    flex-direction: column;
    margin: 0 30px;

    // @media ($min-xl) {
    //   width: 600px;
    // }

    & > h3 {
      font-weight: 800;
      font-size: 1.125rem;
      line-height: 22px;
      color: $brand-primary-dark;
      padding-block: 14px 16px;
      text-align: center;
      text-transform: uppercase;

      @media ($min-md) {
        text-align: start;
      }
    }

    & > p {
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 16px;
      color: $neutral-dark;
      text-align: center;

      @media ($min-md) {
        text-align: start;
        padding-block: 0 16px;
        font-size: 1.125rem;
        line-height: 24px;
        text-align: initial;
      }
    }
  }
}

// Seção de Serviços
.services {
  background-color: $neutral-white;
  padding-block: 1rem;
  position: relative;

  @media ($min-xl) {
    &:after {
      content: "";
      bottom: -200px;
      left: 0;
      width: 0;
      height: 0;
      border-width: 0 0 200px 100vw;
      transform: rotate(180deg);
      border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #fff rgba(0, 0, 0, 0);
      border-style: solid;
      position: absolute;
    }
  }

  &__container {
    font-weight: 900;
    font-size: 26px;
    line-height: 32px;
    text-align: center;
    text-transform: uppercase;
    padding-inline: 16px;
    padding-block: 8px;

    @media ($min-xs) {
      padding-inline: 24px;
    }

    & h3 {
      font-weight: 900;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      text-transform: uppercase;
      color: $brand-primary-dark;
    }
  }

  &__tab {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem 0 1.75rem 0;
    flex-wrap: wrap;
  }

  &__tab-item {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    background: transparent;
    border: none;
    font-weight: 700;
    font-size: 0.75rem;
    color: $neutral-darkest;
    transition: border-color 0.2s;
    text-decoration: none;
    text-transform: uppercase;
    width: 100%;
    padding-block: 1rem;

    @media ($min-lg) {
      padding-inline: 0;
      margin-right: 2rem;
      justify-content: center;
      width: unset;
    }

    &:not(:last-of-type)::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 0px;
      right: 0;
      top: 100%;
      border: 1px solid $neutral-lightest;

      @media ($min-lg) {
        content: none;
      }
    }

    &#service-1 {
      order: 1;
    }

    &#service-2 {
      order: 3;
    }

    &#service-3 {
      order: 5;
    }

    &#service-4 {
      order: 7;
    }

    &#service-5 {
      order: 8;
    }

    &#service-6 {
      order: 9;
    }

    &#service-7 {
      order: 10;
    }

    &#service-8 {
      order: 11;
    }

    &#service-9 {
      order: 12;
    }

    &#service-10 {
      order: 13;
    }

    @media ($min-lg) {
      &#service-1,
      &#service-2,
      &#service-3,
      &#service-4,
      &#service-5,
      &#service-6,
      &#service-7,
      &#service-8,
      &#service-9,
      &#service-10 {
        order: unset;
      }
    }

    & .accordion-arrow {
      height: 20px;
      display: inline-block;
      position: relative;
      margin: 0 16px;

      @media ($min-lg) {
        display: none;
      }

      &:before,
      &:after {
        top: 8px;
        position: absolute;
        width: 9px;
        height: 2px;
        background-color: $neutral-darkest;
        display: inline-block;
        transition: all 0.2s ease;
        border-radius: 25px;
      }

      &:after {
        content: "";
        right: 0;
        transform: rotate(45deg);
      }

      &:before {
        content: "";
        left: 0;
        transform: rotate(-45deg);
      }
    }

    &--active {
      color: $brand-primary-dark;

      & .accordion-arrow {
        &:after {
          transform: rotate(-45deg);
          background-color: $brand-primary-dark;
        }

        &:before {
          transform: rotate(45deg);
          background-color: $brand-primary-dark;
        }
      }
    }

    &:hover,
    &--active {
      border-bottom: none;

      @media ($min-lg) {
        border-bottom: 5px solid $brand-primary-medium;
      }
    }
  }

  &__cards {
    display: none;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin: 30px 0;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
    counter-increment: item;

    @media ($min-lg) {
      flex-direction: row;
      gap: 2rem;

      &[aria-labelledby="service-1"],
      &[aria-labelledby="service-2"],
      &[aria-labelledby="service-3"],
      &[aria-labelledby="service-4"],
      &[aria-labelledby="service-5"],
      &[aria-labelledby="service-6"]
        &[aria-labelledby="service-7"]
        &[aria-labelledby="service-8"]
        &[aria-labelledby="service-9"]
        &[aria-labelledby="service-10"] {
        order: unset;
      }
    }

    &[aria-labelledby="service-1"] {
      order: 2;
    }

    &[aria-labelledby="service-2"] {
      order: 4;
    }

    &[aria-labelledby="service-3"] {
      order: 6;
    }

    &[aria-labelledby="service-4"] {
      order: 7;
    }

    &[aria-labelledby="service-5"] {
      order: 8;
    }

    &[aria-labelledby="service-6"] {
      order: 9;
    }

    &[aria-labelledby="service-7"] {
      order: 10;
    }

    &[aria-labelledby="service-8"] {
      order: 11;
    }

    &[aria-labelledby="service-9"] {
      order: 12;
    }

    &[aria-labelledby="service-10"] {
      order: 13;
    }

    &--active {
      display: flex;
    }
  }

  &__card {
    width: 100%;
    min-height: 70px;
    background: $neutral-white;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
    border-radius: 20px 0px 20px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.5rem;
    text-decoration: none;
    padding: 1.5rem;

    @media ($min-lg) {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.5rem;
      text-decoration: none;
      width: 164px;
      height: 116px;
      background: $neutral-white;
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
      border-radius: 20px 0px 20px 20px;
      padding: 1.5rem;
    }
  }

  &__card-description {
    font-weight: 700;
    font-size: 0.6875rem;
    line-height: 110%;
    text-transform: uppercase;
    color: $neutral-dark;

    @media ($min-lg) {
      font-weight: 700;
      font-size: 0.6875rem;
      line-height: 0.75rem;
      text-align: center;
      text-transform: uppercase;
      color: $neutral-dark;
    }
  }
}

// Seção de banners
.banner {
  padding: 4rem 0;
  background-color: $neutral-white;

  @media ($min-xl) {
    background-color: $neutral-lightest;
  }

  &__cards {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2rem;

    @media ($min-md) {
      flex-direction: row;
    }
  }

  &__card {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 280px;
    height: 200px;
    background: $neutral-white;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
    border-radius: 45px 0px 37px 35px;
    padding-inline: 20px;
    padding-block: 20px;
    gap: 12px;
    overflow: hidden;

    &--no-link {
      pointer-events: none;
    }

    @media ($min-md) {
      width: 450px;
      height: 300px;
    }

    & img {
      min-width: 100%;
      min-height: 100%;
    }
  }

  &__card-text {
    position: absolute;
    bottom: 25px;
    margin-inline: 30px;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    color: $neutral-dark;

    @media ($min-md) {
      bottom: 45px;
      margin-inline: 42px;
    }

    &:empty {
      display: none;
    }
  }
}
