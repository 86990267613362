.banner-header-lp-v2 {
  background-color: $brand-primary-dark-05;
  overflow: hidden;
  position: relative;

  &__container {
    // display: flex;
    margin-inline: auto;
    // position: relative;
    max-width: 1180px;

    &::before {
      bottom: 66px;
      position: absolute;
      height: 150px;
      width: 1248px;
      /* left: 100px; */
      background-color: rgba(0, 0, 0, 0);
      color: rgba(0, 0, 0, 0);
      z-index: 1;
      border-radius: 0 0 80px 0;
      border-right: 1px solid rgba(255, 255, 255, 0.4);
      border-bottom: 1px solid rgba(255, 255, 255, 0.4);
      @media ($min-xl) {
        content: "";
      }
    }

    @media ($min-xl) {
      justify-content: flex-end;
    }

    @media (max-width: $sm) {
      margin-top: 65px;
    }
  }

  &__content {
    padding-block: 25px 15px;
    padding-inline: 25px;
    z-index: 10;
    @media (max-width: $sm) {
      background: url("../assets/images/landing-pages/banner-inss-mobile-new.png");
      background-repeat: no-repeat;
      background-size: 100%;
    }
    @media ($min-xl) {
      left: 50%;
      position: absolute;
      top: 44%;
      transform: translate(-50%, -50%);
      width: 1180px;
    }
  }

  &__content-text {
    @media ($min-xl) {
      width: 720px;
    }
  }

  &__block {
    align-items: flex-start;
    display: flex;
    flex-direction: column;

    @media ($min-xl) {
      flex-direction: row;
    }
  }

  &__block-text {
    margin-bottom: 2rem;
    order: 2;
    max-width: 450px;

    @media ($min-xl) {
      order: unset;
    }

    @media (max-width: $sm) {
      margin-top: 3vh;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    @media ($mobile-default) {
      min-height: 270px;
    }
  }
  &__img-logo {
    margin-bottom: 35px;

    @media ($min-xl) {
      top: -18px;
      position: relative;
      margin-bottom: 0;
    }
    @media (max-width: $sm) {
      display: none;
    }
  }

  &__title {
    color: $neutral-white;
    font-size: 1.75rem;
    font-weight: 900;
    line-height: 1.29;
    text-transform: uppercase;
    white-space: pre-line;
    margin-bottom: 130px;

    @media ($min-sm) {
      margin-bottom: 2rem;
    }

    @media ($min-md) {
      font-size: 2rem;
    }

    @media ($min-xl) {
      font-size: 2.375rem;
      line-height: 1.06;
    }

    @media (max-width: $lg) {
      margin-bottom: 70px;
    }

    @media (max-width: $sm) {
      color: #fff;
      font-size: 1.75rem;
      font-weight: 900;
      line-height: 1.29;
      text-transform: uppercase;
      white-space: pre-line;
      margin-bottom: 0px;
    }
  }

  &__subtitle {
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 2.4375rem;
    color: $neutral-white;

    @media (max-width: 399px) {
      padding-top: 0px;
    }
    @media (max-width: $sm) {
      // padding-top: 20px;
    }
  }

  &__icon {
    order: 1;
    margin-bottom: 1.5rem;

    @media ($min-xl) {
      order: unset;
      margin-bottom: 0;
    }
  }

  &__list {
    list-style: none;
    margin-bottom: 32px;

    > li {
      color: $neutral-white;
      display: flex;
      font-size: 0.9375rem;
      font-weight: 700;
      line-height: 18px;
      margin-bottom: 1rem;

      &::before {
        background-color: $support-02-medium;
        border-radius: 50%;
        content: "";
        height: 12px;
        margin: 3px 10px 0 0;
        min-width: 12px;
      }
    }
  }

  &__button {
    background-color: $support-02-medium-05;
    border-radius: 32px;
    font-size: 1rem;
    font-weight: 800;
    min-width: 100%;
    padding: 16px;
    text-transform: inherit;

    @media ($min-sm) {
      font-size: 1.125rem;
      min-width: 334px;
    }

    &:focus,
    &:hover,
    &:active {
      text-decoration: underline;
    }
  }

  &__image {
    display: flex;
    object-fit: cover;
    overflow: hidden;
    position: absolute;
    right: 0;
    height: 280px;

    @media ($min-sm) {
      height: auto;
    }

    @media ($min-xl) {
      position: relative;
      // border-radius: 0 0 120px 0;
    }
  }
}

.banner-header-lp-v3 {
  background: radial-gradient(
    88.03% 88.03% at 50.04% 11.97%,
    $brand-secondary-medium 0%,
    $brand-primary-medium 55.73%
  );
  overflow: hidden;
  position: relative;
  @media ($min-xl) {
    border-radius: 0 0 0 131px;
  }

  &__container {
    display: flex;
    margin-inline: auto;
    position: relative;
    min-height: 694px;
    flex-direction: column;
    margin: auto;
    // max-width: 1180px;
    max-width: 1272px;

    @media ($min-xl) {
      justify-content: flex-end;
      flex-direction: row;
    }
  }

  &__content {
    margin-top: 45px;
    padding-block: 25px;
    padding-inline: 25px;
    z-index: 10;

    @media ($min-xl) {
      left: 50%;
      position: absolute;
      top: 40%;
      transform: translate(-50%, -50%);
      width: 100%;
    }

    > img {
      margin-bottom: 35px;
      // mobile
      @media (max-width: $lg) {
        display: none;
      }
    }
  }

  &__content-text {
    @media ($min-xl) {
      width: 720px;
    }

    &.mobile {
      padding-inline: 25px;
      display: none;
      // mobile
      @media (max-width: $lg) {
        display: block;
      }
    }
  }

  &__block {
    align-items: flex-start;
    display: flex;
    flex-direction: column;

    @media ($min-xl) {
      flex-direction: row;
    }
  }

  &__block-text {
    margin-bottom: 2rem;
    order: 2;
    // max-width: 450px;
    max-width: 584px;
    position: relative;

    @media ($min-xl) {
      order: unset;
    }
  }

  &__title {
    color: $neutral-white;
    font-size: 1.75rem;
    font-weight: 900;
    line-height: 1.29;
    text-transform: uppercase;
    white-space: pre-line;
    margin-bottom: 1rem;

    @media ($min-sm) {
      margin-bottom: 2rem;
    }

    @media ($min-md) {
      font-size: 2rem;
    }

    @media ($min-xl) {
      font-size: 2.813rem;
      line-height: 1.06;
    }

    @media ($min-hd) {
      font-size: 3.313rem;
    }
  }

  &__subtitle {
    font-weight: 400;
    // font-size: 2rem;
    font-size: 1.625rem;
    line-height: 2.4375rem;
    color: $neutral-white;

    // mobile
    @media (max-width: $lg) {
      font-size: 1.4rem;
      line-height: 1.4rem;
    }
  }

  &__icon {
    order: 1;
    margin-bottom: 1.5rem;
    position: absolute;
    left: 95%;
    top: -38px;

    @media ($min-xl) {
      order: unset;
      margin-bottom: 0;
    }

    // mobile
    @media (max-width: $lg) {
      margin-top: 12px;
      position: relative;
      left: 0;
      top: 0;
      width: 45%;
    }
  }

  &__list {
    list-style: none;
    margin-bottom: 32px;

    &.desktop {
      // mobile
      @media (max-width: $lg) {
        display: none;
      }
    }

    > li {
      color: $neutral-white;
      display: flex;
      font-size: 0.9375rem;
      font-weight: 700;
      line-height: 18px;
      margin-bottom: 1rem;

      &::before {
        background-color: $support-02-medium;
        border-radius: 50%;
        content: "";
        height: 12px;
        margin: 3px 10px 0 0;
        min-width: 12px;
      }
    }
  }

  &__button {
    background-color: $support-02-medium-05;
    border-radius: 32px;
    font-size: 1rem;
    font-weight: 800;
    min-width: 100%;
    padding: 16px;
    text-transform: inherit;

    @media ($min-sm) {
      font-size: 1.125rem;
      min-width: 334px;
    }

    &:focus,
    &:hover,
    &:active {
      text-decoration: underline;
    }
  }

  // FORM
  &__form-content {
    max-height: 629px;

    @media ($min-xl) {
      // margin: 0 35px 35px 0;
      margin: 0 0 35px 0;
    }
  }

  &__form {
    position: relative;
    z-index: 10;
    background: $neutral-white;
    height: 100%;
    width: 100%;
    padding: 25px;

    @media ($min-xl) {
      border-radius: 0 0 157px 0;
      width: 438px;
      padding: 48px;
    }

    // mobile
    @media (max-width: $lg) {
      border-radius: 0 0 125px;
      margin-bottom: 30px;
    }

    & .has-danger {
      & label,
      & select {
        border-color: $feedback-danger-dark;

        &:focus-within {
          outline-color: $feedback-danger-dark;
        }
      }

      & input,
      & textarea {
        color: $feedback-danger-dark;
      }

      & .pristine-error {
        line-height: 1.2;
        color: $feedback-danger-dark;
        margin-top: 0.1rem;
        margin-left: 0.25rem;
        font-size: 0.75rem;

        @media ($min-lg) {
          font-size: 0.8rem;
          margin-left: 0.5rem;
        }
      }
    }
  }

  &__message-container {
    display: none;
    position: relative;
    z-index: 10;
    background: $neutral-white;
    height: 100%;
    width: 100%;
    padding: 25px;

    @media ($min-xl) {
      border-radius: 0 0 157px 0;
      width: 438px;
      padding: 48px;
    }
  }

  &__fieldset {
    display: flex;
    flex-direction: column;
    gap: 0.9375rem;
    border: 0;
  }

  &__legend {
    font-weight: 700;
    font-size: 1rem;
    line-height: 20px;
    color: $feedback-info-darkest;
    margin-bottom: 1rem;
  }

  &__terms-block {
    display: flex;
    flex-direction: column;
    margin: 0.75rem 0.5rem;
    gap: 0.5rem;
  }

  &__info-terms {
    font-weight: 500;
    font-size: 0.6875rem;
    line-height: 0.9375rem;
    color: $feedback-info-darkest;
  }

  &__accept-terms {
    cursor: pointer;
    display: flex;
    align-items: flex-start;
    gap: 0.75rem;
  }

  &__accept-terms ~ .pristine-error {
    margin-left: 1.6rem !important;

    @media ($min-lg) {
      margin-left: 1.5rem !important;
    }
  }

  &__accept-text {
    font-weight: 500;
    font-size: 0.625rem;
    line-height: 0.8125rem;
    color: $feedback-info-darkest;

    > a {
      color: inherit;
    }
  }

  &__accept-checkbox {
    cursor: pointer;
    width: auto;
    display: flex;
    flex: 0 0 16px;
  }

  &__button-container {
    display: flex;
    justify-content: flex-start;
    margin-inline: 1.875rem;
    margin-top: 1.125rem;

    @media ($min-xs) {
      margin-top: 1.625rem;
    }

    > button {
      min-width: 237px;
      text-transform: none;
      font-weight: 700;
      font-size: 0.875rem;
      line-height: 130%;

      &:disabled,
      &[disabled] {
        cursor: default;
        opacity: 0.5;
      }
    }
  }

  &__message {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 1.5rem;
    margin: 1.5rem;

    @media ($min-md) {
      gap: 2rem;
      margin-top: 4rem;
    }
  }

  &__message-image {
    width: 64px;
    height: 64px;
  }

  &__message-description {
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.5rem;
    color: $feedback-info-darkest;

    @media ($min-md) {
      font-size: 1.375rem;
      line-height: 1.6875rem;
    }
  }
}

.info-lp-v2 {
  &__container {
    margin: auto;
    max-width: 1180px;
    padding-block: 50px 30px;
    padding-inline: 25px;

    @media ($min-lg) {
      display: flex;
      gap: 64px;
    }
  }

  &__content {
    &:first-of-type {
      width: 100%;

      @media ($min-lg) {
        width: 75%;
      }
    }

    &:last-of-type {
      width: 100%;

      @media ($min-lg) {
        width: 25%;
      }
    }
  }

  &__title {
    font-weight: 800;
    font-size: 1.375rem;
    line-height: 1.625rem;
    color: $brand-primary-dark-10;

    @media ($min-lg) {
      font-size: 2.375rem;
      line-height: 2.75rem;
    }
  }

  &__description {
    text-align: justify;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.25rem;
    color: $brand-primary-dark-05;
    margin-block: 32px;

    @media ($min-lg) {
      font-size: 1.25rem;
      line-height: 1.5rem;
    }
  }

  &__list {
    list-style: none;
    margin-bottom: 32px;
    margin-top: 12px;

    > li {
      align-items: baseline;
      color: $brand-primary-dark-05;
      display: flex;
      font-size: 1.125rem;
      font-weight: 500;
      line-height: 2.4rem;

      &::before {
        background-color: $support-02-medium;
        border-radius: 50%;
        content: "";
        height: 12px;
        margin: 3px 10px 0 0;
        min-width: 12px;
      }
    }
  }
}

.advantages-lp-v2 {
  background-color: $brand-primary-dark-05;

  &__container {
    margin: auto;
    max-width: 1180px;
    padding-block: 50px;
    padding-inline: 25px;

    @media ($min-xs) {
      padding-inline: 35px;
    }
  }

  &__content {
    &--dashboard {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    &--second {
      margin: 2rem 0;
    }
  }

  &__block-text {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    margin-bottom: 1rem;
  }

  &__title {
    font-weight: 800;
    font-size: 1.375rem;
    line-height: 1.6875rem;
    color: $neutral-white;

    @media ($min-lg) {
      font-size: 2.375rem;
      line-height: 2.75rem;
    }
  }

  &__symbol {
    color: $support-02-medium;
    font-weight: 800;
    font-size: 2.8125rem;
    line-height: 3.4375rem;

    @media ($min-lg) {
      font-size: 3.4375rem;
      line-height: 4.1875rem;
    }
  }

  &__description {
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.375rem;
    color: $neutral-white;
    margin-bottom: 1.5rem;

    @media ($min-lg) {
      font-size: 1.25rem;
      line-height: 1.5rem;
    }
  }

  &__content-bar {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    @media ($min-lg) {
      flex-direction: row;
      align-items: center;
      gap: 24px;
    }
  }

  &__bar {
    width: 96%;
    height: 10px;
    border-radius: 5px;
    overflow: hidden;
    padding: 0;
    background: $brand-primary-light-25;
    margin-bottom: 5px;

    @media ($min-lg) {
      margin-bottom: 0;
    }
  }

  &__progress-1 {
    background-color: $support-02-medium-05;
    width: 60%;
    height: 10px;
    border-radius: 5px;
  }

  &__progress-2 {
    background-color: $neutral-white;
    width: 77%;
    height: 10px;
    border-radius: 5px;
  }

  &__progress-3 {
    background-color: $neutral-white;
    width: 89%;
    height: 10px;
    border-radius: 5px;
  }

  &__progress-4 {
    background-color: $neutral-white;
    width: 94%;
    height: 10px;
    border-radius: 5px;
  }

  &__bar-description {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    color: $neutral-white;
    width: 158px;
  }

  &__items-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1rem;
  }

  &__item {
    width: 448px;
  }

  &__item-title {
    font-weight: 700;
    font-size: 1.75rem;
    line-height: 2.125rem;
    color: $support-02-medium-05;
    margin-bottom: 1rem;
  }

  &__item-description {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.8125rem;
    color: $neutral-white;
    margin-bottom: 1rem;
  }

  &__btn-container {
    display: flex;
    justify-content: center;

    > a {
      width: 423px;
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.25rem;
      font-weight: 800;

      @media ($min-md) {
        font-size: 1.5rem;
      }
    }
  }
}

.steps-lp-v2 {
  &__container {
    margin: auto;
    max-width: 1180px;
    padding-block: 50px;
    padding-inline: 25px;

    @media ($min-xs) {
      padding-inline: 35px;
    }
  }

  &__title {
    font-weight: 800;
    font-size: 1.375rem;
    line-height: 1.6875rem;
    color: $brand-primary-dark-10;
    margin-bottom: 32px;

    @media ($min-lg) {
      font-size: 2rem;
      line-height: 2.4375rem;
    }
  }

  &__cards-container {
    display: flex;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    margin-bottom: 45px;
    flex-wrap: wrap;
    gap: 32px;
  }

  &__card {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    counter-increment: item;

    @media ($min-md) {
      max-width: 236px;
    }
  }

  &__card-number {
    position: relative;
    border: 4px solid $support-02-medium-05;
    border-radius: 50px 0 40px 50px;
    width: 64px;
    height: 54px;

    @media ($min-lg) {
      width: 88px;
      height: 74px;
    }

    &:before {
      content: counter(item);
      font-weight: 700;
      font-size: 2.125rem;
      line-height: 2.5625rem;
      color: $brand-primary-dark-10;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      @media ($min-lg) {
        font-size: 3.125rem;
        line-height: 3.8125rem;
      }
    }
  }

  &__card-title {
    font-size: 1.5rem;
    line-height: 1x.8125rem;
    color: $brand-primary-dark-05;
  }

  &__card-description {
    @media (max-width: $sm) {
      text-align: justify;
    }

    font-weight: 500;
    font-size: 1rem;
    line-height: 1.25rem;
    color: $brand-primary-dark-10;
  }
}

.faq-lp-v2 {
  background-color: $brand-primary-dark-05;

  &__container {
    margin: auto;
    max-width: 1180px;
    padding-block: 50px;
    padding-inline: 25px;

    @media ($min-xs) {
      padding-inline: 35px;
    }
  }

  &__title {
    color: $neutral-white;
    font-size: 1.375rem;
    font-weight: 800;
    line-height: 1.75rem;

    @media ($min-lg) {
      font-size: 2rem;
      line-height: 2.75rem;
    }

    @media ($min-sm) {
      text-align: start;
    }

    &::after {
      color: $support-02-medium;
      content: "?";
      font-size: 2rem;
      font-weight: 800;
      margin-left: 10px;
    }
  }

  &__subttitle {
    color: $neutral-white;
    font-size: 1.375rem;
    font-weight: 800;
    line-height: 1.75rem;

    @media ($min-lg) {
      font-size: 2rem;
      line-height: 2.75rem;
    }

    @media ($min-sm) {
      text-align: start;
    }
  }

  &__content {
    margin-top: 2rem;
  }

  &__accordion {
    margin-bottom: 20px;

    @media ($min-lg) {
      margin-inline: 25px;
    }

    &[open] .faq-lp-v2__accordion-header {
      color: $brand-primary-light-20;

      &::before {
        transform: rotate(180deg);
      }
    }
  }

  &__accordion-header {
    color: $neutral-white;
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1.375rem;
    list-style: none;
    margin-bottom: 0.5rem;
    margin-inline-start: 8px;
    position: relative;
    cursor: pointer;

    &::-webkit-details-marker {
      display: none;
    }

    &::before {
      content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDE0IDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMTQgMS4xNDMwNkMxNCAxLjQzNTYxIDEzLjg4NTcgMS43MjgxNiAxMy42NTgyIDEuOTUxTDcuODI0OTYgNy42NjQ4OEM3LjM2ODgxIDguMTExNzEgNi42MzE0OSA4LjExMTcxIDYuMTc1MzMgNy42NjQ4OEwwLjM0MjExOCAxLjk1MUMtMC4xMTQwNCAxLjUwNDE4IC0wLjExNDA0IDAuNzgxOTQ0IDAuMzQyMTE4IDAuMzM1MTE5QzAuNzk4Mjc1IC0wLjExMTcwNyAxLjUzNTU5IC0wLjExMTcwNyAxLjk5MTc1IDAuMzM1MTE5TDcuMDAwMTUgNS4yNDEwNUwxMi4wMDg1IDAuMzM1MTE4QzEyLjQ2NDcgLTAuMTExNzA3IDEzLjIwMiAtMC4xMTE3MDcgMTMuNjU4MiAwLjMzNTExOEMxMy44ODU3IDAuNTU3OTU5IDE0IDAuODUwNTEgMTQgMS4xNDMwNloiIGZpbGw9IiNGNUMxMzIiLz4KPC9zdmc+Cg==");
      left: -30px;
      position: absolute;
    }
  }

  &__accordion-content {
    color: $neutral-white;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.75rem;
    margin-inline-start: 8px;
    text-align: justify;
  }

  &__container-button {
    display: flex;
    justify-content: center;
    margin-top: 35px;

    a {
      background: $support-02-medium-05;
      border-radius: 32px;
      color: $brand-primary-medium;
      font-size: 1.125rem;
      font-weight: 800;
      line-height: 38px;
      text-transform: capitalize;
      width: 281px;

      @media ($min-md) {
        width: 334px;
      }
    }
  }

  &__disclaimer {
    color: $neutral-white;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 17px;
    padding-block-start: 35px;
  }
}

.disclaimer-lp-v2 {
  &__container {
    margin: auto;
    max-width: 1180px;
    padding-block: 50px;
    padding-inline: 25px;

    @media ($min-xs) {
      padding-inline: 35px;
    }

    > p {
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 1.0625rem;
      color: $brand-primary-dark-05;
      margin-bottom: 2rem;

      > a {
        color: $brand-primary-dark-05;
      }
    }
  }
}
