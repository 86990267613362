.feedback-error {
  display: flex;
  flex-direction: column;
  height: 100vh;

  @media($min-md){
    flex-direction: row;
  }

  &__container {
    margin-inline: auto;
    padding-block: 30px;
    padding-inline: 30px;

    @media($min-sm){
      padding-block: 60px;
      padding-inline: 60px;
    }
  }

  &__content {
    max-width: 420px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__sidebar {
    display: flex;
    background: linear-gradient(178.76deg, #0FB1D3 35.41%, #049CC7 67.32%);
    width: 100%;
    overflow: hidden;
    justify-content: center;

    @media($min-md){
      width: 350px;
      justify-content: initial;
    }
  }

  &__icon {
    margin-bottom: 16px;
  }

  &__title {
    font-weight: 800;
    font-size: 1.125rem;
    line-height: 1.375rem;
    color: $brand-primary-darkest;
    margin-bottom: 26px;
    text-align: center;
  }

  &__description {
    font-weight: 700;
    font-size: .875rem;
    line-height: 1.0625rem;
    text-align: center;
    color: $brand-secondary-dark;
    margin-bottom: 32px;
  }

  &__button {
    background-color: $brand-primary-medium;
    min-width: 168px;
    height: 47px;
    border-radius: 200px;
    padding: 12px 16px;
  }

}