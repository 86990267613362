.footer {
  background-color: $neutral-lightest;


  &__custom {
    background-color: $brand-primary-medium;
    border-radius: 0;

    &--clr-primary-darkest {
      background-color: $brand-primary-darkest;
    }

    @media ($min-xl) {
      border-radius: 143px 0px 0px 0px;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-inline: 70px;
    padding-block: 35px;

    @media ($min-sm) {
      flex-direction: row;
      padding-inline: 16px;
      padding-block: 16px;
    }

    @media ($min-xl) {
      padding-inline: 80px;
      padding-block: 80px;
      gap: 2rem;
      color: $neutral-white;
    }


  }

  &__social-content {
    width: 179px;
    height: 195px;
    background: #FFFFFF;
    border-radius: 60px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-inline: 20px;
    gap: 15px;

    @media($min-sm) {
      width: 174px;
      height: 214px;
    }

  }

  &__social {
    flex: 1 1 0px;

    &--list {
      display: flex;
      list-style: none;

      >li>a {
        display: flex;
      }
    }

    &--text {
      font-weight: 500;
      font-size: 0.625rem;
      line-height: 12px;
      color: $brand-primary-darkest;

      & a {
        color: $brand-primary-darkest;
        margin-inline-start: .2rem;
      }
    }
  }

  &__service-channel {
    flex: 1 1 0;

    & h2 {
      font-weight: 700;
      font-size: 0.875rem;
      line-height: 17px;
      color: $neutral-white;
      padding-block-end: 10px;
    }

    &--item {
      display: flex;
      flex-direction: column;
      padding-block-end: 10px;

      & span,
      a {
        font-weight: 700;
        font-size: 0.625rem;
        line-height: 15px;
        color: $neutral-white;
      }

      & a {
        display: flex;
        text-decoration: none;
        gap: 5px;
      }

      & p {
        padding-block-start: 5px;
        font-weight: 500;
        font-size: 0.625rem;
        line-height: 12px;
        color: $neutral-white;
      }
    }
  }

  &__ombudsman {
    flex: 1 1 0;

    & h2 {
      font-weight: 700;
      font-size: 0.875rem;
      line-height: 17px;
      color: $neutral-white;
      padding-block-end: 10px;
    }

    &--item {
      display: flex;
      flex-direction: column;

      & span,
      a {
        font-weight: 700;
        font-size: 0.625rem;
        line-height: 12px;
        color: $neutral-white;
      }

      & a {
        display: flex;
        text-decoration: none;
      }

      >a:first-of-type {
        margin-block-end: 5px
      }

      & p {
        padding-block-start: 5px;
        font-weight: 500;
        font-size: 0.625rem;
        line-height: 12px;
        color: $neutral-white;
        ;
      }
    }

    &--talks {
      display: flex;
      flex-direction: column;
      gap: 5px;
      padding-block-start: 12px;

      & a {
        display: flex;
        align-items: flex-end;
        gap: 8px;
        font-weight: 500;
      }
    }
  }

  &__ombudsman-chat {
    cursor: pointer;
  }

  &__address {
    flex: 1 1 0;

    & h2 {
      font-weight: 700;
      font-size: 0.875rem;
      line-height: 17px;
      color: $neutral-white;
      padding-block-end: 10px;
    }

    &--item {
      padding-block-end: 5px;
      display: flex;
      flex-direction: column;

      & span {
        font-weight: 700;
        font-size: 0.625rem;
        line-height: 12px;
        color: $neutral-white;
      }

      & p {
        font-weight: 500;
        font-size: 0.625rem;
        line-height: 12px;
        color: $neutral-white;
        padding-block-start: 5px;
      }

    }
  }

  &__info {
    flex: 1 1 0px;

    & h2 {
      font-weight: 700;
      font-size: 0.875rem;
      line-height: 17px;
      color: $neutral-white;
      padding-block-end: 10px;
    }

    &--list {
      list-style: none;

      & a {
        font-weight: 700;
        font-size: 10px;
        line-height: 12px;
        text-decoration-line: underline;
        color: $neutral-white;
      }
    }
  }
}

// Parametrizador de cores do footer.
#param-footer-color {
  display: none;
}

#blip-chat-open-iframe:not(.opened) {
  display: none !important;
}