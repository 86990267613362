.hero-doc {
  background: linear-gradient(90deg, #075060 60.53%, #0ea0c0 100%);
  border-radius: 0px 0px 130px 0px;

  @media ($min-md) {
    border-radius: 0px 0px 186px 0px;
  }

  &__container {
    display: flex;
    justify-content: flex-end;
    position: relative;
    flex-direction: column-reverse;
    min-height: 280px;

    @media ($min-md) {
      flex-direction: row;
    }

    // desktop
    @media ($min-xl) {
      margin-top: 0;
    }

    // mobile
    @media (max-width: $lg) {
      margin-top: 60px;
    }
  }

  &__title {
    font-size: 1.875rem;
    line-height: 2.3125rem;
    font-weight: 900;
    text-transform: uppercase;
    color: $neutral-white;
    margin-bottom: 8px;

    @media ($min-md) {
      font-size: 2.125rem;
      line-height: 2.5625rem;
      margin-bottom: 12px;
    }
  }

  &__subtitle {
    font-weight: 500;
    font-size: 0.9rem;
    line-height: 1.5rem;
    color: $neutral-white;

    @media ($min-md) {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }

  &__description {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
    color: $neutral-dark;
  }

  &__text-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex-grow: 1;
    padding-inline: 16px;

    @media ($min-md) {
      padding-inline: 32px;
    }

    @media ($min-xl) {
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 1180px;
    }
  }

  &__picture {
    display: inline-flex;

    @media ($min-md) {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 14% 100%);
    }
  }

  &__image {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  &__parallax {
    display: none;
    align-items: flex-end;
    flex-direction: column;
    position: absolute;
    right: 0;
    top: 0;

    @media ($min-xl) {
      display: flex;
    }

    & > img {
      height: 150px;

      @media ($min-md) {
        height: 180px;
      }
    }
  }
}

.content-doc {
  &__container {
    width: 100%;
    max-width: 1180px;
    margin-inline: auto;
    padding-inline: 16px;
    padding-block: 16px;

    @media ($min-md) {
      padding-inline: 32px;
      padding-block: 48px;
    }
  }

  &__accordion {
    padding: 25px 0;

    &:not(:first-of-type) {
      border-top: 1px solid rgba(158, 158, 158, 0.4);
    }
  }

  &__accordion-header {
    align-items: center;
    color: $brand-primary-dark;
    cursor: pointer;
    display: flex;
    font-size: 1.125rem;
    line-height: 1.375rem;
    font-weight: 900;
    justify-content: space-between;
    list-style: none;
    position: relative;
    text-transform: uppercase;

    @media ($min-md) {
      font-size: 1.25rem;
      line-height: 1.5rem;
    }

    &::after {
      content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAyMSAxMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yMC41NjI1IDIuMzkzMTVDMjAuNTYyNSAyLjgxMzY5IDIwLjM5ODEgMy4yMzQyMyAyMC4wNzExIDMuNTU0NTdMMTEuNjg1OSAxMS43NjgzQzExLjAzMDIgMTIuNDEwNiA5Ljk3MDI2IDEyLjQxMDYgOS4zMTQ1NCAxMS43NjgzTDAuOTI5Mjk0IDMuNTU0NTdDMC4yNzM1NjggMi45MTIyNiAwLjI3MzU2OCAxLjg3NDA0IDAuOTI5Mjk0IDEuMjMxNzNDMS41ODUwMiAwLjU4OTQyMiAyLjY0NDkxIDAuNTg5NDIyIDMuMzAwNjQgMS4yMzE3M0wxMC41MDAyIDguMjg0MDJMMTcuNjk5OCAxLjIzMTczQzE4LjM1NTUgMC41ODk0MjEgMTkuNDE1NCAwLjU4OTQyMSAyMC4wNzExIDEuMjMxNzNDMjAuMzk4MSAxLjU1MjA3IDIwLjU2MjUgMS45NzI2MSAyMC41NjI1IDIuMzkzMTVaIiBmaWxsPSIjMEE0QTVCIi8+Cjwvc3ZnPgo=");
    }

    &::-webkit-details-marker {
      display: none;
    }
  }

  &__accordion[open] &__accordion-header {
    &::after {
      transform: rotate(180deg);
    }
  }

  &__accordion-cards {
    display: flex;
    gap: 12px;
    margin: 25px 0;
    flex-wrap: wrap;
    justify-content: center;

    @media ($min-md) {
      gap: 25px;
    }
  }

  &__card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 1 0;
    min-height: 280px;
    padding: 48px 15px 30px 15px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
    border-radius: 45px 0 37px 35px;
    max-width: 200px;
    align-items: center;
    min-width: 200px;
    @media ($min-xs) {
      max-width: 155px;
    }

    @media ($min-md) {
      max-width: 205px;
    }
  }

  &__card-title {
    font-weight: 900;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    text-align: center;
    text-transform: uppercase;
    color: $brand-primary-darkest;

    @media ($min-md) {
      font-size: 1rem;
      line-height: 1.25rem;
    }
  }

  &__card-description {
    font-weight: 500;
    color: $neutral-dark;
    text-align: center;
    font-size: 0.6875rem;
    line-height: 0.875rem;

    @media ($min-md) {
      font-size: 0.75rem;
      line-height: 0.875rem;
    }
  }
}
