.anchor-v2 {
  position: relative;
  margin-top: -65px;
  display: none;

  border-radius: 0 0 0 90px;

  @media($min-xl){
    display: block;
  }

  &__nav {
    display: flex;
    align-items: center;
    height: 65px;
    margin: auto;
    max-width: 1180px;
    padding-inline: 60px 25px;
  }

  &__list {
    gap: 80px;
    display: flex;
    list-style: none;
    width: 100%;
  }

  &__pointer {
    color: #FFFFFF;
    font-size: .75rem;
    font-style: normal;
    font-weight: 800;
    line-height: .9375rem;
    text-transform: uppercase;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}