// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------
$button-size-variables: (
  "medium",
  "big"
);

$button-theme-variables: (
  "primary": ("background": $brand-primary-medium,
    "border": $brand-primary-medium,
    "color": $neutral-white,
    "hover-background": $neutral-white,
    "hover-border": $neutral-white,
    "hover-color": $brand-primary-medium,
    "active-background": $brand-primary-medium,
    "active-border": $brand-primary-medium,
    "active-color": $neutral-white,
  ),
  "secondary": ("background": $brand-primary-darkest,
    "border": $brand-primary-darkest,
    "color": $neutral-white,
    "hover-background": $neutral-white,
    "hover-border": $brand-primary-darkest,
    "hover-color": $brand-primary-darkest,
    "active-background": $brand-primary-darkest,
    "active-border": $brand-primary-darkest,
    "active-color": $neutral-white,
  ),
  "secondary-v2": ("background":$brand-primary-medium,
    "border":$brand-primary-medium,
    "color": $neutral-white,
    "hover-background": $neutral-white,
    "hover-border":$brand-primary-medium,
    "hover-color":$brand-primary-medium,
    "active-background":$brand-primary-medium,
    "active-border":$brand-primary-medium,
    "active-color": $neutral-white,
  ),
  "secondary-v3": ("background": $brand-primary-dark,
    "border": $brand-primary-dark,
    "color": $neutral-white,
    "hover-background": $neutral-white,
    "hover-border": $brand-primary-dark,
    "hover-color": $brand-primary-dark,
    "active-background": $brand-primary-dark,
    "active-border": $brand-primary-dark,
    "active-color": $neutral-white,
  ),
  "light": ("background": $neutral-white,
    "border": $neutral-white,
    "color": $brand-primary-medium,
    "hover-background": $brand-primary-medium,
    "hover-border": $brand-primary-medium,
    "hover-color": $neutral-white,
    "active-background": $brand-primary-medium,
    "active-border": $brand-primary-medium,
    "active-color": $neutral-white,
  ),
  "light-gentle": ("background": $neutral-white,
    "border": $neutral-white,
    "color": $brand-primary-dark,
    "hover-background": $neutral-white,
    "hover-border": $neutral-white,
    "hover-color": $brand-primary-dark,
    "active-background": $neutral-white,
    "active-border": $neutral-white,
    "active-color":$brand-primary-dark,
  ),
  "blue-grey": ("background": transparent,
    "border": $neutral-dark,
    "color": $neutral-dark,
    "hover-background": transparent,
    "hover-border": $brand-primary-medium,
    "hover-color": $brand-primary-medium,
    "active-background": transparent,
    "active-border": $brand-primary-medium,
    "active-color": $brand-primary-medium,
  ),
  "pastel-green": ("background": $support-03-medium,
    "border": $support-03-medium,
    "color": $brand-primary-darkest,
    "hover-background": transparent,
    "hover-border": $support-03-medium,
    "hover-color": $brand-primary-darkest,
    "active-background": transparent,
    "active-border": $support-03-medium,
    "active-color": $brand-primary-darkest,
  ),
  "pastel-green-gentle": ("background": $support-03-medium,
    "border": $support-03-medium,
    "color": $brand-primary-darkest,
    "hover-background": $support-03-lightest,
    "hover-border": $support-03-lightest,
    "hover-color": $brand-primary-darkest,
    "active-background": $support-03-lightest,
    "active-border": $support-03-lightest,
    "active-color": $brand-primary-darkest,
  ),
  "dark-blue": ("background": $brand-primary-dark,
    "border": $brand-primary-dark,
    "color": $neutral-white,
    "hover-background": transparent,
    "hover-border": $brand-primary-dark,
    "hover-color": $brand-primary-dark,
    "active-background": transparent,
    "active-border": $brand-primary-dark,
    "active-color": $brand-primary-dark,
  ),
  "outline-primary": ("background": transparent,
    "border": $brand-primary-medium,
    "color": $brand-primary-medium,
    "hover-background": $neutral-white,
    "hover-border": $neutral-white,
    "hover-color": $brand-primary-medium,
    "active-background": $neutral-white,
    "active-border": $brand-primary-medium,
    "active-color": $brand-primary-medium,
  ),
  "outline-light": ("background": transparent,
    "border": $neutral-white,
    "color": $neutral-white,
    "hover-background": rgba(#2e3032, 0.6),
    "hover-border": $neutral-white,
    "hover-color": $neutral-white,
    "active-background": #2e3032,
    "active-border": $neutral-white,
    "active-color": $neutral-white,
  ),
  "outline-blue": ("background": transparent,
    "border": $brand-primary-medium,
    "color": $brand-primary-medium,
    "hover-background": $neutral-white,
    "hover-border": $brand-secondary-medium,
    "hover-color": $brand-secondary-medium,
    "active-background": $neutral-white,
    "active-border": $brand-secondary-medium,
    "active-color": $brand-secondary-medium,
  ),
  "outline-darkest": ("background": transparent,
    "border": $brand-primary-darkest,
    "color": $brand-primary-darkest,
    "hover-background": $neutral-white,
    "hover-border": $brand-secondary-medium,
    "hover-color": $brand-secondary-medium,
    "active-background": $neutral-white,
    "active-border": $brand-secondary-medium,
    "active-color": $brand-secondary-medium,
  ),
  "support-02-medium": ("background": $support-02-medium,
    "border": $support-02-medium,
    "color": $brand-primary-dark,
    "hover-background": $support-02-light,
    "hover-border": $support-02-medium,
    "hover-color": $brand-primary-dark,
    "active-background": $support-02-light,
    "active-border": $support-02-medium,
    "active-color": $brand-primary-dark ),
);

@mixin button-theme($background,
  $border,
  $color,
  $hover-background,
  $hover-border,
  $hover-color,
  $active-background,
  $active-border,
  $active-color) {
  color: $color;
  background: $background;
  border: 1px solid $border;

  &:hover {
    color: $hover-color;
    background-color: $hover-background;
    border-color: $hover-border;
  }

  &:focus-visible,
  &:active {
    color: $active-color;
    background-color: $active-background;
    border-color: $active-border;
  }

  &:focus-visible {
    outline: $focus;
  }
}

@mixin button-size($size: medium) {
  display: inline-block;
  text-transform: uppercase;
  text-align: center;
  transition: all 0.3s;

  @include on-event(true) {
    text-decoration: none;
  }

  // SIZE
  @if $size ==big {
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.75rem;
    padding: 16px 20px;
    border-radius: 30px;
    min-width: 184px;
  }

  @else {
    font-weight: 700;
    font-size: 0.6875rem;
    line-height: 1.25rem;
    padding: 10px 16px;
    border-radius: 20px;
    min-width: 135px;
  }
}

@for $i from 1 through length($button-size-variables) {
  $actualValue: nth($button-size-variables, $i);

  @if ($actualValue =="medium") {
    .btn {
      @include button-size();
    }
  }

  @else {
    .btn-#{$actualValue} {
      @include button-size($actualValue);
    }
  }
}

@each $theme,
$themesMap in $button-theme-variables {
  .btn-#{$theme} {
    @include button-theme(map-get($themesMap, "background"),
      map-get($themesMap, "border"),
      map-get($themesMap, "color"),
      map-get($themesMap, "hover-background"),
      map-get($themesMap, "hover-border"),
      map-get($themesMap, "hover-color"),
      map-get($themesMap, "active-background"),
      map-get($themesMap, "active-border"),
      map-get($themesMap, "active-color"));
  }
}

.search-icon {
  display: none;
  transition: all 0.3s;
  background-color: transparent;
  width: 40px;
  height: 40px;
  padding: 10px;
  border: 0;

  &:hover,
  &:focus-visible,
  &:active {
    background-color: $neutral-white;
    ;
    border-radius: 50%;
  }

  &:focus-visible {
    outline: $focus;
  }

  &:active img {
    filter: brightness(1.5);
  }

  img {
    width: 19px;
  }

  @media ($min-xl) {
    display: inline-block;
  }
}