.selectMultiple {
  position: relative;

  &>div {
    position: relative;
    z-index: 2;
    padding: 8px 42px 2px 12px;
    border-radius: 8px;
    background: #fff;
    font-size: 14px;
    min-height: 44px;
    box-shadow: 0 4px 16px 0 rgba(#162A5A, .12);
    transition: box-shadow .3s ease;

    &:hover {
      box-shadow: 0 4px 24px -1px rgba(#162A5A, .16);
    }

    .arrow {
      right: 8px;
      top: 0;
      bottom: 0;
      cursor: pointer;
      width: 28px;
      position: absolute;

      @media($min-md) {
        right: 16px;
      }

      &:before,
      &:after {
        box-sizing: inherit;
        content: '';
        position: absolute;
        display: block;
        width: 2px;
        height: 8px;
        border-bottom: 8px solid $brand-primary-dark;
        top: 43%;
        transition: all .3s ease;
      }

      &:before {
        right: 12px;
        transform: rotate(-130deg);
      }

      &:after {
        left: 9px;
        transform: rotate(130deg);
      }
    }

    span {
      display: block;
      position: absolute;
      left: 16px;
      cursor: pointer;
      top: 16px;
      transition: all .3s ease;
      font-weight: 500;
      font-size: .8rem;
      line-height: 1;
      color: $brand-primary-darkest;
      width: 100%;
      height: 100%;

      &.hide {
        opacity: 0;
        visibility: hidden;
        transform: translate(-4px, 0);
      }
    }

    a {
      position: relative;
      padding: 0 24px 6px 8px;
      line-height: 28px;
      color: $brand-primary-dark;
      display: inline-block;
      vertical-align: top;
      margin: 0 6px 0 0;

      &:before {
        box-sizing: inherit;
        content: '';
        left: 0;
        top: 0;
        bottom: 6px;
        width: 100%;
        position: absolute;
        display: block;
        background: rgba(#E4ECFA, .7);
        z-index: -1;
        border-radius: 4px;
      }

      i {
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
        width: 24px;
        height: 28px;
        display: block;

        &:before,
        &:after {
          content: '';
          display: block;
          width: 2px;
          height: 10px;
          position: absolute;
          left: 50%;
          top: 50%;
          background: $brand-primary-darkest;
          border-radius: 1px;
        }

        &:before {
          transform: translate(-50%, -50%) rotate(45deg);
        }

        &:after {
          transform: translate(-50%, -50%) rotate(-45deg);
        }
      }

      &.notShown {
        opacity: 0;
        transition: opacity .3s ease;

        &:before {
          width: 28px;
          transition: width .45s cubic-bezier(.87, -.41, .19, 1.44) .2s;
        }

        i {
          opacity: 0;
          transition: all .3s ease .3s;
        }

        em {
          opacity: 0;
          transform: translate(-6px, 0);
          transition: all .4s ease .3s;
        }

        &.shown {
          opacity: 1;

          &:before {
            width: 100%;
          }

          i {
            opacity: 1;
          }

          em {
            opacity: 1;
            transform: translate(0, 0);
          }
        }
      }

      &.remove {
        pointer-events: none;

        &:before {
          width: 28px;
          transition: width .4s cubic-bezier(.87, -.41, .19, 1.44) 0s;
        }

        i {
          opacity: 0;
          transition: all .3s ease 0s;
        }

        em {
          opacity: 0;
          transform: translate(-12px, 0);
          transition: all .4s ease 0s;
        }

        &.disappear {
          opacity: 0;
          transition: opacity .5s ease 0s;
        }
      }
    }
  }

  &>ul {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 16px;
    z-index: 1;
    position: absolute;
    top: calc(100% - 8px);
    left: 0;
    right: 0;
    visibility: hidden;
    opacity: 0;
    border-radius: 8px;
    transform: translate(0, 20px) scale(.8);
    transform-origin: 0 0;
    box-shadow: 0 12px 20px rgba(#162A5A, .08);
    transition: all .4s ease, transform .4s cubic-bezier(.87, -.41, .19, 1.44), filter .3s ease .2s;

    li {
      background: #fff;
      padding: 12px 16px;
      cursor: pointer;
      overflow: hidden;
      position: relative;
      transition: background .3s ease, color .3s ease, transform .3s ease .3s, opacity .5s ease .3s, border-radius .3s ease;
      font-weight: 500;
      font-size: .8rem;
      line-height: 1;
      color: $brand-primary-darkest;

      &:first-child {
        border-radius: 8px 8px 0 0;

        &:last-child {
          border-radius: 8px;
        }
      }

      &:last-child {
        border-radius: 0 0 8px 8px;

        &:first-child {
          border-radius: 8px;
        }
      }

      &:hover {
        background: $neutral-lightest;
      }

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 6px;
        height: 6px;
        background: rgba(#000, .4);
        opacity: 0;
        border-radius: 100%;
        transform: scale(1, 1) translate(-50%, -50%);
        transform-origin: 50% 50%;
      }

      &.beforeRemove {
        border-radius: 0 0 8px 8px;

        &:first-child {
          border-radius: 8px;
        }
      }

      &.afterRemove {
        border-radius: 8px 8px 0 0;

        &:last-child {
          border-radius: 8px;
        }
      }

      &.remove {
        transform: scale(0);
        opacity: 0;

        &:after {
          animation: ripple .4s ease-out;
        }
      }

      &.show {
        transform: scale(0);
        opacity: 0;
        animation: scale-up .35s ease forwards;
      }
    }
  }

  &.open {
    &>div {
      box-shadow: 0 4px 20px -1px rgba(#162A5A, .12);

      .arrow {
        &:before {
          transform: rotate(-50deg);
        }

        &:after {
          transform: rotate(50deg);
        }
      }
    }

    &>ul {
      transform: translate(0, 12px) scale(1);
      opacity: 1;
      visibility: visible;
      filter: drop-shadow(0 16px 24px rgba(#162A5A, .16));
    }
  }
}

@keyframes ripple {
  0% {
    transform: scale(0, 0);
    opacity: 1;
  }

  25% {
    transform: scale(30, 30);
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: scale(50, 50);
  }
}

@keyframes scale-up {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}