.header-lp {
  display: flex;
  justify-content: center;
  width: 100%;
  height: $header-desktop;
  background-color: $neutral-white;
  padding: 0 15px;
  z-index: $header-index;
  box-shadow: 0 1px 3px rgba(0,0,0,.2);
  position: fixed;
  @media ($min-xl){
      position: static;
      box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
    }
  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1180px;
  }

  &__logo {
    margin: auto;
    font-size: 0;
    line-height: 0;
   
    @media ($min-xl) {
      margin-right: 79px;
    }

    img {
      width: 136px;

      @media ($min-xl) {
        width: 170px;
      }
    }
  }

  &__nav {
    position: fixed;
    display: flex;
    background-color: $neutral-white;
    width: 100%;
    top: $header-desktop;
    left: -110%;
    // position: absolute;
    flex-direction: column;
    align-items: initial;
    transition: all 0.4s;
    height: calc(100% - $header-mobile);
    max-width: 565px;

    @media ($min-xl) {
      position: static;
      flex-direction: row;
      max-width: none;
      height: initial;
    }
  }

  &__buttons-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding: 18px 10px 33px;

    & a {
      padding: 10px 12px;
      min-width: 286px;

      @media($min-xl){
        min-width: unset;
      }

      &:empty {
        display: none;
      }

    }
    

    @media ($min-xl) {
      
      padding: 0;
    }
  }

  &__menu-container {
    z-index: 10;
    display: none;
    flex-direction: column;
    position: absolute;
    overflow-y: auto;
    top: 0;
    left: 0;
    bottom: 90px;
    width: 100%;
    background-color: $neutral-white;
    overflow-y: hidden;

    @media ($min-xl) {
      top: $header-desktop;
      height: fit-content;
      padding: 1rem 3rem 1.5rem 4rem;
      border-radius: 0px 0px 20px 20px;
      flex-direction: row;
    }

    // Menu personalizado para Área do Cliente
    &--client-area {
      @media ($min-xl) {
        width: auto;
        left: unset;
        margin-left: 80px;
        padding: 1rem 1.5rem;
      }
    }

    >ul {
      list-style: none;
      display: block;
      gap: 2.5rem;
      overflow: hidden;

      @media ($min-xl) {
        flex-grow: 1;
      }
    }

    &.open {
      display: flex;
    }
  }

  &__submenu {
    position: relative;

    &:not(:last-of-type)::before {
      content: none;
      position: absolute;
      width: 90px;
      height: 0px;
      right: 0;
      top: 100px;
      opacity: 0.6;
      border: 1px solid #7fabb7;
      transform: rotate(-90deg);

      @media ($min-xl) {
        content: "";
      }
    }

    &--third-level {
      list-style: none;
    }
  }

  &__submenu-item {
    a {
      display: block;
      font-size: 1.0625rem;
      line-height: 1.25rem;
      color: $brand-primary-dark;
      padding: 8px 34px;
      text-decoration: none;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 0.75rem;
      line-height: 1.1rem;
      padding: 12px 20px;

      @media ($min-xl) {
        padding: 8px 0;
      }
    }

    button {
      border: none;
      background-color: transparent;
      text-transform: uppercase;
      font-size: 1.0625rem;
      color: $brand-primary-dark;
      padding: 12px 34px;
      text-decoration: none;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 0.75rem;
      line-height: 0.5rem;
      padding: 12px 18px;
      width: 100%;

      @media ($min-xl) {
        padding: 7px 0;
        line-height: 1.1rem;
        width: auto;
      }
    }

    &--title>a,
    button:not(.header__submenu-item--third-level-open button) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: $brand-primary-dark;
      font-weight: 800;
      font-size: 0.75rem;
      line-height: 0.125rem;

      @media ($min-xl) {
        line-height: 2.125rem;
      }
    }

    &:not(.header__submenu-item--title) a:hover {
      color: $brand-primary-light;
    }

    &:not(.header__submenu-item--title, .header__submenu-item--third-level) {
      display: none;

      @media ($min-xl) {
        display: block;
      }
    }

    &--third-level {
      display: none;

      a {
        color: $brand-primary-light;
      }
    }

    &--third-level-open {
      >button {
        display: flex;
        align-items: center;
        font-weight: 600;
      }
    }

    &.open {
      display: block;
    }
  }
}
.header-lp-mobile{
  display: flex;
  justify-content: center;
  width: 100%;
  height: $header-desktop;
  background-color: $neutral-white;
  padding: 0 15px;
  z-index: $header-index;
  box-shadow: 0 1px 3px rgba(0,0,0,.2);
  position: fixed;
  @media ($min-xl){
    display: none;
      position: static;
      box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
    }
  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1180px;
  }

  &__logo {
    margin: auto;
    font-size: 0;
    line-height: 0;
   
    @media ($min-xl) {
      margin-right: 79px;
    }

    img {
      width: 136px;

      @media ($min-xl) {
        width: 170px;
      }
    }
  }

  &__nav {
    position: fixed;
    display: flex;
    background-color: $neutral-white;
    width: 100%;
    top: $header-desktop;
    left: -110%;
    // position: absolute;
    flex-direction: column;
    align-items: initial;
    transition: all 0.4s;
    height: calc(100% - $header-mobile);
    max-width: 565px;

    @media ($min-xl) {
      position: static;
      flex-direction: row;
      max-width: none;
      height: initial;
    }
  }

  &__buttons-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding: 18px 10px 33px;

    & a {
      padding: 10px 12px;
      min-width: 286px;

      @media($min-xl){
        min-width: unset;
      }

      &:empty {
        display: none;
      }

    }
    

    @media ($min-xl) {
      
      padding: 0;
    }
  }

  &__menu-container {
    z-index: 10;
    display: none;
    flex-direction: column;
    position: absolute;
    overflow-y: auto;
    top: 0;
    left: 0;
    bottom: 90px;
    width: 100%;
    background-color: $neutral-white;
    overflow-y: hidden;

    @media ($min-xl) {
      top: $header-desktop;
      height: fit-content;
      padding: 1rem 3rem 1.5rem 4rem;
      border-radius: 0px 0px 20px 20px;
      flex-direction: row;
    }

    // Menu personalizado para Área do Cliente
    &--client-area {
      @media ($min-xl) {
        width: auto;
        left: unset;
        margin-left: 80px;
        padding: 1rem 1.5rem;
      }
    }

    >ul {
      list-style: none;
      display: block;
      gap: 2.5rem;
      overflow: hidden;

      @media ($min-xl) {
        flex-grow: 1;
      }
    }

    &.open {
      display: flex;
    }
  }

  &__submenu {
    position: relative;

    &:not(:last-of-type)::before {
      content: none;
      position: absolute;
      width: 90px;
      height: 0px;
      right: 0;
      top: 100px;
      opacity: 0.6;
      border: 1px solid #7fabb7;
      transform: rotate(-90deg);

      @media ($min-xl) {
        content: "";
      }
    }

    &--third-level {
      list-style: none;
    }
  }

  &__submenu-item {
    a {
      display: block;
      font-size: 1.0625rem;
      line-height: 1.25rem;
      color: $brand-primary-dark;
      padding: 8px 34px;
      text-decoration: none;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 0.75rem;
      line-height: 1.1rem;
      padding: 12px 20px;

      @media ($min-xl) {
        padding: 8px 0;
      }
    }

    button {
      border: none;
      background-color: transparent;
      text-transform: uppercase;
      font-size: 1.0625rem;
      color: $brand-primary-dark;
      padding: 12px 34px;
      text-decoration: none;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 0.75rem;
      line-height: 0.5rem;
      padding: 12px 18px;
      width: 100%;

      @media ($min-xl) {
        padding: 7px 0;
        line-height: 1.1rem;
        width: auto;
      }
    }

    &--title>a,
    button:not(.header__submenu-item--third-level-open button) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: $brand-primary-dark;
      font-weight: 800;
      font-size: 0.75rem;
      line-height: 0.125rem;

      @media ($min-xl) {
        line-height: 2.125rem;
      }
    }

    &:not(.header__submenu-item--title) a:hover {
      color: $brand-primary-light;
    }

    &:not(.header__submenu-item--title, .header__submenu-item--third-level) {
      display: none;

      @media ($min-xl) {
        display: block;
      }
    }

    &--third-level {
      display: none;

      a {
        color: $brand-primary-light;
      }
    }

    &--third-level-open {
      >button {
        display: flex;
        align-items: center;
        font-weight: 600;
      }
    }

    &.open {
      display: block;
    }
  }
}
// Estilo do icone de hamburguer e abertura do menu mobile
.hamburger-menu-lp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;

  @media ($min-xl) {
    display: none;
  }

  &__lines,
  &__lines:before,
  &__lines:after {
    display: block;
    width: 26px;
    height: 4px;
    background-color: $brand-primary-medium;
    transition: 0.4s ease-in-out;
  }

  &__lines {
    position: relative;
  }

  &__lines:before {
    position: absolute;
    content: "";
    top: -8px;
    right: 0;
  }

  &__lines:after {
    position: absolute;
    content: "";
    top: 8px;
  }

  &__checkbox {
    display: block;
    visibility: hidden;
    width: 0;
    height: 0;
    position: absolute;

    @media ($min-xl) {
      display: none;
    }
  }

  &__checkbox:checked~& &__lines {
    transform: rotatez(-45deg);
  }

  &__checkbox:checked~& &__lines:before {
    transform: rotatez(90deg) translate(8px, 0px);
    width: 100%;
  }

  &__checkbox:checked~& &__lines:after {
    transform: translate(0, -8px) rotatez(0deg);
  }

  &__checkbox:checked~.header-lp__nav {
    left: 0;
  }
}

.nav-menu-lp {
  z-index: 1;
  flex: 1;
  overflow-y: auto;

  >ul {
    list-style: none;
    display: block;
    gap: 2.5rem;

    @media ($min-xl) {
      display: flex;
    }
  }

  &__item-container {
    display: none;
    flex-direction: column;
    position: relative;
    width: 100%;
    margin: 0 auto;
    left: 0rem;
    border-radius: 0px 0px 20px 20px;
    background-color: $neutral-white;

    @media ($min-xl) {
      position: absolute;
      flex-direction: row;
      padding: 1rem 3rem 1.5rem 4rem;
    }

    &.open {
      display: flex;
    }
  }

  // Em mobile o js esconde o nav_menu quando o contaienr do
  // menu partner area for exibido
  // &.open {
  //   display: block;
  // }

  // @media ($min-xl) {
  //   display: flex;
  // }

  // Apenas desktop
  // Hover do item do menu e mudanças na lista e link
  &__dropdown:hover {
    &>.nav-menu-lp__pointer {
      &:before {
        opacity: 1;
      }
    }
  }

  &__pointer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 0.75rem;
    line-height: 0.9375rem;
    font-weight: 800;
    color: $brand-primary-medium;
    background-color: $neutral-white;
    padding: 12px 20px;
    text-decoration: none;
    text-transform: uppercase;
    border: none;

    @media ($min-xl) {
      position: relative;
      display: inline-block;
      padding: 0;
      border-bottom: none;
      white-space: nowrap;
      line-height: $header-desktop;
    }

    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
    }

    &:before {
      content: "";
      width: 100%;
      height: 8px;
      position: absolute;
      bottom: 0;
      background-color: #7fabb7;
      opacity: 0;
      transition: opacity 0.3s;
    }

    &[aria-expanded="true"] {
      &>.accordion-arrow {
        &:before {
          transform: rotate(45deg);
        }

        &:after {
          transform: rotate(-45deg);
        }
      }
    }
  }

  &__submenu {
    position: relative;
    overflow: hidden;
    transition: max-height 0.5s ease;
    padding: 0;
    margin: 0;
    list-style: none;
    flex-grow: 1;
    max-height: initial;
    transition: all 0.3s;

    &:not(:last-of-type)::before {
      content: none;
      position: absolute;
      width: 90px;
      height: 0px;
      right: 0;
      top: 100px;
      opacity: 0.6;
      border: 1px solid #7fabb7;
      transform: rotate(-90deg);

      @media ($min-xl) {
        content: "";
      }
    }
  }

  &__submenu-item {
    background-color: $neutral-white;

    &:not(.nav-menu-lp__submenu-item--title) {
      display: none;

      @media ($min-xl) {
        display: block;
      }
    }

    &.open {
      display: block;
    }

    @media ($min-xl) {
      &:last-child {
        border-bottom: none;
      }
    }

    &:not(.nav-menu-lp__submenu-item--title) a:hover {
      color: $brand-primary-light;
    }

    a {
      display: block;
      font-size: 1.0625rem;
      line-height: 1.25rem;
      color: $brand-primary-dark;
      padding: 8px 34px;
      text-decoration: none;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 0.75rem;
      line-height: 1.1rem;

      @media ($min-xl) {
        padding: 8px 0;
      }
    }

    button {
      border: none;
      background-color: transparent;
      text-transform: uppercase;
      font-size: 1.0625rem;
      color: $brand-primary-dark;
      padding: 12px 34px;
      text-decoration: none;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 0.75rem;
      line-height: 0.5rem;

      @media ($min-xl) {
        padding: 8px 0;
      }

      @media ($min-xl) {
        line-height: 1.5rem;
      }
    }

    &--title a,
    button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: $brand-primary-dark;
      font-weight: 800;
      font-size: 0.75rem;
      line-height: 0.125rem;

      @media ($min-xl) {
        line-height: 2.125rem;
      }
    }

    &--title button {
      width: 100%;
    }
  }
}

.nav-menu-lp-mobile{
  z-index: 1;
  flex: 1;
  overflow-y: auto;
  display: none;

  @media (max-width: $sm) {
    display: block;
  }
  >ul {
    list-style: none;
    display: block;
    gap: 2.5rem;

    @media ($min-xl) {
      display: flex;
    }
  }

  &__item-container {
    display: none;
    flex-direction: column;
    position: relative;
    width: 100%;
    margin: 0 auto;
    left: 0rem;
    border-radius: 0px 0px 20px 20px;
    background-color: $neutral-white;

    @media ($min-xl) {
      position: absolute;
      flex-direction: row;
      padding: 1rem 3rem 1.5rem 4rem;
    }

    &.open {
      display: flex;
    }
  }

  // Em mobile o js esconde o nav_menu quando o contaienr do
  // menu partner area for exibido
  // &.open {
  //   display: block;
  // }

  // @media ($min-xl) {
  //   display: flex;
  // }

  // Apenas desktop
  // Hover do item do menu e mudanças na lista e link
  &__dropdown:hover {
    &>.nav-menu-lp__pointer {
      &:before {
        opacity: 1;
      }
    }
  }

  &__pointer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 0.75rem;
    line-height: 0.9375rem;
    font-weight: 800;
    color: $brand-primary-medium;
    background-color: $neutral-white;
    padding: 12px 20px;
    text-decoration: none;
    text-transform: uppercase;
    border: none;

    @media ($min-xl) {
      position: relative;
      display: inline-block;
      padding: 0;
      border-bottom: none;
      white-space: nowrap;
      line-height: $header-desktop;
    }

    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
    }

    &:before {
      content: "";
      width: 100%;
      height: 8px;
      position: absolute;
      bottom: 0;
      background-color: #7fabb7;
      opacity: 0;
      transition: opacity 0.3s;
    }

    &[aria-expanded="true"] {
      &>.accordion-arrow {
        &:before {
          transform: rotate(45deg);
        }

        &:after {
          transform: rotate(-45deg);
        }
      }
    }
  }

  &__submenu {
    position: relative;
    overflow: hidden;
    transition: max-height 0.5s ease;
    padding: 0;
    margin: 0;
    list-style: none;
    flex-grow: 1;
    max-height: initial;
    transition: all 0.3s;

    &:not(:last-of-type)::before {
      content: none;
      position: absolute;
      width: 90px;
      height: 0px;
      right: 0;
      top: 100px;
      opacity: 0.6;
      border: 1px solid #7fabb7;
      transform: rotate(-90deg);

      @media ($min-xl) {
        content: "";
      }
    }
  }

  &__submenu-item {
    background-color: $neutral-white;

    &:not(.nav-menu-lp__submenu-item--title) {
      display: none;

      @media ($min-xl) {
        display: block;
      }
    }

    &.open {
      display: block;
    }

    @media ($min-xl) {
      &:last-child {
        border-bottom: none;
      }
    }

    &:not(.nav-menu-lp__submenu-item--title) a:hover {
      color: $brand-primary-light;
    }

    a {
      display: block;
      font-size: 1.0625rem;
      line-height: 1.25rem;
      color: $brand-primary-dark;
      padding: 8px 34px;
      text-decoration: none;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 0.75rem;
      line-height: 1.1rem;

      @media ($min-xl) {
        padding: 8px 0;
      }
    }

    button {
      border: none;
      background-color: transparent;
      text-transform: uppercase;
      font-size: 1.0625rem;
      color: $brand-primary-dark;
      padding: 12px 34px;
      text-decoration: none;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 0.75rem;
      line-height: 0.5rem;

      @media ($min-xl) {
        padding: 8px 0;
      }

      @media ($min-xl) {
        line-height: 1.5rem;
      }
    }

    &--title a,
    button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: $brand-primary-dark;
      font-weight: 800;
      font-size: 0.75rem;
      line-height: 0.125rem;

      @media ($min-xl) {
        line-height: 2.125rem;
      }
    }

    &--title button {
      width: 100%;
    }
  }
}