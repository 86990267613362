.loading {
  display: none;
  width: 100%;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba($support-01-lightest, .1);
  height: 100%;

  &--show {
  display: flex;
  }

  &__ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;

    div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 64px;
      height: 64px;
      margin: 8px;
      border: 8px solid $brand-primary-dark;
      border-radius: 50%;
      animation: loading-animation 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: $brand-primary-dark transparent transparent transparent;
    }

    div:nth-child(1) {
      animation-delay: -0.45s;
    }

    div:nth-child(2) {
      animation-delay: -0.3s;
    }

    div:nth-child(3) {
      animation-delay: -0.15s;
    }

    &.small {

    }
  }

  &.small {
    .loading__ring {
      width: 40px;
      height: 40px;

      div {
        width: 32px;
        height: 32px;
        margin: 4px;
        border: 4px solid $brand-primary-dark;
        border-color: $brand-primary-dark transparent transparent transparent;
      }
    }
  }
}

@keyframes loading-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
