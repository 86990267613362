html {

  scroll-padding-top: 60px;

}
.form-financiamento-solar {
  background-color: $neutral-lightest;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTMiIGhlaWdodD0iMjgyIiB2aWV3Qm94PSIwIDAgNTMgMjgyIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8ZyBvcGFjaXR5PSIwLjgiPgo8cGF0aCBkPSJNNTEuOTU4IDU3LjhMNy44MDIyOWUtMDYgNTcuOEw3LjEzNjg4ZS0wNiAyQzI4LjU4NDEgMi4yNjYzIDUxLjcxIDI3LjEwMjMgNTEuOTU4IDU3LjhaIiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIvPgo8cGF0aCBkPSJNNTEuOTU4IDU3LjhMMy43NjU0NWUtMDUgNTcuOEwzLjgzMTk5ZS0wNSAxMTMuNkMyOC41ODQxIDExMy4zMzQgNTEuNzEgODguNDk3OCA1MS45NTggNTcuOFoiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIi8+CjxwYXRoIGQ9Ik01MS45NTggMTY5LjRMNTEuOTU4IDExMy42TDMuNzY1NDVlLTA1IDExMy42QzAuMjQ4MDAyIDE0NC4yOTggMjMuMzczOSAxNjkuMTM0IDUxLjk1OCAxNjkuNFYxNjkuNFoiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIi8+CjxwYXRoIGQ9Ik0zLjgzMTk5ZS0wNSAyMjUuMkwzLjc2NTQ1ZS0wNSAxNjkuNEw1MS45NTggMTY5LjRDNTEuNzEgMjAwLjA5OCAyOC41ODQxIDIyNC45MzQgMy44MzE5OWUtMDUgMjI1LjJaIiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIvPgo8cGF0aCBkPSJNNTEuOTU4IDI4MUw1MS45NTggMjI1LjJMMy43NjU0NWUtMDUgMjI1LjJDMC4yNDgwMDIgMjU1Ljg5OCAyMy4zNzM5IDI4MC43MzQgNTEuOTU4IDI4MVYyODFaIiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIvPgo8L2c+Cjwvc3ZnPgo=");
  background-position: 0 68px;
  background-repeat: no-repeat;

  &__background {
    position: relative;
    border-radius: 0 0 0 130px;
    background-color: $brand-secondary-medium;

    &::before {
      position: absolute;
      height: 1px;
      width: calc(50% + 200px);
      left: 18px;
      color: rgba(255, 255, 255, 0.5);
      background-color: currentColor;
      top: calc(100% - 65px);

      @media($min-xl) {
        content: '';
      }
    }

  }
  

  &__container {
    display: flex;
    flex-direction: column;
    border-radius: 0 0 0 90px;
    min-height: 548px;
    position: relative;
    max-width: 1180px;
    margin: auto;

    @media ($min-md) {
      flex-direction: row;
    }
  }

  &__text-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 220px;
    padding-block: 20px;
    padding-inline: 60px;
    display: flex;
    background-color: $brand-secondary-medium;

    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzQiIGhlaWdodD0iMTgwIiB2aWV3Qm94PSIwIDAgMzQgMTgwIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8ZyBvcGFjaXR5PSIwLjgiPgo8cGF0aCBkPSJNMzIuOTYyNiAzNy40TDYuMjUzOThlLTA1IDM3LjRMNi4yMTE3NmUtMDUgMkMxOC4xMzQgMi4xNjg5NCAzMi44MDUzIDE3LjkyNTEgMzIuOTYyNiAzNy40WiIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiLz4KPHBhdGggZD0iTTMyLjk2MjYgMzcuNEw2LjIxMTc2ZS0wNSAzNy40TDYuMjUzOThlLTA1IDcyLjhDMTguMTM0IDcyLjYzMTEgMzIuODA1MyA1Ni44NzQ5IDMyLjk2MjYgMzcuNFoiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIi8+CjxwYXRoIGQ9Ik0zMi45NjI2IDEwOC4yTDMyLjk2MjYgNzIuNzk5OUw2LjIxMTc2ZS0wNSA3Mi43OTk5QzAuMTU3MzczIDkyLjI3NDggMTQuODI4NyAxMDguMDMxIDMyLjk2MjYgMTA4LjJWMTA4LjJaIiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIvPgo8cGF0aCBkPSJNNi4yNTM5OGUtMDUgMTQzLjZMNi4yMTE3NmUtMDUgMTA4LjJMMzIuOTYyNiAxMDguMkMzMi44MDUzIDEyNy42NzUgMTguMTM0IDE0My40MzEgNi4yNTM5OGUtMDUgMTQzLjZaIiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIvPgo8cGF0aCBkPSJNMzIuOTYyNiAxNzlMMzIuOTYyNiAxNDMuNkw2LjIxMTc2ZS0wNSAxNDMuNkMwLjE1NzM3MyAxNjMuMDc1IDE0LjgyODcgMTc4LjgzMSAzMi45NjI2IDE3OVYxNzlaIiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIvPgo8L2c+Cjwvc3ZnPgo=");
    background-position: 0 15px;
    background-repeat: no-repeat;

    @media ($min-xs) {
      margin-top: 40px;
      background-position: 0 30px;
      min-height: 260px;
    }

    @media ($min-md) {
      flex: 1 1 0;
      padding-inline: 0;
      align-items: center;
      background-color: transparent;
      background-image: none;
    }

    @media ($min-xl) {
      position: absolute;
      width: 1180px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      align-items: baseline;
    }

  }

  &__text-block {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    padding-inline-start: 0;

    @media ($min-md) {
      max-width: 320px;
      padding-inline-start: 60px;
    }
  }

  &__title {
    font-size: 1.375rem;
    line-height: 1.6875rem;
    text-transform: uppercase;
    font-weight: 900;
    color: $neutral-white;
    margin-block-end: 10px;

    @media ($min-md) {
      font-size: 1.875rem;
      line-height: 37px;
    }
  }

  &__description {
    font-size: 1rem;
    line-height: 1.25rem;
    font-weight: 400;
    color: $neutral-white;

    @media ($min-md) {
      font-size: 1.5rem;
      line-height: 29px;
    }

    p:empty {
      padding-bottom: 1rem;
    }
  }

  &__content {
    position: relative;
    flex-grow: 1;
    overflow: hidden;

    @media ($min-md) {
      border-radius: 0;
      display: flex;
      justify-content: flex-end;
      flex: 1 1 0;
    }

  }

  &__content-image {
    display: none;
    position: absolute;
    max-width: 820px;
    height: 100%;

    @media ($min-md) {
      display: block;
    }

    @media($min-xl) {
      clip-path: polygon(0 0, 100% 0, 100% calc(100% - 65px), 0 calc(100% - 65px));
    }
  }

  &__wrapper {
    position: relative;
    padding-block: 1rem 4rem;
    background-color: $white-10;
    border-radius: 0px 0px 0px 129px;

    @media ($min-md) {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-block-end: 0;
      padding: 45px 30px 27px 30px;
      border-radius: 0;
    }

    @media ($min-lg) {
      border-radius: 90px 0px 0px 0px;
      max-width: 417px;
    }

    & form {
      & fieldset {
        display: flex;
        flex-direction: column;
        gap: 0.9375rem;
        border: 0;
        margin-inline: 1rem;

        @media($min-xs) {
          margin-inline: 1.875rem;
        }
      }

      & .js-toggle-visbililty {
        display: none;
      }

      & legend {
        font-weight: 700;
        font-size: 1rem;
        line-height: 20px;
        color: $feedback-info-darkest;
        margin-bottom: 1rem;
      }

      & label {
        background: $neutral-white;
        border: 1px solid $neutral-lightest;
        border-radius: 8px;
        max-width: 320px;
      }

      & select {
        max-width: 320px;
      }

      & button {
        width: 320px;
        font-weight: 700;
        font-size: 0.875rem;
        line-height: 130%;
        color: $brand-primary-darkest;

        &:disabled,
        &[disabled] {
          cursor: default;
          opacity: 0.5;
        }
      }

      & .has-danger {

        & label,
        & select {
          border-color: $feedback-danger-dark;

          &:focus-within {
            outline-color: $feedback-danger-dark;
          }
        }

        & input,
        & textarea {
          color: $feedback-danger-dark;
        }

        & .pristine-error {
          line-height: 1.2;
          color: $feedback-danger-dark;
          margin-top: .1rem;
          margin-left: 0.25rem;
          font-size: 0.75rem;

          @media ($min-lg) {
            font-size: 0.8rem;
            margin-left: 0.5rem;
          }
        }
      }
    }
  }

  &__button-container {
    display: flex;
    justify-content: flex-start;
    margin-inline: 1.875rem;
    margin-top: 1.125rem;

    @media ($min-xs) {
      margin-top: 1.625rem;
    }
  }

  &__message {
    display: none;
    flex-direction: column;
    height: 100%;
    gap: 1.5rem;
    margin: 1.5rem;

    @media($min-md) {
      gap: 2rem;
      margin-top: 4rem;
    }
  }

  &__message-image {
    width: 64px;
    height: 64px;
  }

  &__message-description {
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.5rem;
    color: $feedback-info-darkest;

    @media($min-md) {
      font-size: 1.375rem;
      line-height: 1.6875rem;
    }
  }

}

.about-financiamento-solar {
  background-color: $neutral-lightest;
  border-radius: 0px 0px 155px 0px;

  @media ($min-md) {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTM3IiBoZWlnaHQ9IjI3MiIgdmlld0JveD0iMCAwIDEzNyAyNzIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+DQo8cGF0aCBkPSJNNjguOTA3MiAyLjAwMDAzTDY4LjkwNzIgNjguMTU0N0wxLjIwMTQyIDY4LjE1NDdDMS41MjQ1NCAzMS43NjA1IDMxLjY1OTcgMi4zMTU3NCA2OC45MDcyIDIuMDAwMDNaIiBzdHJva2U9IiM3M0Q4NTIiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIi8+DQo8cGF0aCBkPSJNNjguOTA3MyAxMzUuMDk2TDY4LjkwNzMgNjguMTU0MkwxMzYuNjEzIDY4LjE1NDJDMTM2LjI5IDEwNC45ODIgMTA2LjE1NSAxMzQuNzc3IDY4LjkwNzMgMTM1LjA5NloiIHN0cm9rZT0iIzBFQTBDMCIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiLz4NCjxwYXRoIGQ9Ik0xMzYuNjEzIDIwMi4wMzlMMTM2LjYxMyAxMzUuMDk3TDY4LjkwNjkgMTM1LjA5N0M2OS4yMyAxNzEuOTI0IDk5LjM2NTEgMjAxLjcyIDEzNi42MTMgMjAyLjAzOVoiIHN0cm9rZT0iIzBFQTBDMCIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiLz4NCjxwYXRoIGQ9Ik02OC42NTEgMjcwTDY4LjY1MSAyMDEuNjUxTDEzNyAyMDEuNjUxQzEzNi42NzQgMjM5LjI1MiAxMDYuMjUyIDI2OS42NzQgNjguNjUxIDI3MFoiIHN0cm9rZT0iIzczRDg1MiIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiLz4NCjwvc3ZnPg0K");
    background-repeat: no-repeat;
    background-position: right 0;
  }

  @media ($min-lg) {
    border-radius: 0px 0px 238px 0px;
  }

  &__container {
    margin: auto;
    max-width: 1180px;
    padding-inline: 1.5rem;
  }

  &__content {
    max-width: 1025px;
    margin: 60px 0;

    @media ($min-md) {
      margin: 30px 110px 30px 0;
    }
  }

  &__title-block {
    margin-bottom: 1rem;

    @media ($min-md) {
      margin-bottom: 1.5rem;
    }

    & img {
      width: 26px;
      height: 26px;
      top: 3px;
      position: relative;
      margin-left: 5px;

      @media ($min-md) {
        width: 32px;
        height: 32px;
      }
    }
  }

  &__title {
    display: inline;
    font-weight: 800;
    font-size: 1.625rem;
    line-height: 30px;
    color: $brand-secondary-dark;

    @media ($min-md) {
      font-size: 2rem;
      line-height: 44px;
    }
  }

  &__description {
    font-weight: 500;
    font-size: 1rem;
    line-height: 26px;
    text-align: justify;
    color: $neutral-dark;
    margin-bottom: 2rem;

    @media ($min-md) {
      font-size: 18px;
      margin: 0.5rem 0;
    }

    p:empty {
      padding-bottom: 1rem;
    }
  }
}

.hire-financiamento-solar {

  @media($min-md) {
    padding-block-end: 100px;
  }

  &__content {
    margin: auto;
    max-width: 1180px;
    padding-block: 60px;
    padding-top: 30px;

    &:first-of-type {
      padding-inline: 1.5rem;
    }
  }

  &__title-block {
    margin-bottom: 1rem;

    @media ($min-md) {
      margin-bottom: 1.5rem;
    }

    & img {
      width: 26px;
      height: 26px;
      top: 3px;
      position: relative;
      margin-left: 5px;

      @media ($min-md) {
        width: 32px;
        height: 32px;
      }
    }
  }

  &__title {
    display: inline;
    font-weight: 800;
    font-size: 1.625rem;
    line-height: 30px;
    color: $brand-secondary-dark;

    @media ($min-md) {
      font-size: 2rem;
      line-height: 44px;
    }
  }

  &__description {
    font-weight: 500;
    font-size: 1rem;
    line-height: 26px;
    text-align: justify;
    color: $neutral-dark;
    margin-bottom: 2rem;

    @media ($min-md) {
      font-size: 18px;
      margin: 0.5rem 0;
    }

    p:empty {
      padding-bottom: 1rem;
    }
  }

  &__container-list {
    display: flex;
    flex-direction: column;

    @media ($min-md) {
      flex-direction: row;
      background-image: none;
    }
  }

  &__list-block {
    display: flex;
    flex-direction: column;
    padding-inline: 24px;
    padding-block: 3.5rem;
    flex-grow: 1;
    align-items: center;

    @media ($min-md) {
      padding-inline: 48px;
    }

    &--blue {
      background: $brand-secondary-medium;
      border-radius: 108px 0 0 0;

      @media ($min-md) {
        border-radius: 200px 0 0 0;
      }
    }

    &--green {
      position: relative;
      background: $support-03-medium;
      border-radius: 0 0 108px 0;

      @media ($min-md) {
        position: relative;
        bottom: -100px;
        border-radius: 0 0 200px 0;
      }
    }
  }

  &__list-content {
    max-width: 500px;
  }

  &__list-title {
    font-weight: 800;
    font-size: 1.5rem;
    line-height: 28px;
    color: $brand-primary-darkest;
    margin-bottom: 24px;
    margin-left: 10px;

    @media ($min-md) {
      max-width: 326px;
      margin: 25px 50px;
    }
  }

  &__list {
    list-style: none;
    counter-reset: item;
    margin-bottom: 15px;
  }

  &__list-item {
    display: flex;
    align-items: baseline;
    gap: 8px;
    margin-bottom: 24px;

    @media ($min-md) {
      gap: 12px;
    }

    & span {
      display: flex;
      gap: 16px;

      &::before {
        display: inline-block;
        content: counter(item) ".";
        counter-increment: item;
        font-weight: 800;
        font-size: 36px;
        line-height: 44px;
        text-transform: uppercase;
        color: $neutral-white;
      }
    }
  }

  &__btn {
    margin: 15px auto;
    width: 280px;
    font-weight: 700;
    font-size: 0.675rem;
    line-height: 18px;
    color: $neutral-white;

    @media ($min-md) {
      width: 305px;
      font-size: .875rem;
      line-height: 130%;
    }
  }
}

.be-partner {
  padding-block: 48px;
  padding-inline: 40px;
  display: flex;
  justify-content: center;

  a {
    width: 440px;
    font-weight: 700;
    font-size: .875rem;
    line-height: 130%;
    border-radius: 40px;
  }
}

.financiamento-solar-faq {
  padding-block: 2rem 4rem;
  padding-top: 30px;

  &__container {
    max-width: 1180px;
    padding-inline: 1.5rem;
  }

  &__title {
    display: block;
    font-weight: 800;
    font-size: 1.625rem;
    line-height: 30px;
    color: $brand-secondary-dark;
    margin-bottom: 12px;

   strong {
    font-weight: 800;
    font-size: 2.25rem;
    line-height: 3.625rem;
    color: #73d852;
   } 

    @media ($min-md) {
      font-size: 2rem;
      line-height: 44px;
      margin-bottom: 42px;
    }

    // font-weight: 800;
    // font-size: 1.75rem;
    // line-height: 44px;
    // color: $brand-primary-dark-10;
    // margin-bottom: 12px;

    // @media ($min-md) {
    //   margin-bottom: 42px;
    // }
  }

  &__content {
    display: flex;
    flex-direction: column;

    @media ($min-md) {
      flex-direction: row;
      gap: 4rem;
    }
  }

  &__tab-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    margin-bottom: 24px;

    @media ($min-md) {
      gap: 15px;
    }
  }

  &__tab-item {
    width: 100px;
    text-align: start;
    padding-inline: 8px;
    border: none;
    background-color: transparent;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 24px;
    color: $brand-primary-dark-10;
    text-transform: uppercase;

    &--active {
      background: $brand-secondary-medium;
      color: $neutral-white;
      border-radius: 4px;
    }
  }

  &__accordion-container {

    & .accordion {
      margin-bottom: 12px;

      @media ($min-md) {
        margin-bottom: 16px;
      }

      & .accordion__header,
      & .accordion__content {
        position: relative;
        background-color: $neutral-white;

        font-size: .9375rem;
        color: $neutral-dark;

        @media ($min-lg) {
          font-size: 1.125rem;
        }
      }

      & .accordion__header {
        list-style: none;
        cursor: pointer;
        border-radius: 5px;
        border: 0;
        font-weight: 700;
        line-height: 1.1;
        overflow: hidden;
        display: flex;
        margin-bottom: 8px;
        position: relative;
        font-weight: 700;
        font-size: .75rem;
        line-height: 130%;
        color: $brand-primary-dark-10;
        gap: 12px;

        &::-webkit-details-marker {
          display: none;
        }

        @media($min-sm) {
          font-size: .875rem;
        }

        &::before {
          content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDE0IDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMTQgMS4xNDMwNkMxNCAxLjQzNTYxIDEzLjg4NTcgMS43MjgxNiAxMy42NTgyIDEuOTUxTDcuODI0OTYgNy42NjQ4OEM3LjM2ODgxIDguMTExNzEgNi42MzE0OSA4LjExMTcxIDYuMTc1MzMgNy42NjQ4OEwwLjM0MjExOCAxLjk1MUMtMC4xMTQwNCAxLjUwNDE4IC0wLjExNDA0IDAuNzgxOTQ0IDAuMzQyMTE4IDAuMzM1MTE5QzAuNzk4Mjc1IC0wLjExMTcwNyAxLjUzNTU5IC0wLjExMTcwNyAxLjk5MTc1IDAuMzM1MTE5TDcuMDAwMTUgNS4yNDEwNUwxMi4wMDg1IDAuMzM1MTE4QzEyLjQ2NDcgLTAuMTExNzA3IDEzLjIwMiAtMC4xMTE3MDcgMTMuNjU4MiAwLjMzNTExOEMxMy44ODU3IDAuNTU3OTU5IDE0IDAuODUwNTEgMTQgMS4xNDMwNloiIGZpbGw9IiM1NkJDRDIiLz4KPC9zdmc+Cg==");
          height: fit-content;
        }
      }

      &[open] .accordion__header {
        border-bottom: 0;

        &::before {
          transform: rotate(180deg);
        }
      }

      & .accordion__content {
        padding-top: 0;
        border-top: 0;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        line-height: 1.6;
      }
    }

    & .accordion__content {
      border: 0;
    }

    & .accordion__description {
      font-weight: 500;
      font-size: 0.75rem;
      line-height: 130%;
      padding-inline-end: 12px;

      @media($min-sm) {
        font-size: .875rem;
        padding-inline-start: 25px;
      }
    }
  }
}

#o-que-e,
#vantagens {
  padding-top: 30px;
  // border: 1px solid red;
}
