.generic-card {
  padding-block: 4rem;
  background-color: $neutral-white;

  &__container {
    width: 100%;
    max-width: 1180px;
    margin-inline: auto;
    padding-inline: 1rem;
  }

  &__items-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 12px;

    @media ($min-xl) {
      gap: 25px;
    }
  }

  &__item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 335px;
    background: $neutral-white;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, .15);
    border-radius: 45px 0px 37px 35px;
    padding-inline: 30px;
    padding-block: 30px;
    gap: 22px;

  }

  &__title {
    font-weight: 900;
    font-size: 1rem;
    line-height: 1.25rem;
    text-align: center;
    text-transform: uppercase;
    color: $brand-primary-darkest;
  }

  &__body {
    display: flex;
    gap: 6px;
    flex-direction: column;
  }

  &__description {
    font-weight: 500;
    font-size: .75rem;
    line-height: 1rem;
    text-align: center;
    color: $neutral-dark;

    &--highlight {
      font-weight: 700;
      font-size: .6875rem;
      color: $neutral-dark;
    }

    &--smaller {
      font-size: .625rem;
      line-height: .75rem;
    }

    strong {
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.25rem;
      text-align: center;
      color: $brand-primary-darkest;
    }

    >a {
      color: $neutral-dark;
      -moz-hyphens: auto;
    }

  }

  &__description-link {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &__footer {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 18px;
  }

  &__link {
    font-weight: 500;
    font-size: .75rem;
    line-height: .9375rem;
    color: $brand-primary-darkest;
    text-decoration: none;
    display: flex;
    align-items: flex-end;
    gap: 5px;
  }

}