.container-pesquisa {
    background: linear-gradient(90deg, $brand-primary-dark-40 0%, $brand-primary-dark-15 100%);

    width        : 100%;
    border-radius: 0px 0px 143px 0px;


    &__titulo {
        color        : $neutral-white;
        margin       : auto;
        text-align   : center;
        font-size    : 2rem;
        padding-block: 90px
    }

    &__quadro {
        background-color    : $neutral-white;
        min-height          : 300px;
        width               : 90%;
        margin              : auto;
        padding-block       : 50px;
        margin-bottom       : 200px;
        padding-inline      : 40px;
        padding-inline-start: 40px;
        padding-inline-end  : 40px;
        text-align          : justify;

        &--titulo {
            color        : $brand-primary-medium;
            margin-bottom: 5px;
        }

        &--barra-progresso {
            width           : 25%;
            height          : 20px;
            background-color: $neutral-light;
            border-radius   : 20px;
            margin-bottom   : 10px;

            &--valor {
                width           : 10%;
                height          : 20px;
                background-color: $support-03-dark;
                border-radius   : 20px;
            }
        }

        &--sub-titulo {
            color    : $brand-primary-dark;
            font-size: 1.2rem;
        }

        &--negrito {
            color      : $brand-primary-dark;
            font-weight: 700;
        }


        &--perguntas {
            margin-block: 10px;

            &--notas {
                max-width      : 27%;
                display        : flex;
                justify-content: space-between;

                &--botao {

                    height          : 60px;
                    width           : 60px;
                    display         : flex;
                    align-items     : center;
                    justify-content : center;
                    border-radius   : 13px;
                    cursor          : pointer;
                    background-color: red;
                    color           : $neutral-white;
                    font-size       : 18px;
                    font-weight     : 700px;
                }
            }

            &--info {
                padding-block  : 20px;
                max-width      : 27%;
                display        : flex;
                justify-content: space-between;

            }

            &--comentario {
                color        : $brand-primary-dark;
                font-size    : 1.2rem;
                font-weight  : 700;
                padding-block: 25px;
            }

            &--campo {
                width        : 100%;
                height       : 120px;
                border-color : $neutral-light;
                border-radius: 10px;
                padding      : 20px;
                font-size    : 18px;
            }

            #caracteres {
                color: $neutral-medium;
            }

            &--seletor {
                background-color: $neutral-white;
                height          : 45px;
                width           : 350px;
                font-size       : 18px;
                font-weight     : 700;
                border-radius   : 12px;
                border-color    : $neutral-white;
                box-shadow      : 2px 4px 2px 2px #c4c4c48c;
                padding-inline  : 15px;
                padding-block   : 5px;

                option {
                    background-color: $neutral-white;
                    height          : 40px;
                    width           : 250px;
                    font-size       : 18px;
                    font-weight     : 700;
                    border-radius   : 15px;
                    border-color    : $neutral-white;
                    box-shadow      : 2px 4px 2px 2px #c4c4c48c;
                    padding-inline  : 15px;
                    padding-block   : 5px;
                    border          : none;
                    outline         : none;
                }
            }

            &--selecionar {
                display       : flex;
                border-radius : 20px;
                flex-direction: column;
                min-height    : 50px;
                width         : 27%;
                font-size     : 18px;
                font-weight   : 700;
                padding-inline: 20px;
                box-shadow    : 2px 4px 2px 2px #c4c4c48c;
                margin-block: 25px;
                &--expandir{
                    display: flex;
                    justify-content: space-between;
                    padding-inline: 10px;
                    padding-block: 15px;

                    img{
                        height: 30px;
                        width: 30px;
                    }
                }
                
                &--valores {
                    border-bottom  : 1px solid #c4c4c48c;
                    padding-block  : 13px;
                    display        : flex;
                    justify-content: flex-start;

                    &--check {
                        height       : 20px;
                        width        : 20px;
                        border       : 2px solid $brand-primary-light-10;
                        border-radius: 200px;
                        margin-inline: 20px;
                    }
                    option{
                        font-size     : 18px;
                        font-weight   : 700;
                    }
                }
            }
        }

        &--button {

            display        : flex;
            justify-content: left;
            margin-top     : 35px;

            a {
                background    : $support-02-medium-05;
                border-radius : 32px;
                color         : $brand-primary-medium;
                font-size     : 1.125rem;
                font-weight   : 800;
                line-height   : 38px;
                text-transform: capitalize;
                width         : 281px;

                @media ($min-md) {
                    width: 334px;
                }

            }
        }

    }

    .yellow-text {
        color: $support-02-medium-05;
    }

    @media (max-width: $sm) {
        &__titulo {
            font-size    : 1.35rem;
            padding-block: 90px 20px;
        }

        &__quadro {
            &--barra-progresso {
                width: 100%;
            }

            &--titulo {

                margin-bottom: 10px;
                text-align   : left;

                h1 {
                    font-size: 1.375rem;
                }
            }

            &--sub-titulo {
                font-size: 0.975rem;
            }

            &--negrito {
                font-weight: 700;
            }

            &--perguntas {
                margin-block: 10px;

                &--notas {
                    max-width      : 100%;
                    display        : flex;
                    justify-content: space-between;

                    &--botao {

                        height: 50px;
                        width : 50px;
                    }
                }

                &--info {
                    padding-block  : 20px;
                    max-width      : 100%;
                    display        : flex;
                    justify-content: space-between;
                    font-size      : 14px;

                }

                &--comentario {
                    color        : $brand-primary-dark;
                    font-size    : 0.975rem;
                    font-weight  : 700;
                    padding-block: 25px;
                }

                &--campo {
                    width        : 100%;
                    height       : 120px;
                    border-color : $neutral-light;
                    border-radius: 10px;
                    padding      : 20px;
                    font-size    : 14px;
                }

                #caracteres {
                    font-size: 14px;
                    color    : $neutral-medium;
                }

                &--selecionar {
                    width         : 100%;
                }
                
            }
        }

    }
}