.anchor-v3 {
  position: relative;
  margin-top: -65px;
  display: none;
  border-radius: 0 0 0 90px;

  &::before {
    position: absolute;
    height: 1px;
    width: calc(100% - 600px);
    color: rgba(255, 255, 255, 0.2);
    background-color: currentColor;
    top: calc(100% - 65px);

    @media($min-xl){
      content: '';
    }
  }

  @media($min-xl){
    display: block;
  }

  &__nav {
    display: flex;
    align-items: center;
    height: 65px;
    margin: auto;
    max-width: 1180px;
    padding-inline: 50px;
  }

  &__list {
    gap: 80px;
    display: flex;
    list-style: none;
    width: 100%;
  }

  &__item {}

  &__pointer {
    color: #FFFFFF;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 800;
    line-height: .9375rem;
    text-transform: uppercase;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}