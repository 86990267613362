/**************************\
  Basic Modal Styles
\**************************/
.modal {
  position: relative;
  z-index: $modal-index;

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba($neutral-darkest, .5);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__container {
    background-color: $neutral-white;
    border-radius: 20px 0px 20px 20px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
    max-height: 100vh;
    max-width: 415px;
    min-height: 320px;
    overflow-y: auto;
    padding: 18px 25px 53px;
    width: 80%;
    display: flex;
    flex-direction: column;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__title {
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.25rem;
    color: $brand-primary-dark;
    text-transform: uppercase;
  }

  &__logo {
    margin-bottom: 8px;

    &--feedback-success, &--feedback-alert {
      margin-bottom: 12px;
      display: none;

      &--open {
        display: block;
      }
    }
  }

  &__info {
    margin-bottom: 16px;
    text-align: center;
    max-width: 240px;
    font-weight: 700;
    font-size: .6875rem;
    line-height: .75rem;
    color: $neutral-dark;
  }

  &__link {
    font-weight: 700;
    font-size: .6875rem;
    line-height: .75rem;
    color: $neutral-dark;
  }

  &__description {
    font-weight: 500;
    font-size: .6875rem;
    line-height: .75rem;
    color: $neutral-dark;
    text-align: center;
    max-width: 240px;

    &--feedback {
      font-weight: 500;
      font-size: .875rem;
      line-height: 130%;
      text-align: center;
      color: #0B4051;
      margin-bottom: 24px;
    }
  }

  &__close {
    background: transparent;
    border: 0;
    position: relative;
    transform: translate(14px, -8px);
    color: $neutral-dark;

    &:before {
      content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNC4xMjE0IDEyLjAwMDRMMjAuNTYwOSA1LjU2MDg3QzIxLjE0NzQgNC45NzQzNyAyMS4xNDc0IDQuMDI2MzcgMjAuNTYwOSAzLjQzOTg3QzE5Ljk3NDQgMi44NTMzOCAxOS4wMjY0IDIuODUzMzggMTguNDM5OSAzLjQzOTg3TDEyLjAwMDQgOS44NzkzN0w1LjU2MDg3IDMuNDM5ODdDNC45NzQzOCAyLjg1MzM4IDQuMDI2MzggMi44NTMzOCAzLjQzOTg4IDMuNDM5ODdDMi44NTMzNyA0LjAyNjM3IDIuODUzMzcgNC45NzQzNyAzLjQzOTg4IDUuNTYwODdMOS44NzkzNyAxMi4wMDA0TDMuNDM5ODggMTguNDM5OUMyLjg1MzM3IDE5LjAyNjQgMi44NTMzNyAxOS45NzQ0IDMuNDM5ODggMjAuNTYwOUMzLjczMjM3IDIwLjg1MzQgNC4xMTYzNyAyMS4wMDA0IDQuNTAwMzggMjEuMDAwNEM0Ljg4NDM3IDIxLjAwMDQgNS4yNjgzNyAyMC44NTM0IDUuNTYwODcgMjAuNTYwOUwxMi4wMDA0IDE0LjEyMTRMMTguNDM5OSAyMC41NjA5QzE4LjczMjQgMjAuODUzNCAxOS4xMTY0IDIxLjAwMDQgMTkuNTAwNCAyMS4wMDA0QzE5Ljg4NDQgMjEuMDAwNCAyMC4yNjg0IDIwLjg1MzQgMjAuNTYwOSAyMC41NjA5QzIxLjE0NzQgMTkuOTc0NCAyMS4xNDc0IDE5LjAyNjQgMjAuNTYwOSAxOC40Mzk5TDE0LjEyMTQgMTIuMDAwNFoiIGZpbGw9IiM0RjRGNEYiLz4KPC9zdmc+Cg==");
    }
  }
}


/**************************\
  Demo Animation Style
\**************************/
@keyframes mmfadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes mmfadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes mmslideIn {
  from {
    transform: translateY(15%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes mmslideOut {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-10%);
  }
}

.micromodal-slide {
  display: none;

  &.is-open {
    display: block;
  }

  &[aria-hidden="false"] .modal__overlay {
    animation: mmfadeIn .3s cubic-bezier(0.0, 0.0, 0.2, 1);
  }

  &[aria-hidden="false"] .modal__container {
    animation: mmslideIn .3s cubic-bezier(0, 0, .2, 1);
  }

  &[aria-hidden="true"] .modal__overlay {
    animation: mmfadeOut .3s cubic-bezier(0.0, 0.0, 0.2, 1);
  }

  &[aria-hidden="true"] .modal__container {
    animation: mmslideOut .3s cubic-bezier(0, 0, .2, 1);
  }

  & .modal__container,
  & .modal__overlay {
    will-change: transform;
  }
}