// colors
// $brand-primary-dark - azul
// $support-03-medium - verde
// $neutral-dark - cinza
// $neutral-lightest - cinza-claro
// .footer {
//     background-color: $neutral-white;
// }
#main{

    .loader-feirao {
        width        : 48px;
        height       : 48px;
        border-radius: 50%;
        position     : relative;
        animation    : rotate 1s linear infinite
    }
    
    .loader-feirao::before,
    .loader-feirao::after {
        content      : "";
        box-sizing   : border-box;
        position     : absolute;
        inset        : 0px;
        border-radius: 50%;
        border       : 5px solid $brand-secondary-medium;;
        animation    : prixClipFix 2s linear infinite;
    }
    
    .loader-feirao::after {
        transform   : rotate3d(90, 90, 0, 180deg);
        border-color: $brand-secondary-medium;
    }
    
    @keyframes rotate {
        0% {
            transform: rotate(0deg)
        }
    
        100% {
            transform: rotate(360deg)
        }
    }
    
    @keyframes prixClipFix {
        0% {
            clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0)
        }
    
        50% {
            clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0)
        }
    
        75%,
        100% {
            clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%)
        }
    }
    .div-loading-feirao{
        display: flex;
        padding-block: 3vh;
        justify-content: space-around;
    }
    .null-result{
        font-size: 20px;
        font-weight: 900;
        color:  $brand-primary-darkest;
        @media(max-width: $sm){
            font-size: 15px;
        }
    }
    .div-result-feirao{
        display: none;
        padding-block: 3vh;
        justify-content: flex-start;
    }
    
    
    #disclaimer-feirao {
        background-color: $neutral-white;
    
        p {
            font-weight  : 500;
            font-size    : .75rem;
            line-height  : 1.0625rem;
            color        : #125a70;
            margin-bottom: 0.5rem;
        }
    }
    
    #main-feirao {
        background-color: $neutral-lightest !important;
    
        @media(max-width: $sm) {
            background-color: $neutral-lightest !important;
        }
    }
    
    #desktop-feirao {
        display: flex !important;
    
        @media(max-width: $sm) {
            display: none !important;
        }
    }

    #desktop-feirao-imagem {
        display: flex !important;
    
        @media(max-width: $sm) {
            display: none !important;
        }
    }
    
    .mobile-feirao {
        display: none !important;
    
        @media(max-width: $sm) {
            display: block !important;
        }
    
        
    }
}
.banner-header-lp-feirao {
    display        : flex;
    justify-content: space-evenly;
    align-items    : center;
    height         : 650px;

    &__texto {
        width          : 50%;
        height         : inherit;
        display        : flex;
        align-items    : center;
        justify-content: space-around;
        background     : linear-gradient(90deg, $neutral-white, $neutral-lightest);

        h1 {
            font-weight: 900;
            font-size  : 40px;
            color      : $brand-primary-dark;
            margin     : 25px 0px;
        }

        &--color {
            color: $support-03-medium;
        }

        &--subtitle {
            color    : $neutral-dark;
            font-size: 24px;
        }

        p {
            color           : $brand-primary-darkest;
            background-color: $support-03-medium;
            padding         : 20px;
            font-size       : 24px;
            border-radius   : 0px 30px 30px;
            margin          : 25px 0px;
            text-align      : center;
        }

        &--taxa {
            font-weight: 800;
            font-size  : 32px;

        }

        &--texto {
            color    : $brand-primary-darkest;
            font-size: 18px;
        }
    }

    &__imagem {
        width          : 50%;
        background     : linear-gradient(90deg, #075060, #0EA0C0);
        height         : inherit;
        display        : flex;
        justify-content: space-around;
        align-items    : center;
        clip-path      : polygon(10% 0, 100% 0, 100% 100%, 0% 100%);

    }
    
    &__imagem-mobile {
        padding-inline: 4vh !important;
    }
    @media(max-width: $sm) {
        padding-top   : 75px;
        padding-inline: 35px;
        margin        : auto;
        background    : linear-gradient(360deg, #0EA0C0, #075060);
        margin        : 0;
        border-radius : 0px 0px 90px 0px;
        height        : auto;
        padding-bottom: 75px;

        &__texto {
            width     : 100%;
            background: transparent;

            &--container {
                margin-bottom: 25px;


            }

            h1 {
                font-weight: 900;
                font-size  : 30px;
                color      : $neutral-white;
                margin     : 25px 0px;
            }

            &--subtitle {
                color: $neutral-white;
                ;
                font-size: 17px;
            }

            p {
                color           : #002c37;
                background-color: #73d852;
                padding         : 10px;
                font-size       : 16px;
                border-radius   : 0px 30px 30px;
                margin          : 30px 0px;
                text-align      : center;
            }

            &--taxa {
                font-weight: 800;
                font-size  : 18px;

            }

            &--texto {
                color    : $brand-primary-darkest;
                font-size: 14px;
            }
        }
    }

    .detalhe-superior {
        position: absolute;
        right   : 83px;
        top     : 62px;
    }

    .detalhe-lateral-1 {
        position: absolute;
        right   : 0;
        top     : 62px
    }

    .detalhe-lateral-2 {
        position: absolute;
        right   : 0;
        top     : 148px;
    }

    .detalhe-lateral-3 {
        position: absolute;
        right   : 0;
        top     : 350px
    }

    .detalhe-lateral-4 {
        position: absolute;
        right   : 0;
        top     : 436px;
    }
}

.banner-car {
    background-color: $neutral-lightest;
    display         : flex;
    justify-content : space-around;
    flex-direction  : column;
    align-items     : center;

    img {
        position: absolute;
    }

    &__desktop {
        @media(max-width: $sm) {
            display: none;
        }
    }

    &__mobile {
        width  : -webkit-fill-available;
        display: none;

        @media(max-width: $sm) {
            display: block;
        }
    }
}

.container-titulo {
    height          : 300px;
    background-color: $neutral-lightest;
    display         : flex;
    justify-content : center;
    flex-direction  : column;
    align-items     : center;
    margin-top      : 80px;

    h1 {
        font-size  : 32px;
        font-weight: 900;
        color      : $brand-primary-darkest;
    }

    span {
        font-weight: 700;
        font-size  : 32px;
        color      : $brand-secondary-medium;
        display    : flex;
        align-items: center;
    }

    @media(max-width: $sm) {
        text-align    : -webkit-center;
        margin-top    : 30px;
        padding-inline: 2px;

        h1,
        span {
            font-size: 26px;
        }
    }

}

.session-store {
    background-color: $neutral-lightest;
    height          : auto;

    &__container {
        background-color    : #fff;
        height              : 300px;
        border-radius       : 200px 0px 0px 0px;
        display             : flex;
        padding-inline-start: 200px;
        padding-top         : 60px;
        flex-direction      : column;
        justify-content     : center;
        height              : auto;

        h1 {
            font-size    : 32px;
            font-weight  : 900;
            color        : $brand-primary-darkest;
            margin-bottom: 15px;
        }

        &--titulo-color {
            color: $brand-secondary-medium;
        }

        &--subtitulo {
            font-weight: 600;
            font-size  : 20px;
            color      : $neutral-dark-10;
        }
    }

    .form__group {
        position    : relative;
        padding     : 20px 0 0;
        margin-top  : 10px;
        width       : 30%;
        margin-right: 20px;
    }

    .form__field {
        font-family  : inherit;
        width        : 100%;
        border       : 0;
        border       : 1px solid $neutral-lightest;
        outline      : 0;
        font-size    : 16px;
        color        : $neutral-dark-10;
        padding      : 30px 0px 15px 20px;
        background   : transparent;
        transition   : border-color 0.2s;
        border-radius: 10px;

    }

    .form__label {
        position   : absolute;
        top        : 25px;
        display    : block;
        left       : 21px;
        font-size  : 14px;
        font-weight: 700;
        color      : $brand-primary-light;

    }

    .form__field {

        &:required,
        &:invalid {
            box-shadow: none;
        }
    }

    &__inputs {
        display    : flex;
        padding-top: 40px;

        &--buttons {
            display    : flex;
            align-items: center;
            margin     : 0px 10px;

            button {
                background-color: $brand-primary-medium;
                color           : $neutral-white;
                border-radius   : 20px;
                font-size       : 14px;
                font-weight     : 700;
                width           : 150px;
                border          : 0px;
                height          : 40px;
                margin          : 0px 10px;
            }

            &--buscar {
                display        : flex;
                align-items    : center;
                justify-content: space-around;
            }
        }

    }

    &__resultado {
        margin: 30px 0px;

        h2 {
            color      : $brand-primary-light;
            font-size  : 16px;
            font-weight: 700;
        }

        &--container-principal {
            display       : flex;
            flex-direction: column;
            overflow-y    : scroll;
            max-height    : 500px;
            width         : 85%;

        }

        &--container {
            display      : flex;
            align-items  : center;
            border-bottom: 1px solid $neutral-light;
            //width: 85%;
            padding-block: 15px;
        }

        &--imagem {
            width: 140px;
        }

        &--dados {
            margin-left: 145px;
        }

        .titulo-dados {
            color: $neutral-dark;
        }
    }

    @media(max-width: $sm) {


        &__container {
            padding-inline-start: 35px;
            padding-inline      : 35px;
            border-radius       : 0px;
            background-color    : #fff;
            height              : 300px;
            display             : -webkit-box;
            display             : -ms-flexbox;
            display             : flex;
            padding-top         : 60px;
            flex-direction      : column;
            justify-content     : center;
            height              : auto;

            h1 {
                font-size    : 30px;
                font-weight  : 900;
                color        : $brand-primary-darkest;
                margin-bottom: 15px;
            }

            &--subtitulo {
                font-weight: 600;
                font-size  : 16px;
                color      : $neutral-dark-10;
            }
        }

        &__inputs {
            display       : -webkit-box;
            display       : -ms-flexbox;
            display       : flex;
            padding-top   : 40px;
            flex-direction: column;
        }

        .form__group {
            width: 100%;

        }

        &__resultado {
            margin: 30px 0px;

            p {
                font-size: 13px;
            }

            h2 {
                color      : $brand-primary-light;
                font-size  : 16px;
                font-weight: 700;
            }

            &--container-principal {
                display       : flex;
                flex-direction: column;
                width         : 90%;
            }

            &--container {
                display       : flex;
                border-bottom : 1px solid #d8d8d8;
                padding-block : 15px;
                flex-direction: column;
                align-items   : flex-start;
            }

            &--dados {
                margin-left: 0px;
            }

            .titulo-dados {
                color: $neutral-dark;
            }
        }

        .form__field {
            font-family  : inherit;
            width        : 100%;
            border       : 0;
            border       : 1px solid $neutral-lightest;
            outline      : 0;
            font-size    : 14px;
            color        : $neutral-dark-10;
            padding      : 30px 0px 15px 20px;
            background   : transparent;
            transition   : border-color 0.2s;
            border-radius: 10px;

        }
    }

}