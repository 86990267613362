.header-pesquisa {
    display         : flex;
    justify-content : center;
    width           : 100%;
    height          : $header-desktop;
    background-color: $neutral-white;
    padding         : 0 15px;
    z-index         : $header-index;
    box-shadow      : 0 1px 3px rgba(0, 0, 0, .2);
    position        : fixed;

    @media ($min-xl) {
        position  : static;
        box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
    }

    &__container {
        display        : flex;
        justify-content: space-between;
        align-items    : center;
        width          : 100%;
        max-width      : 1180px;
    }

    &__logo {
        margin     : auto;
        font-size  : 0;
        line-height: 0;

        img {
            width: 136px;

            @media ($min-xl) {
                width: 170px;
            }
        }
    }
}