.banner-header-lp-v4 {
  background: $neutral-lightest;

  &__background {
    background: linear-gradient(90deg, #075060 0%, #0ea0c0 100%);
    border-radius: 0px 0px 52px 0px;

    @media ($min-lg) {
      border-radius: 0 0 186px 0;
      margin-top: 60px;
    }
  }

  &__container {
    margin: auto;
    margin-right: 0px;
    max-width: 1180px;
    padding-inline-start: 25px;
    display: flex;
    justify-content: center;
    position: relative;

    @media (max-width: $sm) {
      margin-top: 60px !important;
    }
    @media ($min-xl) {
      // padding-inline: 25px;
      border-radius: 0 0 110px 0;
    }
    @media ($min-hd) {
      margin: auto;
    }

    @media ($mobile-default) {
      margin-top: 60px;
      justify-content: flex-start;
      padding-bottom: 20px;
      margin-left: 5px;
    }
  }
  &__detalhe {
    position: absolute;
    z-index: 3;
    width: 98%;
    background-position: right;
    background-repeat: no-repeat;
    height: 350px;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNzAiIGhlaWdodD0iMzA1IiB2aWV3Qm94PSIwIDAgNzAgMzA1IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMS42MDc0MiAyMjcuNzVMNzEuNjc2MiAyMjcuNzVMNzEuNjc2MiAzMDNDMzMuMTI4NyAzMDIuNjQxIDEuOTQxODIgMjY5LjE0OCAxLjYwNzQyIDIyNy43NVoiIHN0cm9rZT0iIzdGQUJCNyIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiLz4KPHBhdGggZD0iTTEuNjA3NDIgMjI3Ljc1TDcxLjY3NjIgMjI3Ljc1TDcxLjY3NjIgMTUyLjVDMzMuMTI4NyAxNTIuODU5IDEuOTQxODIgMTg2LjM1MiAxLjYwNzQyIDIyNy43NVoiIHN0cm9rZT0iIzdGQUJCNyIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiLz4KPHBhdGggZD0iTTEuNjA3NDIgNzcuMjQ5OUwxLjYwNzQyIDE1Mi41TDcxLjY3NjIgMTUyLjVDNzEuMzQxOCAxMTEuMTAyIDQwLjE1NDkgNzcuNjA5IDEuNjA3NDIgNzcuMjQ5OVY3Ny4yNDk5WiIgc3Ryb2tlPSIjN0ZBQkI3IiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIvPgo8cGF0aCBkPSJNNzEuNjc2MiAxLjk5OTk2TDcxLjY3NjIgNzcuMjVMMS42MDc0MiA3Ny4yNUMxLjk0MTgyIDM1Ljg1MjEgMzMuMTI4NyAyLjM1OTA5IDcxLjY3NjIgMS45OTk5NloiIHN0cm9rZT0iIzdGQUJCNyIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiLz4KPC9zdmc+Cg==");

    @media (max-width: $sm) {
      display: none;
    }
  }
  picture {
    display: flex;
    clip-path: polygon(40% 0, 100% 0, 100% 100%, 55% 100%);
    position: absolute;
    right: 0;

    @media ($min-sm) {
      clip-path: polygon(10% 0, 100% 0, 100% 100%, 22% 100%);
    }

    @media ($min-md) {
      position: relative;
      right: auto;
    }

    > img {
      object-fit: cover;
      width: 306;
      height: 212px;

      @media ($min-lg) {
        width: auto;
        height: auto;
        border-radius: 0 0 110px 0;
      }
    }
  }

  &__content {
    display: flex;
    align-items: center;
    padding-bottom: 25px;

    @media ($min-xl) {
      margin-top: 2.5rem;
    }
  }

  &__content-text {
    top: 25px;
    position: relative;
  }

  &__block-text {
    position: relative;
  }

  &__title {
    color: $neutral-white;
    font-weight: 900;
    text-transform: uppercase;
    white-space: pre-line;
    font-size: 1.75rem;
    line-height: 2.125rem;
    max-width: 220px;
    margin-bottom: 40px;

    @media ($min-sm) {
      margin-bottom: 2rem;
      font-size: 1.5rem;
      line-height: 1.29;
      max-width: none;
    }

    @media ($min-md) {
      font-size: 1.8rem;
    }

    @media ($min-xl) {
      font-size: 2rem;
      line-height: 1.06;
    }
  }

  &__subtitle {
    font-weight: 400;
    color: $neutral-white;
    margin-bottom: 1.5rem;
    font-size: 1rem;
    line-height: 1.25rem;

    @media ($min-md) {
      font-size: 1.375rem;
      line-height: 1.6875rem;
    }
  }

  &__icon {
    margin: 40px 0 20px 0;

    @media ($min-xl) {
      margin: 0;
      position: absolute;
      top: -60px;
      right: -50px;
    }
  }

  &__list {
    list-style: none;

    > li {
      color: $neutral-white;
      display: flex;

      line-height: 1.25rem;
      margin-bottom: 1rem;
      font-size: 0.75rem;
      line-height: 0.9375rem;

      @media ($min-md) {
        font-weight: 500;
        font-size: 1rem;
      }

      &::before {
        background-color: $support-02-medium;
        border-radius: 50%;
        content: "";
        height: 12px;
        margin: 3px 10px 0 0;
        min-width: 12px;
      }
    }
  }

  &__anchor {
    display: none;
    position: relative;

    &::before {
      position: absolute;
      height: 1px;
      width: calc(100% - 500px);
      color: rgba(255, 255, 255, 0.2);
      background-color: currentColor;
      top: calc(100% - 65px);

      @media ($min-xl) {
        content: "";
      }
    }

    @media ($min-xl) {
      display: block;
    }
  }

  &__anchor-nav {
    display: flex;
    align-items: center;
    height: 65px;
    margin: auto;
    max-width: 1180px;
    padding-inline: 25px;
  }

  &__anchor-list {
    gap: 80px;
    display: flex;
    list-style: none;
    width: 100%;
  }

  &__anchor-pointer {
    color: $neutral-white;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 800;
    line-height: 0.9375rem;
    text-transform: uppercase;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &__img-logo {
    top: 20px;
    position: absolute;

    @media ($min-xl) {
      top: 50px;
    }
  }
}

.banner-header-lp-v4-form {
  background: $neutral-lightest;

  &__background {
    background: linear-gradient(90deg, #075060 0%, #0ea0c0 100%);
    border-radius: 0px 0px 52px 0px;

    @media ($min-lg) {
      border-radius: 0 0 186px 0;
    }

    // mobile
    @media (max-width: $lg) {
      border-radius: 0px;
    }
  }

  &__container {
    margin: auto;
    max-width: 1280px;
    display: flex;
    justify-content: center;
    position: relative;
    flex-direction: column;

    @media ($min-xl) {
      border-radius: 0 0 110px 0;
      justify-content: flex-end;
      flex-direction: row;
    }

    // mobile
    @media ($mobile-default) {
      margin-top: 60px;
    }
  }

  picture {
    display: flex;
    clip-path: polygon(40% 0, 100% 0, 100% 100%, 55% 100%);
    position: absolute;
    right: 0;

    @media ($min-sm) {
      clip-path: polygon(10% 0, 100% 0, 100% 100%, 22% 100%);
    }

    @media ($min-md) {
      position: relative;
      right: auto;
    }

    > img {
      object-fit: cover;
      width: 306;
      height: 212px;

      @media ($min-lg) {
        width: auto;
        height: auto;
        border-radius: 0 0 110px 0;
      }
    }
  }

  &__content {
    display: flex;
    align-items: center;
    padding-block-end: 25px;
    padding-inline: 25px;
    @media (max-width: $sm) {
      // margin-top: 15px;
      margin-top: 10px;
      width: auto;
      padding: 0 30px 30px 30px;
    }
    @media ($min-xl) {
      padding-inline-start: 50px;
      width: 55%;
    }
  }

  &__content-text {
    top: 25px;
    position: relative;
    max-width: 720px;
    width: 100%;

    &.mobile {
      padding-inline: 25px;
      position: initial;
      display: none;
      // mobile
      @media (max-width: $lg) {
        display: block;
      }
    }
  }

  &__block-text {
    position: relative;
  }

  &__title {
    color: $neutral-white;
    font-weight: 900;
    text-transform: uppercase;
    white-space: pre-line;
    font-size: 1.75rem;
    line-height: 2.125rem;
    margin-bottom: 20px;

    @media ($min-sm) {
      margin-bottom: 2rem;
      font-size: 1.5rem;
      line-height: 1.29;
      max-width: none;
    }

    @media ($min-md) {
      font-size: 1.8rem;
    }

    @media ($min-xl) {
      font-size: 2.125rem;
      line-height: 1.06;
    }
  }

  &__subtitle {
    font-weight: 400;
    color: $neutral-white;
    margin-bottom: 1.5rem;
    font-size: 1rem;
    line-height: 1.25rem;

    @media ($min-md) {
      font-size: 1.375rem;
      line-height: 1.6875rem;
    }
  }

  &__logo-safra {
    margin-bottom: 35px;
    // mobile
    @media (max-width: $lg) {
      display: none;
    }
  }

  &__icon {
    margin: 0px;

    @media ($min-xl) {
      margin: 0;
      // position: absolute;
      // top: -60px;
      // right: 160px;
      position: initial;
      margin-left: 69%;
    }

    // mobile
    @media (max-width: $lg) {
      // margin: 40px 0px 10px 0px;
    }
  }

  &__list {
    list-style: none;

    &.desktop {
      // mobile
      @media (max-width: $lg) {
        display: none;
      }
    }

    > li {
      color: $neutral-white;
      display: flex;

      line-height: 1.25rem;
      margin-bottom: 1rem;
      font-size: 0.875rem;
      line-height: 0.9375rem;

      @media ($min-md) {
        font-weight: 500;
        font-size: 1rem;
      }

      &::before {
        background-color: $support-02-medium;
        border-radius: 50%;
        content: "";
        height: 12px;
        margin: 3px 10px 0 0;
        min-width: 12px;
      }
    }
  }

  &__anchor {
    display: none;
    position: relative;

    &::before {
      position: absolute;
      height: 1px;
      width: calc(100% - 500px);
      color: rgba(255, 255, 255, 0.2);
      background-color: currentColor;
      top: calc(100% - 65px);

      @media ($min-xl) {
        content: "";
      }
    }

    @media ($min-xl) {
      display: block;
    }
  }

  &__anchor-nav {
    display: flex;
    align-items: center;
    height: 65px;
    margin: auto;
    max-width: 1180px;
    padding-inline: 25px;
  }

  &__anchor-list {
    gap: 80px;
    display: flex;
    list-style: none;
    width: 100%;
  }

  &__pointer {
    color: $neutral-white;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 800;
    line-height: 0.9375rem;
    text-transform: uppercase;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &__img-logo {
    top: 20px;
    position: absolute;

    @media ($min-xl) {
      top: 50px;
    }
  }

  // FORM
  &__form-content {
    // max-height: 629px;
    background: $neutral-white;

    @media ($min-xl) {
      border-radius: 0 0 91px 0;
      padding-left: 50px;
      padding-right: 15px;
      clip-path: polygon(0 0, 100% 0, 100% 100%, 20% 100%);
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzYiIGhlaWdodD0iMTU2IiB2aWV3Qm94PSIwIDAgMzYgMTU2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMS42NzE4NyAxMTZMMzcuMDU1NCAxMTZMMzcuMDU1NCAxNTRDMTcuNTg5NiAxNTMuODE5IDEuODQwNzQgMTM2LjkwNSAxLjY3MTg3IDExNloiIHN0cm9rZT0iIzcxQUJCOCIgc3Ryb2tlLW9wYWNpdHk9IjAuMiIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiLz4KPHBhdGggZD0iTTEuNjcxODggMTE2TDM3LjA1NTQgMTE2TDM3LjA1NTQgNzguMDAwMUMxNy41ODk2IDc4LjE4MTUgMS44NDA3NCA5NS4wOTQ5IDEuNjcxODggMTE2WiIgc3Ryb2tlPSIjNzFBQkI4IiBzdHJva2Utb3BhY2l0eT0iMC4yIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIvPgo8cGF0aCBkPSJNMS42NzE4NyAzOS45OTk5TDEuNjcxODggNzcuOTk5OUwzNy4wNTU0IDc3Ljk5OTlDMzYuODg2NiA1Ny4wOTQ2IDIxLjEzNzcgNDAuMTgxMyAxLjY3MTg3IDM5Ljk5OTlWMzkuOTk5OVoiIHN0cm9rZT0iIzcxQUJCOCIgc3Ryb2tlLW9wYWNpdHk9IjAuMiIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiLz4KPHBhdGggZD0iTTM3LjA1NTQgMS45OTk5OEwzNy4wNTU0IDQwTDEuNjcxODggNDBDMS44NDA3NCAxOS4wOTQ3IDE3LjU4OTYgMi4xODEzMyAzNy4wNTU0IDEuOTk5OThaIiBzdHJva2U9IiM3MUFCQjgiIHN0cm9rZS1vcGFjaXR5PSIwLjIiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIi8+Cjwvc3ZnPgo=");
      background-position: 100% 0;
      background-repeat: no-repeat;
    }

    // mobile
    @media (max-width: $lg) {
      border-radius: 0 0 125px;
      margin-bottom: 30px;
    }
  }

  &__form {
    position: relative;
    z-index: 10;
    height: 100%;
    width: 100%;
    padding: 25px;

    @media ($min-xl) {
      width: 438px;
      padding: 48px;
    }

    & .form-group-double {
      display: flex;
      gap: 12px;
      flex-direction: column;

      @media ($min-md) {
        flex-direction: row;
      }

      & .form-group {
        width: 100%;

        @media ($min-md) {
          width: 50%;
        }
      }
    }

    & .has-danger {
      & label,
      & select {
        border-color: $feedback-danger-dark;

        &:focus-within {
          outline-color: $feedback-danger-dark;
        }
      }

      & input,
      & textarea {
        color: $feedback-danger-dark;
      }

      & .pristine-error {
        line-height: 1.2;
        color: $feedback-danger-dark;
        margin-top: 0.1rem;
        margin-left: 0.25rem;
        font-size: 0.75rem;

        @media ($min-lg) {
          font-size: 0.8rem;
          margin-left: 0.5rem;
        }
      }
    }
  }

  &__message-container {
    display: none;
    position: relative;
    z-index: 10;
    background: $neutral-white;
    height: 100%;
    width: 100%;
    padding: 25px;

    @media ($min-xl) {
      border-radius: 0 0 157px 0;
      width: 438px;
      padding: 48px;
    }
  }

  &__fieldset {
    display: flex;
    flex-direction: column;
    gap: 0.9375rem;
    border: 0;
  }

  &__legend {
    font-weight: 700;
    font-size: 1rem;
    line-height: 20px;
    color: $feedback-info-darkest;
    margin-bottom: 1rem;
  }

  &__terms-block {
    display: flex;
    flex-direction: column;
    margin: 0.75rem 0.5rem;
    gap: 0.5rem;
  }

  &__info-terms {
    font-weight: 500;
    font-size: 0.6875rem;
    line-height: 0.9375rem;
    color: $feedback-info-darkest;
  }

  &__accept-terms {
    cursor: pointer;
    display: flex;
    align-items: flex-start;
    gap: 0.75rem;
  }

  &__accept-terms ~ .pristine-error {
    margin-left: 1.6rem !important;

    @media ($min-lg) {
      margin-left: 1.5rem !important;
    }
  }

  &__accept-text {
    font-weight: 500;
    font-size: 0.625rem;
    line-height: 0.8125rem;
    color: $feedback-info-darkest;

    > a {
      color: inherit;
    }
  }

  &__accept-checkbox {
    cursor: pointer;
    width: auto;
    flex: 0 0 16px;
  }

  &__button-container {
    display: flex;
    justify-content: flex-start;
    margin-inline: 1.875rem;
    margin-top: 1.125rem;

    @media ($min-xs) {
      margin-top: 1.625rem;
    }

    > button {
      min-width: 237px;
      text-transform: none;
      font-weight: 700;
      font-size: 0.875rem;
      line-height: 130%;
      text-transform: uppercase;

      &:disabled,
      &[disabled] {
        cursor: default;
        opacity: 0.5;
      }
    }
  }

  &__message {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 1.5rem;
    margin: 1.5rem;

    @media ($min-md) {
      gap: 2rem;
      margin-top: 4rem;
    }
  }

  &__message-image {
    width: 64px;
    height: 64px;
  }

  &__message-description {
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.5rem;
    color: $feedback-info-darkest;

    @media ($min-md) {
      font-size: 1.375rem;
      line-height: 1.6875rem;
    }
  }
}

.info-lp-v4 {
  background-color: $neutral-lightest;

  &__container {
    margin: auto;
    max-width: 1180px;
    padding-block: 50px 30px;
    padding-inline: 25px;
  }

  &__title {
    font-weight: 900;
    text-transform: uppercase;
    color: $brand-primary-dark;
    margin-bottom: 24px;
    font-size: 1.25rem;
    line-height: 1.5rem;

    @media ($min-lg) {
      font-size: 1.625rem;
      line-height: 2rem;
    }
  }

  &__description {
    text-align: justify;
    font-weight: 500;
    color: $neutral-dark;
    font-size: 0.875rem;
    line-height: 1.125rem;

    @media ($min-lg) {
      font-size: 1.125rem;
      line-height: 1.5rem;
    }
  }
}

.who-can-hire {
  background-color: $neutral-lightest;

  &__container {
    margin: auto;
    max-width: 1180px;
    padding-block: 50px 80px;
    padding-inline: 25px;
    z-index: 2;
    position: relative;
  }

  &__title {
    font-weight: 900;
    font-size: 1.25rem;
    line-height: 1.5rem;
    text-transform: uppercase;
    color: $brand-primary-dark;
    margin-bottom: 32px;

    @media ($min-md) {
      font-size: 1.625rem;
      line-height: 2rem;
    }
  }

  &__cards-container {
    display: flex;
    justify-content: center;
    gap: 12px;
    flex-wrap: wrap;
  }

  &__card {
    // min-width: 275px;

    min-height: 256px;
    min-width: 273px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
    border-radius: 45px 0 37px 35px;
    background: $neutral-white;
    padding-inline: 32px;
    padding-block: 26px;
    justify-content: space-evenly;
    gap: 12px;

    @media (max-width: $sm) {
      min-width: 100%;
    }
    @media ($min-sm) {
      width: calc((100% / 2) - 12px);
    }

    @media ($min-md) {
      width: calc((100% / 3) - 12px);
    }

    @media ($min-lg) {
      width: calc((100% / 4) - 12px);
    }

    & > a:empty {
      display: none;
    }
  }

  &__card-title {
    font-weight: 900;
    font-size: 1.1875rem;
    line-height: 1.4375rem;
    text-transform: uppercase;
    color: $brand-primary-dark;
    min-height: 69px;
  }

  &__card-descrption {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    color: $neutral-dark;
  }
}

.how-to-hire {
  position: relative;

  @media ($min-xl) {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNzAiIGhlaWdodD0iMzA1IiB2aWV3Qm94PSIwIDAgNzAgMzA1IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMS42MDc0MiAyMjcuNzVMNzEuNjc2MiAyMjcuNzVMNzEuNjc2MiAzMDNDMzMuMTI4NyAzMDIuNjQxIDEuOTQxODIgMjY5LjE0OCAxLjYwNzQyIDIyNy43NVoiIHN0cm9rZT0iIzdGQUJCNyIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiLz4KPHBhdGggZD0iTTEuNjA3NDIgMjI3Ljc1TDcxLjY3NjIgMjI3Ljc1TDcxLjY3NjIgMTUyLjVDMzMuMTI4NyAxNTIuODU5IDEuOTQxODIgMTg2LjM1MiAxLjYwNzQyIDIyNy43NVoiIHN0cm9rZT0iIzdGQUJCNyIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiLz4KPHBhdGggZD0iTTEuNjA3NDIgNzcuMjQ5OUwxLjYwNzQyIDE1Mi41TDcxLjY3NjIgMTUyLjVDNzEuMzQxOCAxMTEuMTAyIDQwLjE1NDkgNzcuNjA5IDEuNjA3NDIgNzcuMjQ5OVY3Ny4yNDk5WiIgc3Ryb2tlPSIjN0ZBQkI3IiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIvPgo8cGF0aCBkPSJNNzEuNjc2MiAxLjk5OTk2TDcxLjY3NjIgNzcuMjVMMS42MDc0MiA3Ny4yNUMxLjk0MTgyIDM1Ljg1MjEgMzMuMTI4NyAyLjM1OTA5IDcxLjY3NjIgMS45OTk5NloiIHN0cm9rZT0iIzdGQUJCNyIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiLz4KPC9zdmc+Cg==");
    background-position: 100% 0;
    background-size: 62px;
    background-repeat: no-repeat;
  }

  &:before {
    content: "";
    top: -100px;
    right: 0;
    width: 0;
    height: 0;
    border-width: 0 0 100px 100vw;
    border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #fff rgba(0, 0, 0, 0);
    border-style: solid;
    position: absolute;

    @media ($min-md) {
      top: -240px;
      border-width: 0 0 240px 100vw;
    }
  }

  &__container {
    margin: auto;
    max-width: 1180px;
    padding-block: 10px 80px;
    padding-inline: 25px;
    z-index: 1;
    position: relative;
  }

  &__title {
    font-weight: 900;
    font-size: 1.25rem;
    line-height: 1.5rem;
    text-transform: uppercase;
    color: $brand-primary-dark;
    margin-bottom: 32px;

    @media ($min-lg) {
      font-size: 1.625rem;
      line-height: 2rem;
    }
  }

  &__list {
    list-style: none;

    > li {
      counter-increment: item;
      display: flex;
      align-items: center;
      gap: 22px;
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.25rem;
      color: $brand-primary-dark;
      margin-bottom: 32px;
      position: relative;

      @media ($min-lg) {
        font-size: 1.125rem;
        line-height: 1.375rem;
      }
    }

    > li::before {
      content: counter(item);
      border-radius: 50px;
      width: 48px;
      height: 48px;
      background: $support-02-medium;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 700;
      font-size: 1.25rem;
      line-height: 130%;
      color: $neutral-white;
      flex-shrink: 0;
    }

    > li:not(:last-of-type):after {
      position: absolute;
      content: "";
      width: 0;
      height: 18px;
      border: 1px solid $neutral-medium;
      left: 22px;
      top: 54px;
    }
  }

  &__disclaimer {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    color: $neutral-dark;
    padding-inline: 20px;
    text-align: justify;
  }
}

.advantages-lp-v3 {
  background: $neutral-lightest;
  position: relative;

  &:before {
    content: "";
    top: -100px;
    right: 0;
    width: 0;
    height: 0;
    border-width: 0 0 100px 100vw;
    border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) $neutral-lightest
      rgba(0, 0, 0, 0);
    border-style: solid;
    position: absolute;

    @media ($min-md) {
      top: -240px;
      border-width: 0 0 240px 100vw;
    }
  }

  &__container {
    margin: auto;
    max-width: 1180px;
    padding-block: 10px 30px;
    padding-inline: 25px;
  }

  &__title {
    font-weight: 900;
    font-size: 1.25rem;
    line-height: 1.5rem;
    text-transform: uppercase;
    color: $brand-primary-dark;

    @media ($min-md) {
      font-size: 1.625rem;
      line-height: 2rem;
    }

    &:not(&--second) {
      margin-bottom: 30px;
    }
  }

  &__cards-container {
    display: flex;
    gap: 18px;
    margin-bottom: 70px;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__card {
    width: 344px;
    min-height: 278px;
    left: 114px;
    top: 1750px;
    background: $neutral-white;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
    border-radius: 45px 0px 37px 35px;
    padding-inline: 48px;
    padding-block: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 20px;
  }

  &__card-title {
    text-align: center;
    font-weight: 900;
    font-size: 1.1875rem;
    line-height: 1.4375rem;
    text-transform: uppercase;
    color: $brand-primary-dark;
  }

  &__card-description {
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.375rem;
    color: $neutral-dark;
    text-align: center;
  }

  &__content {
    &--dashboard {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }

  &__block-text {
    display: flex;
    align-items: baseline;
    gap: 1rem;
    margin-bottom: 1rem;
  }

  &__symbol-icon {
    > img {
      width: 24px;
      height: 24px;

      @media ($min-md) {
        width: 30px;
        height: 30px;
      }
    }
  }

  &__symbol {
    color: $support-02-medium;
    font-weight: 800;
    font-size: 2rem;
    line-height: 2rem;

    @media ($min-lg) {
      font-size: 2.25rem;
      line-height: 2.75rem;
    }
  }

  &__description {
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: $neutral-dark;
    margin-bottom: 40px;

    @media ($min-lg) {
      font-size: 1.25rem;
      line-height: 1.5rem;
    }
  }

  &__content-bar {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    @media ($min-lg) {
      flex-direction: row;
      align-items: center;
      gap: 24px;
    }
  }

  &__bar {
    width: 96%;
    height: 10px;
    border-radius: 5px;
    overflow: hidden;
    padding: 0;
    background: $neutral-light;
    margin-bottom: 5px;

    @media ($min-lg) {
      margin-bottom: 0;
    }
  }

  &__progress-1 {
    background-color: $support-02-medium-05;
    width: 60%;
    height: 10px;
    border-radius: 5px;
  }

  &__progress-2 {
    background-color: $brand-primary-light;
    width: 77%;
    height: 10px;
    border-radius: 5px;
  }

  &__progress-3 {
    background-color: $brand-primary-light;
    width: 89%;
    height: 10px;
    border-radius: 5px;
  }

  &__progress-4 {
    background-color: $brand-primary-light;
    width: 94%;
    height: 10px;
    border-radius: 5px;
  }

  &__bar-description {
    width: 158px;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 0.9375rem;
    color: $neutral-dark;
  }

  &__items-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1rem;
  }
  &__card-icon {
    height: 68px;
  }
  &__item {
    width: 448px;
  }

  &__item-title {
    font-weight: 700;
    font-size: 1.75rem;
    line-height: 2.125rem;
    color: $support-02-medium-05;
    margin-bottom: 1rem;
  }

  &__item-description {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.8125rem;
    color: $neutral-white;
    margin-bottom: 1rem;
  }
}

.faq-lp-v3 {
  &__container {
    margin: auto;
    max-width: 1180px;
    padding-block: 50px;
    padding-inline: 25px;

    @media ($min-xs) {
      padding-inline: 35px;
    }
  }

  &__title {
    color: $brand-primary-dark;
    font-size: 1.625rem;
    font-weight: 800;
    line-height: 2rem;
    margin-bottom: 1.5rem;
    text-transform: uppercase;

    @media ($min-lg) {
      font-size: 2rem;
      line-height: 2.75rem;
      margin-bottom: 2rem;
    }

    @media ($min-sm) {
      text-align: start;
    }

    &::after {
      color: $support-02-medium;
      content: "?";
      font-size: 2rem;
      font-weight: 800;
      line-height: 3.0625rem;
      margin-left: 10px;
    }
  }

  &__accordion {
    margin-bottom: 20px;

    @media ($min-lg) {
      margin-inline: 25px;
    }

    &[open] .faq-lp-v3__accordion-header {
      &::before {
        transform: rotate(180deg);
      }
    }
  }

  &__accordion-header {
    color: $brand-primary-dark;
    cursor: pointer;
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.375rem;
    list-style: none;
    margin-bottom: 0.5rem;
    margin-inline-start: 8px;
    position: relative;

    &::before {
      content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDE0IDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMTQgMS4xNDMwNkMxNCAxLjQzNTYxIDEzLjg4NTcgMS43MjgxNiAxMy42NTgyIDEuOTUxTDcuODI0OTYgNy42NjQ4OEM3LjM2ODgxIDguMTExNzEgNi42MzE0OSA4LjExMTcxIDYuMTc1MzMgNy42NjQ4OEwwLjM0MjExOCAxLjk1MUMtMC4xMTQwNCAxLjUwNDE4IC0wLjExNDA0IDAuNzgxOTQ0IDAuMzQyMTE4IDAuMzM1MTE5QzAuNzk4Mjc1IC0wLjExMTcwNyAxLjUzNTU5IC0wLjExMTcwNyAxLjk5MTc1IDAuMzM1MTE5TDcuMDAwMTUgNS4yNDEwNUwxMi4wMDg1IDAuMzM1MTE4QzEyLjQ2NDcgLTAuMTExNzA3IDEzLjIwMiAtMC4xMTE3MDcgMTMuNjU4MiAwLjMzNTExOEMxMy44ODU3IDAuNTU3OTU5IDE0IDAuODUwNTEgMTQgMS4xNDMwNloiIGZpbGw9IiNGNUMxMzIiLz4KPC9zdmc+Cg==");
      left: -30px;
      position: absolute;
    }

    &::-webkit-details-marker {
      display: none;
    }
  }

  &__accordion-content {
    text-align: justify;
    color: $neutral-white;
    margin-inline-start: 8px;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: $neutral-dark;
  }

  &__container-button {
    display: flex;
    justify-content: center;
    margin-top: 35px;

    a {
      background: $support-02-medium-05;
      border-radius: 32px;
      color: $brand-primary-medium;
      font-size: 1.125rem;
      font-weight: 800;
      line-height: 38px;
      text-transform: capitalize;
      width: 281px;

      @media ($min-md) {
        width: 334px;
      }
    }
  }

  &__disclaimer {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: $neutral-dark;
    padding-block-start: 35px;
    text-align: justify;
  }
}
