// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

/*
 * Main content containers
 * 1. Make the container full-width with a maximum width
 * 2. Center it in the viewport
 * 3. Leave some space on the edges, especially valuable on small screens
 */
.container {
  width: 100%; /* 1 */
  max-width: calc($max-width + 2rem); /* 1 */
  margin-inline: auto; /* 2 */
  padding-inline: 1rem; /* 3 */
}

// .mobile-only {
// 	@media screen and ($min-xl) {
// 		display: none;
// 	}
// }

// .desktop-only {
// 	@media screen and ($max-xl) {
// 		display: none;
// 	}
// }

span.destaque {
  color: #fcb516;
}
