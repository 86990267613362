.fgts-tutorial {
  &__container {
    margin: auto;
    max-width: 1180px;
    padding-block: 50px;
    padding-inline: 25px;

    @media ($min-md) {
      padding-block: 75px;
    }

    // desktop
    @media ($min-xl) {
      margin-top: 0;
    }

    // mobile
    @media (max-width: $lg) {
      margin-top: 60px;
    }
  }

  &__title {
    font-size: 1.3rem;
    font-weight: 800;
    line-height: 2rem;
    margin-bottom: 2rem;
    text-align: center;
    color: $brand-primary-light-10;
    text-transform: uppercase;

    @media ($min-md) {
      font-size: 2.125rem;
      line-height: 2.5625rem;
    }
  }

  &__subtitle {
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 1.5rem;
    margin-bottom: 1rem;
    text-align: center;
    color: $brand-primary-dark-10;

    @media ($min-md) {
      font-size: 1.5rem;
      line-height: 1.8125rem;
    }
  }

  &__sub-subtitle {
    color: $brand-primary-light-10;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    margin-bottom: 1rem;
    color: $brand-primary-light-10;
    text-align: center;

    @media ($min-md) {
      font-size: 1.25rem;
      line-height: 1.5rem;
    }
  }

  &__card {
    align-items: center;
    counter-increment: item;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    margin: 4rem 0;

    @media ($min-md) {
      flex-direction: row;
      gap: 50px;
      margin: 4rem;
    }
  }

  &__image {
    max-width: 100%;
    height: 380px;

    @media ($min-md) {
      max-width: 100%;
      height: 480px;
    }
  }

  &__content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    @media ($min-md) {
      align-items: baseline;
    }
  }

  &__content-title {
    align-items: center;
    color: $brand-primary-dark-20;
    display: flex;
    flex-direction: column;
    font-size: 1.125rem;
    font-weight: 800;
    line-height: 1.375rem;
    margin-bottom: 1rem;
    text-align: center;
    max-width: 420px;

    @media ($min-md) {
      flex-direction: row;
      font-size: 1.625rem;
      line-height: 2rem;
      text-align: start;
    }

    &::before {
      color: $support-02-medium-05;
      content: counter(item) ".";
      font-size: 2.75rem;
      font-weight: 800;
      line-height: 3.375rem;
      margin-right: 1rem;
    }
  }

  &__content-description {
    align-items: center;
    color: $brand-primary-light-15;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.375rem;

    @media ($min-md) {
      font-size: 1rem;
      line-height: 1.625rem;
    }

    p:empty {
      padding-bottom: 1rem;
    }
  }

  &__store-link {
    margin-top: 15px;
    display: flex;

    &:last-of-type {
      margin-bottom: 15px;
    }

    img {
      max-width: 140px;
    }

    @media ($min-md) {
      display: none;
    }
  }
}
