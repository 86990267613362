.default-form {
  max-width: 690px;

  &__fieldset {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
    border: 0;
    margin-block-start: 1.875rem;

    @media ($min-lg) {
      gap: 0.9375rem;
      margin-top: 10px;
    }

    // mobile
    @media (max-width: $lg){
      margin-top: 12px;
    }
  }

  &__legend {
    color: $neutral-white;
    margin-bottom: 1.5rem;
    font-weight: 900;
    font-size: 1.375rem;
    line-height: 1.3;
    text-transform: uppercase;

    @media ($min-lg) {
      font-size: 2.25rem;
      line-height: 44px;
    }
  }

  &__select {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDE0IDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMTQgMS4xNDMwNkMxNCAxLjQzNTYxIDEzLjg4NTcgMS43MjgxNiAxMy42NTgyIDEuOTUxTDcuODI0OTYgNy42NjQ4OEM3LjM2ODgxIDguMTExNzEgNi42MzE0OSA4LjExMTcxIDYuMTc1MzMgNy42NjQ4OEwwLjM0MjExOCAxLjk1MUMtMC4xMTQwNCAxLjUwNDE4IC0wLjExNDA0IDAuNzgxOTQ0IDAuMzQyMTE4IDAuMzM1MTE5QzAuNzk4Mjc1IC0wLjExMTcwNyAxLjUzNTU5IC0wLjExMTcwNyAxLjk5MTc1IDAuMzM1MTE5TDcuMDAwMTUgNS4yNDEwNUwxMi4wMDg1IDAuMzM1MTE4QzEyLjQ2NDcgLTAuMTExNzA3IDEzLjIwMiAtMC4xMTE3MDcgMTMuNjU4MiAwLjMzNTExOEMxMy44ODU3IDAuNTU3OTU5IDE0IDAuODUwNTEgMTQgMS4xNDMwNloiIGZpbGw9IiMwMDU4NkYiLz4KPC9zdmc+Cg==");
    background-position: right 12px center;
    background-repeat: no-repeat;
    background-color: $neutral-white;
    width: 100%;
    height: 48px;
    padding: 0 1rem;
    border: 1px solid $neutral-lightest;
    border-radius: 10px;
    font-weight: 500;
    font-size: .8rem;
    line-height: 1;
    color: $brand-primary-darkest;
    appearance: none;
    font-family: inherit;

    @media($min-sm) {
      background-position: right 26px center;
    }


    @media ($min-lg) {
      padding: 0 1.25rem;
    }

    &:focus {
      outline: none;
    }
  }

  & .form-group {
    display: flex;
    gap: 1.25rem;
  }

  &__input-container {
    display: flex;
    align-items: center;
    position: relative;

    background: $neutral-white;
    border: 1px solid $neutral-lightest;
    border-radius: 10px;

    cursor: text;

    min-height: 3.125rem;
    padding: 0.5rem 1rem;

    &--search {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMyAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik00LjcxMiA3LjVDMy4wNTUyNSA3LjUgMS43MTIgNi4xNTY3NSAxLjcxMiA0LjVDMS43MTIgMi44NDMyNSAzLjA1NTI1IDEuNSA0LjcxMiAxLjVDNi4zNjg3NSAxLjUgNy43MTIgMi44NDMyNSA3LjcxMiA0LjVDNy43MTIgNi4xNTY3NSA2LjM2ODc1IDcuNSA0LjcxMiA3LjVaTTExLjk5MjMgMTAuNzE5OEw4LjM3NzI2IDcuMTA0NzVDOC45MDA3NiA2LjM2OSA5LjIxMjAxIDUuNDcyIDkuMjEyMDEgNC41QzkuMjEyMDEgMi4wMTUyNSA3LjE5Njc2IDAgNC43MTIwMSAwQzIuMjI3MjYgMCAwLjIxMjAwNiAyLjAxNTI1IDAuMjEyMDA2IDQuNUMwLjIxMjAwNiA2Ljk4NDc1IDIuMjI3MjYgOSA0LjcxMjAxIDlDNS42ODQwMSA5IDYuNTgxMDEgOC42ODg3NSA3LjMxNjc2IDguMTY1MjVMMTAuOTMxOCAxMS43ODAyQzExLjA3OCAxMS45MjY1IDExLjI3IDEyIDExLjQ2MiAxMkMxMS42NTQgMTIgMTEuODQ2IDExLjkyNjUgMTEuOTkyMyAxMS43ODAyQzEyLjI4NTUgMTEuNDg3IDEyLjI4NTUgMTEuMDEzIDExLjk5MjMgMTAuNzE5OFoiIGZpbGw9IiMwRUEwQzAiLz4KPC9zdmc+Cg==");
      background-position: right 18px center;
      background-repeat: no-repeat;
      background-size: 16px;
    }

    @media ($min-lg) {
      min-height: 3rem;
      padding: 0rem 1.25rem;
    }
  }

  &__label {
    font-weight: 500;
    font-size: .8rem;
    line-height: 1;
    color: $brand-primary-darkest;
    z-index: 10;
    transition: all 0.3s;
  }

  &__input {
    position: absolute;
    left: 1.25rem;
    right: 1.25rem;
    border: 0;

    line-height: 1;
    color: $neutral-dark;
    bottom: 0.4rem;
    font-size: 0.875rem;

    @media ($min-lg) {
      font-size: 1rem;
      bottom: 0.3rem;
    }

    &:focus {
      outline: 0;
    }

    &::placeholder {
      opacity: 0;
    }

    &:not(:placeholder-shown)~.default-form__label,
    &:focus~.default-form__label {
      position: relative;
      color: $brand-primary-darkest;
      font-weight: 600;
      font-size: 0.75rem;
      transform: translate(0, -0.75rem);

      @media ($min-lg) {
        transform: translate(0, -0.625rem);
        font-size: 0.625rem;
      }
    }
  }

  &__textarea-container {
    display: block;
    position: relative;
    background: $neutral-white;
    border: 1px solid $neutral-lightest;
    border-radius: 10px;

    min-height: 9.25rem;
    padding: 0.9375rem 1.25rem;

    @media ($min-lg) {
      padding: 1.25rem;
      min-height: 7.3125rem;
    }
  }

  &__textarea {
    position: absolute;
    left: 1.25rem;
    right: 1.25rem;

    border: 0;
    resize: none;
    line-height: 1;
    color: $neutral-dark;

    top: 1.875rem;
    bottom: 0.9375rem;
    font-size: 0.875rem;

    @media ($min-lg) {
      top: 2.5rem;
      bottom: 1.25rem;
      font-size: 1.125rem;
    }

    &:focus {
      outline: 0;
    }

    &:placeholder-shown {
      opacity: 0;
    }

    &:not(:placeholder-shown) {
      opacity: 1;
    }

    &:not(:placeholder-shown)~.default-form__label,
    &:focus~.default-form__label {
      position: absolute;
      left: 1.25rem;
      color: $brand-primary-lightest;
      font-weight: 600;
      top: 0.625rem;
      font-size: 0.75rem;

      @media ($min-lg) {
        font-size: 0.875rem;
        top: 1rem;
      }
    }
  }

  & .has-danger {

    & label,
    & select {
      border-color: $brand-primary-lightest;

      &:focus-within {
        outline-color: $brand-primary-lightest;
      }
    }

    & input,
    & textarea {
      color: $brand-primary-lightest;
    }

    & .pristine-error {
      line-height: 1.2;
      color: $brand-primary-lightest;

      margin-top: 0.375rem;
      margin-left: 0.25rem;
      font-size: 0.75rem;

      @media ($min-lg) {
        margin-top: 0.5rem;
        margin-left: 0.5rem;
        font-size: 1rem;
      }
    }
  }

  &__radio-container {
    display: flex;
    gap: 1.25rem;

    & label {
      width: 100%;
      cursor: pointer;
      font-weight: 500;
      line-height: 1.5;
      color: $neutral-dark;
      font-size: 0.875rem;


      @media ($min-lg) {
        font-size: 1.125rem;

      }
    }

    & input[type="radio"] {
      margin-right: 0.75rem;
      width: 1.375rem;
      height: 1.375rem;
      appearance: none;
      background-color: white;
      border-radius: 50%;
      border: 0.125rem solid $neutral-lightest;
      transform: translateY(-0.075em);
      display: grid;
      place-content: center;
      flex-shrink: 0;

      &:checked::before {
        content: "";
        width: 0.625rem;
        height: 0.625rem;
        border-radius: 50%;
        background-color: $neutral-lightest;
      }
    }
  }

  &__checkbox-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-bottom: 1.75rem;

    & label {
      font-weight: 500;
      line-height: 1;
      color: $neutral-dark;
      font-size: 0.875rem;


      @media ($min-lg) {
        font-size: 1.125rem;

      }
    }

    & input[type="checkbox"] {
      margin-right: 0.75rem;

      &:checked {
        accent-color: $neutral-lightest;
      }
    }
  }

  & button {
    min-width: 100%;
    margin-top: 1.5rem;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 130%;
  }
}