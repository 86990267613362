.extract-tax {
  padding-block: 25px 80px;

  &__container {
    max-width: 1180px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-inline: auto;
    padding-inline: 2rem;
    align-items: center;
  }

  &__title {
    font-weight: 900;
    text-transform: uppercase;
    color: $brand-primary-dark;
    margin-block: 25px;
    font-size: 1.125rem;
    line-height: 1.375rem;

    @media ($min-md) {
      font-size: 1.25rem;
      line-height: 1.5rem;
    }
  }

  &__content {
    display: flex;
    gap: 32px;
    padding: 45px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
    border-radius: 45px 0px 37px 35px;
    width: 100%;
    max-width: 480px;

    // desktop
    @media ($min-xl) {
      padding: 30px;
    }

    // mobile
    @media (max-width: $lg) {
      padding: 10px 20px;
    }
  }

  .formHidden {
    display: none;
  }

  &__form {
    flex: 1 1 0;
    display: flex;
    gap: 18px;
    flex-direction: column;

    > button {
      max-width: 50%;
    }

    & .has-danger {
      & label,
      & select {
        border-color: $feedback-danger-dark;

        &:focus-within {
          outline-color: $feedback-danger-dark;
        }
      }

      & input,
      & textarea {
        color: $feedback-danger-dark;
      }

      & .pristine-error {
        line-height: 1.2;
        color: $feedback-danger-dark;
        margin-top: 0.1rem;
        margin-left: 0.25rem;
        font-size: 0.75rem;

        @media ($min-lg) {
          font-size: 0.8rem;
          margin-left: 0.5rem;
        }
      }
    }
  }

  &__form-legend {
    font-weight: 500;
    color: $neutral-dark;
    font-size: 0.6875rem;
    line-height: 0.875rem;
    margin-bottom: 16px;

    @media ($min-md) {
      font-size: 0.875rem;
      line-height: 1rem;
    }
  }
}
