.footer-pesquisa {
    background-color: $neutral-white;


    &__custom {
        
        border-radius   : 0;

        &--clr-primary-darkest {
            background-color: $brand-primary-darkest;
        }

        @media ($min-xl) {
            border-radius: 143px 0px 0px 0px;
        }
    }

    &__container-logo {
        display       : flex;
        flex-direction: column;
        gap           : 1rem;
        padding-top: 40px;
        
        @media ($min-sm) {
            flex-direction: row;
            padding-inline: 16px;
            padding-block : 16px;
        }

        @media ($min-xl) {
            padding-top: 40px;
            gap           : 2rem;
            color         : $neutral-white;
        }
    }

    &__container {
        display       : flex;
        flex-direction: column;
        gap           : 1rem;
        // padding-inline: 70px;
        padding-block : 35px;

        @media ($min-sm) {
            flex-direction: row;
            padding-inline: 16px;
            padding-block : 16px;
        }

        @media ($min-xl) {
            padding-inline: 80px;
            padding-block: 50px;
            gap           : 5.5rem;
            color         : $neutral-white;
        }


    }

    &__social-content {
        margin: auto;
        width: max-content;
        height: 105px;
        font-size: 15px;
        padding: 10px;
        color: #FFF;
        background     : #053542;
        border-radius  : 60px 0px;
        display        : flex;
        justify-content: center;
        align-items    : center;
        flex-direction : column;
        // padding-inline : 20px;
        gap            : 15px;

        @media($min-sm) {
            width: max-content;
            height: 105px;
        }

    }

    &__social {
        flex: 1 1 0px;

        &--list {
            display   : flex;
            list-style: none;

            >li>a {
                display: flex;
            }
        }

        &--text {
            font-weight: 500;
            font-size  : 0.625rem;
            line-height: 12px;
            color      : $brand-primary-darkest;

            & a {
                color              : $brand-primary-darkest;
                margin-inline-start: .2rem;
            }
        }
    }

    &__service-channel {
        flex: 1 1 0;
        margin-bottom: 10px;
        & h2 {
            font-weight      : 700;
            font-size        : 1rem;
            line-height      : 17px;
            color            : $brand-primary-darkest;
            padding-block-end: 10px;
        }

        &--item {
            // display          : flex;
            flex-direction   : column;
            padding-block-end: 10px;
            & img {
                height: 15px;
            }
            & span,
            a {
                
                height: 25px;
                font-weight: 700;
                font-size  : 1rem;
                line-height: 15px;
                color      : $brand-primary-darkest;
            }

            & a {
                display        : flex;
                text-decoration: none;
                gap            : 5px;
            }

            & p {
                padding-block-start: 5px;
                font-weight        : 500;
                font-size          : 0.825rem;
                line-height        : 12px;
                color              : $brand-primary-darkest;
            }
        }
    }

    &__ombudsman {
        flex: 1 1 0;
        
        & h2 {
            font-weight      : 700;
            font-size        : 1rem;
            line-height      : 17px;
            color            : $brand-primary-darkest;
            padding-block-end: 10px;
        }
        & img {
            height: 15px;
            margin-bottom: 5px;
        }
        &--item {
            // display       : flex;
            flex-direction: column;
            & span,
            a {
                font-weight: 700;
                font-size  : 1rem;
                line-height: 12px;
                color      : $brand-primary-darkest;
            }

            & a {
                margin-top: 6px;
                margin-bottom: 5px;
                display        : flex;
                text-decoration: none;
            }

            >a:first-of-type {
                margin-block-end: 5px
            }

            & p {
                padding-block-start: 5px;
                font-weight        : 500;
                font-size          : 0.825rem;
                line-height        : 12px;
                color              : $brand-primary-darkest;
                ;
            }

        }
        .link{
            padding-block-start: 5px;
            font-weight        : 500;
            font-size          : 0.825rem;
            line-height        : 12px;
            color              : $brand-primary-darkest;
            display: -webkit-inline-box ;
        }
        &--talks {
            display            : flex;
            flex-direction     : column;
            gap                : 5px;
            padding-block-start: 12px;

            & a {
                display    : flex;
                font-size  : 0.725;
                gap        : 8px;
                font-weight: 700;
            }
        }
    }

    &__ombudsman-chat {
        cursor: pointer;
    }

    &__address {
        flex: 1 1 0;

        & h2 {
            font-weight      : 700;
            font-size        : 1rem;
            line-height      : 17px;
            color            : $brand-primary-darkest;
            padding-block-end: 10px;
            margin-bottom: 20px;
        }
        & img{
            height: 15px;
        }
        &--item {
            padding-block-end: 5px;
            display          : flex;
            flex-direction   : column;
            margin-bottom: 12px;
            & img{
                height: 15px;
            }
            & span {
                font-weight: 700;
                font-size  : 0.825rem;
                line-height: 12px;
                color      : $brand-primary-darkest;
            }

            & p {
                font-weight        : 500;
                font-size          : 0.825rem;
                line-height        : 12px;
                color              : $brand-primary-darkest;
                padding-block-start: 5px;
            }

        }
    }

    &__info {
        flex: 1 1 0px;

        & h2 {
            font-weight      : 700;
            font-size        : 1rem;
            line-height      : 17px;
            color            : $brand-primary-darkest;
            padding-block-end: 10px;
            margin-bottom: 20px;
        }
        & img{
            height: 15px;
        }
        &--list {
            list-style: none;

            & a {
                font-weight         : 500;
                font-size           : 14px;
                line-height         : 12px;
                text-decoration: none !important;
                color               : $brand-primary-darkest;
            }
            & li{
                margin-bottom: 10px;
            }
        }
    }
}

// Parametrizador de cores do footer.
#param-footer-color {
    display: none;
}

#blip-chat-open-iframe:not(.opened) {
    display: none !important;
}