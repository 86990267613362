.error {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  min-height: 480px;

  &__container {
    text-align: center;
    max-width: 900px;
    margin-inline: auto;
  }

  &__title {
    color: $brand-primary-medium;
    font-weight: 900;
    margin-bottom: 26px;
    text-transform: uppercase;
    font-size: 1.125rem;
    line-height: 1.375rem;

    @media($min-md) {
      font-size: 1.375rem;
      line-height: 1.6875rem;
    }
  }

  &__subtitle {
    color: $brand-primary-darkest;
    font-weight: 900;
    line-height: 1.75rem;
    margin-bottom: 22px;
    text-transform: uppercase;
    white-space: pre-line;
    font-size: 1.375rem;

    @media($min-md) {
      font-size: 1.625rem;
    }
  }

  &__content {
    font-weight: 500;
    font-size: .75rem;
    line-height: 1rem;
    color: $neutral-dark;
    margin-bottom: 28px;

  }

  &__link {
    font-weight: 800;
    font-size: .75rem;
    line-height: 1.625rem;
    padding: 10px 38px;
    border-radius: 200px;
  }
}