.hero-internas {
  background: linear-gradient(90deg, #075060 60.53%, #0EA0C0 100%);
  border-radius: 0px 0px 130px 0px;
  margin-bottom: 24px;

  @media($min-md) {
    border-radius: 0px 0px 186px 0px;
  }

  &__container {
    display: flex;
    justify-content: flex-end;
    position: relative;
    flex-direction: column-reverse;
    min-height: 280px;

    @media($min-md) {
      flex-direction: row;
    }

  }

 
  &__title {
    font-size: 1.4rem;
    line-height: 2rem;
    font-weight: 900;
    text-transform: uppercase;
    color: #fff;
    margin-bottom: 7px;


    @media ($min-xl) {
      margin-top: 0;
    }

    @media (max-width: $lg){
      margin-top: 60px;
    }

    @media($min-md) {
      font-size: 2.125rem;
      line-height: 2.5625rem;
    }
  }

  &__description {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
    color: $neutral-dark;
  }

  &__text-content {
    display: flex;
    align-items: center;
    flex-grow: 1;
    padding-inline: 16px;

    @media($min-md){
      padding-inline: 32px;
    }

    @media ($min-xl) {
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 1180px;
    }

    >h3{

      // desktop
      @media ($min-xl) {
        font-size: 1.17em;
      }
  
      // mobile
      @media (max-width: $lg){
        font-size: 0.9rem;
      }
    }
  }

  &__picture {
    display: inline-flex;

    @media($min-md) {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 14% 100%);
    }
  }

  &__image {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  &__parallax {
    display: none;
    align-items: flex-end;
    flex-direction: column;
    position: absolute;
    right: 0;
    top: 0;

    @media($min-xl){
      display: flex;
    }

    &>img {
      height: 150px;

      @media ($min-md) {
        height: 180px;
      }
    }
  }
}

.info-internas {

  &:last-of-type {
    margin-bottom: 48px;
  }

  &__container {
    width: 100%;
    max-width: 1180px;
    margin-inline: auto;
    padding-inline: 16px;
    padding-block: 16px;

    @media($min-md) {
      padding-inline: 32px;
      padding-block: 24px;
    }

  }

  
  &__title {
    font-size: 1.125rem;
    line-height: 1.375rem;
    font-weight: 900;
    text-transform: uppercase;
    color: $brand-primary-dark;
    margin-block-end: 9px;

    &:empty {
      display: none;
    }

    @media($min-md) {
      font-size: 24px;
      line-height: 2rem;
      margin-block-end: 18px;
    }
  }

  &__content {
    text-align: justify;
    >p {
      font-weight: 500;
      font-size: .875rem;
      line-height: 1.5rem;
      color: $neutral-dark;
      &:empty {
        padding-block-end: 1rem;
      }
  
      @media($min-md) {
        font-size: 1rem;
        &:empty {
          padding-block-end: 2rem;
        }

      }
  
      a {
        color: $neutral-dark;
        overflow-wrap: break-word;
      }
  
    }
  
    > ul {
      font-weight: 500;
      font-size: .875rem;
      line-height: 1.5rem;
      color: $neutral-dark;
      padding-inline-start: 1rem;
  
      @media($min-md) {
        font-size: 1rem;
        padding-inline-start: 2rem;
      }

      a {
        color: $neutral-dark;
      }
    }
  }

}