/* COOKIES NOVO - INICIO */
.reject-cookies:hover {
  cursor: pointer;
}

.link-cookies {
  color: #00586f !important;
  text-decoration: underline;
}

.text-cookies {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: justify;
  color: #4f4f4f;
}

.container-cookies {
  display: flex;
  justify-content: space-between;
}

.container-text-cookies {
  width: 70%;
}

.btn-cookies {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #0a4a5b !important;
  cursor: pointer;
}

.btn-cookies-management {
  color: #9e9e9e !important;
  margin-right: 20px;
}

.btn-cookies:hover {
  text-decoration: underline;
}

.btn-cookies-management:hover {
  color: #9e9e9e;
  text-decoration: underline;
}

.modal-title {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #0a4a5b;
  text-transform: uppercase;
  padding-left: 10px;
  padding-top: 5px;
}

.btn-modal-close {
  font-size: 40px;
  padding: 0;
  margin: 0;
  line-height: 0;
  padding-top: 20px !important;
  padding-right: 6px !important;
}

.p-policiy-cookies {
  height: 400px;
}

.container-buttons-cookies-modal {
  padding-bottom: 30px;
  padding-top: 30px;
  text-align: center;
}

.modal-padding {
  padding: 25px 50px 0px 50px !important;
}

/* COOKIES NOVO - FIM */

.modal-cookies .nav-tabs .nav-link {
  font-size: 13px;
  color: #3f3f3f;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.modal-cookies .nav-tabs {
  text-align: center;
  margin-bottom: 16px;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.modal-cookies .nav-tabs .nav-link {
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 130%;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #00586f;
}

.modal-cookies .nav-tabs .nav-link.active,
.modal-cookies .nav-tabs .nav-link:hover {
  border-color: transparent;
  border-bottom: 3px solid #003a48;
  color: #003a48;
  text-decoration: none;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.modal-cookies .nav-tabs .nav-link {
  color: #4c4f4f;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 55050;
  display: none;
  overflow: hidden;
  outline: 0;
}

.fading {
  background-color: #000000ad;
}

.modal-cookies .modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}

.modal-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.15);
  border-radius: 20px 0px 20px 20px;
  outline: 0;
}

.modal-dialog, .modal__cookies {
  max-width: 900px;
  margin: 1.75rem auto;
}

.modal-cookies label {
  font-size: 16px;
  line-height: 21px;
  color: #4f4f4f;
  font-weight: 500;
}

.field {
  font-size: 14px;
  text-align: justify;
  margin-bottom: 15px;
}

.tab-pane p {
  font-size: 14px;
  text-align: justify;
  margin-bottom: 10px;
  margin-top: 5px;
  color: #4f4f4f;
}

.aviso-cookies {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  border-top: 1px solid #3f3f3f29;
  z-index: 9999;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  height: 97px;
  box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.15);
}

.small,
small {
  font-size: 80%;
  font-weight: 400;
}

.actions {
  margin-top: 20px;
}

.btn.green.outline {
  border: 1px solid #003a48;
  background-color: #003a48;
  color: #fff;
  padding: 5px 10px;
  border-radius: 50px;
  width: 275px;
  height: 40px;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
}

.btn-save-all-cookies {
  background-color: #fff !important;
  color: #002c37 !important;
}

.btn-save-all-cookies:hover {
  color: #FFF !important;
  border: 2px solid #003a48;
  background-color: #003a48 !important;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.btn.green.outline:hover {
  color: #003a48;
  border: 2px solid #003a48;
  background-color: #fff;
}

// .isFormCookies {
//   display: none;
// }

.isInfoCookies {
  display: none;
}

@media (max-width: 600px) {
  .modal-padding {
    padding: 10px 15px 0px 15px !important;
  }

  .modal-dialog {
    max-width: 360px;
  }

  .modal-cookies label {
    font-size: 14px;
  }

  .tab-pane p {
    font-size: 12px;
  }

  .modal-cookies .nav-tabs .nav-link {
    font-size: 11px;
  }

  .small,
  small {
    font-size: 13px;
    font-weight: 400;
    text-align: justify;
    line-height: 20px;
  }

  .aviso-cookies {
    height: 155px;
  }

  .container-cookies {
    flex-direction: column;
  }

  .container-text-cookies {
    width: 100%;
  }

  .container-buttons-cookies {
    display: flex;
    flex-direction: column;
  }

  .container-buttons-cookies-modal {
    padding-bottom: 20px;
    padding-top: 20px;
  }

  .btn-cookies-management {
    margin-bottom: 5px;
    margin-top: 5px;
    margin-right: 0px;
  }

  .btn-save-all-cookies {
    margin-top: 10px;
  }
}