.banner-header-template {
    background   : $neutral-lightest;
   
    .fundo {
        background   : #DDDEDE;
        border-radius: 0px 0px 180px 0px;

        @media(max-width: $sm) {
            border-radius: 0px 0px 0px 0px;
        }
    }

    .banner-header-template__container--text__button {
        width: fit-content;
    }

    .logo-branco {
        display: none;
    }

    @media (max-width: $sm) {
        border-radius: 0px;

        &__container {
            display       : flex;
            border-bottom : 0px;
            border-radius : 0px !important;
            flex-direction: column-reverse;

            &--text {

                padding-block  : 15px 30px !important;
                padding-left   : 0px !important;
                padding-inline : 40px !important;
                width          : 100% !important;
                display        : flex !important;
                flex-direction : column !important;
                justify-content: space-evenly !important;

                h1 {

                    display       : contents;
                    display       : flex;
                    flex-direction: row-reverse;

                    .banner-header-lp-v4-form__icon {
                        height: fit-content;
                    }

                    span {
                        padding-top: 40px;
                        font-size  : 26px !important;
                    }

                    @media(max-width: 280px) {
                        flex-wrap: wrap
                    }
                }

                p {
                    font-size    : 14px !important;
                    padding-block: 15px 40px  !important;
                    
                }
            }

            &--banner {
                height: auto;
                width        : 100% !important;
                height       : 320px;
                clip-path    : polygon(0 0%, 100% 0%, 100% 100%, 0% 100%) !important;
                background   : rgba(255, 255, 255, 0) !important;
                border-radius: 0px !important;

            }
        }

        &__menu {
            display: none !important;
        }
    }

    &__container {
        display      : flex;
        border-bottom: 1px solid #fff;
        border-radius: 0px 0px 170px;

        &--text {
            padding-inline : 100px;
            padding-left   : 120px;
            width          : 50%;
            display        : flex;
            flex-direction : column;
            justify-content: space-evenly;


            span {
                color      : #00586F;
                font-size  : 21px;
                font-weight: 700;
            }

            h1 {
                span {
                    font-weight: 900;
                    font-size  : 36px;
                    font-family: 'Montserrat';
                    color      : #00586F;
                }

            }

            p {
                font-weight: 500;
                font-size  : 18px;
                font-family: 'Montserrat';
                color      : #0A4A5B;
            }

            &__button {
                background-color   : $support-02-medium-05;
                border-radius      : 32px;
                font-size          : 1rem;
                font-weight        : 800;
                // width: fit-content;
                // padding         : 16px;
                text-transform     : inherit;

                @media ($min-sm) {

                    font-size: 14px;
                    width    : 265px;
                }

                &:focus,
                &:hover,
                &:active {
                    text-decoration: underline;
                }
            }
        }

        &--banner {
            width: 50%;

            clip-path    : polygon(0 0, 100% 0%, 100% 100%, 10% 100%);
            background   : cadetblue;
            border-radius: 0px 0px 168px 0px;

            &--desktop {
                width        : 100%;
                border-radius: 0px 0px 168px 0px;
                height       : 100%;

                @media(max-width: $sm) {
                    display: none;
                }
            }

            &--mobile {
                display: none;

                @media(max-width: $sm) {
                    width        : 100%;
                    border-radius: 0px;
                    height       : auto;
                    display      : block;
                }
            }

            &--detalhe {
                position: absolute !important;
                z-index : 99;
                right   : 0px;
                top     : 40px;
                height  : auto;

                @media (max-width: $sm) {
                    display: none;
                }
            }

            &--card {
                width         : 378px;
                height        : 109px;
                color         : #4F4F4F;
                position      : absolute;
                top           : 43%;
                font-size     : 14px;
                right         : 0;
                background    : #fff;
                padding-inline: 55px;
                padding-block : 20px;
                clip-path     : polygon(0 0, 100% 0%, 100% 100%, 5% 100%);
                font-family   : 'Montserrat';
                font-weight   : 500;

                &--texto {
                    color      : #4F4F4F;
                    font-size  : 14px;
                    font-family: 'Montserrat';
                    font-weight: 500;
                }

                @media(max-width: $sm) {
                    width         : 75%;
                    height        : 100px;
                    top      : 20%;
                    font-size: 10px;
                }
            }
        }
    }

    .banner-header-template__container--banner--detalhe {
        position: absolute !important;
        z-index : 99;
        right   : 0px;
        top     : 40px;
        height  : auto;

        @media (max-width: $sm) {
            display: none;
        }
    }

    &__menu {
        padding-block  : 30px;
        display        : flex;
        justify-content: space-between;
        border-radius  : 0px 0px 186px 0px;
        width          : 80%;
        padding-left   : 120px;

        &-item {
            a {
                font-family    : 'Montserrat';
                font-weight    : 800;
                font-size      : 12px;
                color          : #0A4A5B;
                text-decoration: none;
            }
        }
    }
}


.logo-azul,
.logo-branco {
    @media(max-width: $sm) {
        display: none;
    }
}
.button-saiba-mais-banner--hidden,
.button-saiba-mais--hidden,
.saiba-mais--hidden,
.secao-como__principal--titulo--hidden,
.secao-como__principal--texto-complementar--hidden,
.secao-como__informacao--disclamer--hidden,
.secao-banner-medio__title--hidden,
.carousel-institucional--titulo--hidden,
.secao-banner-medio__link--hidden,
.detalhe-lista--hidden,
.banner-header-template__container--text__button--hidden,
.imagem--hidden,
.banner-header-template__container--banner--card--hidden,
.secao-banner__titulo--disclamer--hidden,
.secao-carrosel__titulo--hidden,
.banner-header-lp-v4-form__icon--hidden,
.secao-itens__banner--frase--hidden {
    display: none;
}
.button-saiba-mais-banner,
.button-saiba-mais{
    text-decoration: none;    
    display: flex;
    align-items: center;
}
.banner-header-template--segundo {

    background: #ededed;

    .logo-azul {
        display: none;
    }

    .fundo {
        background   : linear-gradient(62deg, rgb(7, 80, 96) 0%, rgb(14, 160, 192) 50%);
        border-radius: 0px 0px 180px 0px;

        @media(max-width: $sm) {
            border-radius: 0px 0px 0px 0px;
        }
    }

    .banner-header-template {
        background   : $neutral-lightest;
        border-radius: 0px 0px 180px 0px;

        .logo-branco {
            display: none;
        }

        @media (max-width: $sm) {
            border-radius: 0px;

            &__container {
                display       : flex;
                border-bottom : 0px;
                border-radius : 0px !important;
                flex-direction: column-reverse;

                &--text {

                    padding-block  : 15px 30px !important;
                    padding-left   : 0px !important;
                    padding-inline : 40px !important;
                    width          : 100% !important;
                    display        : flex !important;
                    flex-direction : column !important;
                    justify-content: space-evenly !important;

                    h1 {

                        display       : contents;
                        display       : flex;
                        flex-direction: row-reverse;

                        .banner-header-lp-v4-form__icon {
                            height: fit-content;
                        }

                        span {
                            padding-top: 40px;
                            font-size  : 26px !important;
                        }
                    }

                    p {
                        font-size    : 14px !important;
                        padding-block: 15px 40px  !important;
                    }
                }

                &--banner {
                    width: 100% !important;
                    height: auto;
                    clip-path    : polygon(0 0%, 100% 0%, 100% 100%, 0% 100%) !important;
                    background   : rgba(255, 255, 255, 0) !important;
                    border-radius: 0px !important;

                    &--desktop {
                        width        : 100% !important;
                        border-radius: 0px !important;
                        height       : 100% !important;
                    }
                }
            }

            &__menu {
                display: none !important;
            }
        }

        &__container {
            display      : flex;
            border-bottom: 1px solid #fff;
            border-radius: 0px 0px 170px;

            &--text {
                padding-inline : 100px;
                padding-left   : 120px;
                width          : 50%;
                display        : flex;
                flex-direction : column;
                justify-content: space-evenly;


                span {
                    color      : #fff;
                    font-size  : 21px;
                    font-weight: 700;
                }

                h1 {
                    span {
                        font-weight: 900;
                        font-size  : 36px;
                        font-family: 'Montserrat';
                        color      : #fff;
                    }

                }

                p {
                    font-weight: 500;
                    font-size  : 18px;
                    font-family: 'Montserrat';
                    color      : #fff;
                }

                &__button {
                    background-color   : $support-02-medium-05;
                    border-radius      : 32px;
                    font-size          : 1rem;
                    font-weight        : 800;
                    // width: fit-content;
                    // padding         : 16px;
                    text-transform     : inherit;

                    @media ($min-sm) {

                        font-size: 14px;
                        width    : 265px;
                    }

                    &:focus,
                    &:hover,
                    &:active {
                        text-decoration: underline;
                    }
                }
            }

            &--banner {
                width: 50%;

                clip-path    : polygon(0 0, 100% 0%, 100% 100%, 10% 100%);
                background   : rgba(255, 255, 255, 0);
                border-radius: 0px 0px 168px 0px;

                &--desktop {
                    width        : 100%;
                    border-radius: 0px 0px 168px 0px;
                    height       : 100%;
                }
            }
        }

        .banner-header-template__container--banner--detalhe {
            position: absolute !important;
            z-index : 99;
            right   : 0px;
            top     : 40px;
            height  : auto;

            @media (max-width: $sm) {
                display: none;
            }
        }

        &__menu {
            padding-block  : 30px;
            display        : flex;
            justify-content: space-between;
            border-radius  : 0px 0px 186px 0px;
            width          : 80%;
            padding-left   : 120px;

            &-item {
                a {
                    font-family    : 'Montserrat';
                    font-weight    : 800;
                    font-size      : 12px;
                    color          : #fff;
                    text-decoration: none;
                }
            }
        }
    }
}

.btn-banner {
    @media($min-sm) {
        width: fit-content !important;
    }
}

.banner-header-template--terceiro {
    .logo-branco {
        display: none;
    }

    background : #DDDDDD;

    .fundo {
        background   : #fff;
        border-radius: 0px 0px 180px 0px;

        @media(max-width: $sm) {
            border-radius: 0px 0px 0px 0px;
        }
    }

    .banner-header-template {
        background   : #fff;
        border-radius: 0px 0px 180px 0px;

        .logo-branco {
            display: none;
        }

        @media (max-width: $sm) {
            border-radius: 0px;

            &__container {
                display       : flex;
                border-bottom : 0px;
                border-radius : 0px !important;
                flex-direction: column-reverse;

                &--text {

                    padding-block  : 15px 30px !important;
                    padding-left   : 0px !important;
                    padding-inline : 40px !important;
                    width          : 100% !important;
                    display        : flex !important;
                    flex-direction : column !important;
                    justify-content: space-evenly !important;

                    h1 {

                        display       : contents;
                        display       : flex;
                        flex-direction: row-reverse;

                        .banner-header-lp-v4-form__icon {
                            height: fit-content;
                        }

                        span {
                            padding-top: 40px;
                            font-size  : 26px !important;
                        }
                    }

                    p {
                        font-size    : 14px !important;
                        padding-block: 15px 40px  !important;
                    }
                }

                &--banner {
                    width: 100% !important;
                    height: auto;
                    clip-path    : polygon(0 0%, 100% 0%, 100% 100%, 0% 100%) !important;
                    background   : rgba(255, 255, 255, 0) !important;
                    border-radius: 0px !important;

                    &--desktop {
                        width        : 100% !important;
                        border-radius: 0px !important;
                        height       : 100% !important;
                    }
                }
            }

            &__menu {
                display: none !important;
            }
        }

        &__container {
            display      : flex;
            border-bottom: 1px solid #fff;
            border-radius: 0px 0px 170px;

            &--text {
                padding-inline : 100px;
                padding-left   : 120px;
                width          : 50%;
                display        : flex;
                flex-direction : column;
                justify-content: space-evenly;


                span {
                    color      : #00586F;
                    font-size  : 21px;
                    font-weight: 700;
                }

                h1 {
                    span {
                        font-weight: 900;
                        font-size  : 36px;
                        font-family: 'Montserrat';
                        color      : #00586F;
                    }

                }

                p {
                    font-weight: 500;
                    font-size  : 18px;
                    font-family: 'Montserrat';
                    color      : #0A4A5B;
                }

                &__button {
                    background-color   : #0A4A5B;
                    color              : #fff;
                    border             : 1px solid #0A4A5B;
                    border-radius      : 32px;
                    font-size          : 1rem;
                    font-weight        : 800;
                    // width: fit-content;
                    // padding         : 16px;
                    text-transform     : inherit;

                    @media ($min-sm) {

                        font-size: 14px;
                        width    : 265px;
                    }

                    &:focus,
                    &:hover,
                    &:active {
                        text-decoration: underline;
                    }
                }
            }

            &--banner {
                width: 50%;

                clip-path    : polygon(0 0, 100% 0%, 100% 100%, 10% 100%);
                background   : rgba(255, 255, 255, 0);
                border-radius: 0px 0px 168px 0px;

                &--desktop {
                    width        : 100%;
                    border-radius: 0px 0px 168px 0px;
                    height       : 100%;
                }
            }
        }

        .banner-header-template__container--banner--detalhe {
            position: absolute !important;
            z-index : 99;
            right   : 0px;
            top     : 40px;
            height  : auto;

            @media (max-width: $sm) {
                display: none;
            }
        }

        &__menu {
            padding-block  : 30px;
            display        : flex;
            justify-content: space-between;
            border-radius  : 0px 0px 186px 0px;
            width          : 80%;
            padding-left   : 120px;

            &-item {
                a {
                    font-family    : 'Montserrat';
                    font-weight    : 800;
                    font-size      : 12px;
                    color          : #0A4A5B;
                    text-decoration: none;
                }
            }
        }
    }

}

.banner-header-template--quatro {
    background: #FFFFFF;

    .logo-azul {
        display: none;
    }

    .fundo {
        background   : #0A4A5B;
        border-radius: 0px 0px 180px 0px;

        @media(max-width: $sm) {
            border-radius: 0px 0px 0px 0px;
        }
    }

    .banner-header-template {
        background   : $neutral-lightest;
        border-radius: 0px 0px 180px 0px;

        .logo-branco {
            display: none;
        }

        @media (max-width: $sm) {
            border-radius: 0px;

            &__container {
                display       : flex;
                border-bottom : 0px;
                border-radius : 0px !important;
                flex-direction: column-reverse;

                &--text {

                    padding-block  : 15px 30px !important;
                    padding-left   : 0px !important;
                    padding-inline : 40px !important;
                    width          : 100% !important;
                    display        : flex !important;
                    flex-direction : column !important;
                    justify-content: space-evenly !important;

                    h1 {

                        display       : contents;
                        display       : flex;
                        flex-direction: row-reverse;

                        .banner-header-lp-v4-form__icon {
                            height: fit-content;
                        }

                        span {
                            padding-top: 40px;
                            font-size  : 26px !important;
                        }
                    }

                    p {
                        font-size    : 14px !important;
                        padding-block: 15px 40px  !important;
                    }
                }

                &--banner {
                    width: 100% !important;
                    height: auto;
                    clip-path    : polygon(0 0%, 100% 0%, 100% 100%, 0% 100%) !important;
                    background   : rgba(255, 255, 255, 0) !important;
                    border-radius: 0px !important;

                    &--desktop {
                        width        : 100% !important;
                        border-radius: 0px !important;
                        height       : 100% !important;
                    }
                }
            }

            &__menu {
                display: none !important;
            }
        }

        &__container {
            display      : flex;
            border-bottom: 1px solid #fff;
            border-radius: 0px 0px 170px;

            &--text {
                padding-inline : 100px;
                padding-left   : 120px;
                width          : 50%;
                display        : flex;
                flex-direction : column;
                justify-content: space-evenly;


                span {
                    color      : #fff;
                    font-size  : 21px;
                    font-weight: 700;
                }

                h1 {
                    span {
                        font-weight: 900;
                        font-size  : 36px;
                        font-family: 'Montserrat';
                        color      : #fff;
                    }

                }

                p {
                    font-weight: 500;
                    font-size  : 18px;
                    font-family: 'Montserrat';
                    color      : #fff;
                }

                &__button {
                    background-color   : #fff;
                    color              : #0A4A5B;
                    border             : 1px solid #fff;
                    border-radius      : 32px;
                    font-size          : 1rem;
                    font-weight        : 800;
                    // width: fit-content;
                    // padding         : 16px;
                    text-transform     : inherit;

                    @media ($min-sm) {

                        font-size: 14px;
                        width    : 265px;
                    }

                    &:focus,
                    &:hover,
                    &:active {
                        text-decoration: underline;
                    }
                }
            }

            &--banner {
                width: 50%;

                clip-path    : polygon(0 0, 100% 0%, 100% 100%, 10% 100%);
                background   : rgba(255, 255, 255, 0);
                border-radius: 0px 0px 168px 0px;

                &--desktop {
                    width        : 100%;
                    border-radius: 0px 0px 168px 0px;
                    height       : 100%;
                }
            }
        }

        .banner-header-template__container--banner--detalhe {
            position: absolute !important;
            z-index : 99;
            right   : 0px;
            top     : 40px;
            height  : auto;

            @media (max-width: $sm) {
                display: none;
            }
        }

        &__menu {
            padding-block  : 30px;
            display        : flex;
            justify-content: space-between;
            border-radius  : 0px 0px 186px 0px;
            width          : 80%;
            padding-left   : 120px;

            &-item {
                a {
                    font-family    : 'Montserrat';
                    font-weight    : 800;
                    font-size      : 12px;
                    color          : #fff;
                    text-decoration: none;
                }
            }
        }
    }
}
.banner-header-template--quinto {
    background: #FFFFFF;

    .logo-azul {
        display: none;
    }

    .fundo {
        background   : #0A4A5B;
        border-radius: 0px 0px 180px 0px;

        @media(max-width: $sm) {
            border-radius: 0px 0px 0px 0px;
        }
    }

    .banner-header-template {
        background   : $neutral-lightest;
        border-radius: 0px 0px 180px 0px;

        .logo-branco {
            display: none;
        }

        @media (max-width: $sm) {
            border-radius: 0px;

            &__container {
                display       : flex;
                border-bottom : 0px;
                border-radius : 0px !important;
                flex-direction: column-reverse;

                &--text {

                    padding-block  : 15px 30px !important;
                    padding-left   : 0px !important;
                    padding-inline : 40px !important;
                    width          : 100% !important;
                    display        : flex !important;
                    flex-direction : column !important;
                    justify-content: space-evenly !important;

                    h1 {

                        display       : contents;
                        display       : flex;
                        flex-direction: row-reverse;

                        .banner-header-lp-v4-form__icon {
                            height: fit-content;
                        }

                        span {
                            padding-top: 40px;
                            font-size  : 26px !important;
                        }
                    }

                    p {
                        font-size    : 14px !important;
                        padding-block: 15px 40px  !important;
                    }
                }

                &--banner {
                    width: 100% !important;
                    height: auto;
                    clip-path    : polygon(0 0%, 100% 0%, 100% 100%, 0% 100%) !important;
                    background   : rgba(255, 255, 255, 0) !important;
                    border-radius: 0px !important;

                    &--desktop {
                        width        : 100% !important;
                        border-radius: 0px !important;
                        height       : 100% !important;
                    }
                }
            }

            &__menu {
                display: none !important;
            }
        }

        &__container {
            display      : flex;
            border-bottom: 1px solid #fff;
            border-radius: 0px 0px 170px;

            &--text {
                padding-inline : 100px;
                padding-left   : 120px;
                width          : 50%;
                display        : flex;
                flex-direction : column;
                justify-content: space-evenly;


                span {
                    color      : #fff;
                    font-size  : 21px;
                    font-weight: 700;
                }

                h1 {
                    span {
                        font-weight: 900;
                        font-size  : 36px;
                        font-family: 'Montserrat';
                        color      : #fff;
                    }

                }

                p {
                    font-weight: 500;
                    font-size  : 18px;
                    font-family: 'Montserrat';
                    color      : #fff;
                }

                &__button {
                    background-color   : #F5C132;
                    color              : #0A4A5B;
                    border             : 1px solid #F5C132;
                    border-radius      : 32px;
                    font-size          : 1rem;
                    font-weight        : 800;
                    // width: fit-content;
                    // padding         : 16px;
                    text-transform     : inherit;

                    @media ($min-sm) {

                        font-size: 14px;
                        width    : 265px;
                    }

                    &:focus,
                    &:hover,
                    &:active {
                        text-decoration: underline;
                    }
                }
            }

            &--banner {
                width: 50%;

                clip-path    : polygon(0 0, 100% 0%, 100% 100%, 10% 100%);
                background   : rgba(255, 255, 255, 0);
                border-radius: 0px 0px 168px 0px;

                &--desktop {
                    width        : 100%;
                    border-radius: 0px 0px 168px 0px;
                    height       : 100%;
                }
            }
        }

        .banner-header-template__container--banner--detalhe {
            position: absolute !important;
            z-index : 99;
            right   : 0px;
            top     : 40px;
            height  : auto;

            @media (max-width: $sm) {
                display: none;
            }
        }

        &__menu {
            padding-block  : 30px;
            display        : flex;
            justify-content: space-between;
            border-radius  : 0px 0px 186px 0px;
            width          : 80%;
            padding-left   : 120px;

            &-item {
                a {
                    font-family    : 'Montserrat';
                    font-weight    : 800;
                    font-size      : 12px;
                    color          : #fff;
                    text-decoration: none;
                }
            }
        }
    }
}


.titulo-tempalte {
    background: $neutral-lightest;

    &__container {
        display         : flex;
        border-bottom   : 1px solid #fff;
        padding         : 70px 100px 70px 80px;
        background-color: #fff;
        border-radius   : 0px 0px 0px 171px;

        @media (max-width: $sm) {
            border-bottom   : 20px solid #fff;
            padding         : 0px;
            background-color: #fff;
            border-radius   : 0px 0px 0px 171px;
            flex-direction  : column;
        }

        &--titulo {
            font-family   : "Montserrat";
            font-weight   : 900;
            font-size     : 26px;
            width         : 50%;
            color         : #0a4a5b;
            padding-inline: 40px 80px;
            border-right  : 1px solid #d8d8d8;
            padding-block : 15px;
            text-align    : left;

            @media (max-width: $sm) {
                width        : 100%;
                color        : #0a4a5b;
                border-right : 0px;
                padding-block: 15px;
                font-size     : 20px;
            }
        }

        &--info {
            font-weight   : 500;
            font-size     : 16px;
            font-family   : 'Montserrat';
            padding-inline: 80px;
            padding-block : 15px;
            text-align    : justify;

            @media (max-width: $sm) {
                font-size     : 14px;
                padding-inline: 40px;
                padding-block : 15px 65px;
            }
        }
    }

}

.titulo-tempalte--segundo {
    background: white;

    .titulo-tempalte {


        &__container {
            display         : flex;
            border-bottom   : 1px solid #fff;
            padding         : 70px 100px 70px 80px;
            background-color: #EDEDED;
            border-radius   : 0px 0px 0px 171px;

            @media (max-width: $sm) {
                border-bottom   : 20px solid #fff;
                padding         : 0px;
                background-color: #EDEDED;
                border-radius   : 0px 0px 0px 171px;
                flex-direction  : column;
            }

            &--titulo {
                font-family   : "Montserrat";
                font-weight   : 900;
                font-size     : 26px;
                width         : 50%;
                color         : #0a4a5b;
                padding-inline: 40px 80px;
                border-right  : 1px solid #d8d8d8;
                padding-block : 15px;
                text-align    : left;

                @media (max-width: $sm) {
                    width        : 100%;
                    color        : #0a4a5b;
                    border-right : 0px;
                    font-size     : 20px;
                    padding-block: 15px;
                }
            }

            &--info {
                font-weight   : 500;
                font-size     : 16px;
                font-family   : 'Montserrat';
                padding-inline: 80px;
                padding-block : 15px;
                text-align    : justify;

                @media (max-width: $sm) {
                    font-size     : 14px;
                    padding-inline: 40px;
                    padding-block : 15px 65px;
                }
            }
        }

    }
}

.titulo-tempalte--terceiro {
    background: #F1F1F1;

    .titulo-tempalte {


        &__container {
            display         : flex;
            border-bottom   : 1px solid #fff;
            padding         : 70px 100px 70px 80px;
            background-color: #DDDDDD;
            border-radius   : 0px 0px 0px 171px;

            @media (max-width: $sm) {
                border-bottom   : 0px solid #fff;
                padding         : 0px;
                background-color: #EDEDED;
                border-radius   : 0px 0px 0px 171px;
                flex-direction  : column;
            }

            &--titulo {
                font-family   : "Montserrat";
                font-weight   : 900;
                font-size     : 26px;
                width         : 50%;
                color         : #0a4a5b;
                padding-inline: 40px 80px;
                border-right  : 1px solid #d8d8d8;
                padding-block : 15px;
                text-align    : left;

                @media (max-width: $sm) {
                    font-size     : 20px;
                    width        : 100%;
                    color        : #0a4a5b;
                    border-right : 0px;
                    padding-block: 15px;
                }
            }

            &--info {
                font-weight   : 500;
                font-size     : 16px;
                font-family   : 'Montserrat';
                padding-inline: 80px;
                padding-block : 15px;
                text-align    : justify;

                @media (max-width: $sm) {
                    font-size     : 14px;
                    padding-inline: 40px;
                    padding-block : 15px 65px;
                }
            }
        }

    }
}

.titulo-tempalte--quatro {
    background: #F1F1F1;

    &__container {
        display         : flex;
        border-bottom   : 1px solid #fff;
        padding         : 70px 100px 70px 80px;
        background-color: #fff;
        border-radius   : 0px 0px 0px 171px;

        @media (max-width: $sm) {
            border-bottom   : 20px solid #fff;
            padding         : 0px;
            background-color: #fff;
            border-radius   : 0px 0px 0px 171px;
            flex-direction  : column;
        }

        &--titulo {
            font-family   : "Montserrat";
            font-weight   : 900;
            font-size     : 26px;
            width         : 50%;
            color         : #0a4a5b;
            padding-inline: 40px 80px;
            border-right  : 1px solid #d8d8d8;
            padding-block : 15px;
            text-align    : left;

            @media (max-width: $sm) {
                font-size     : 20px;
                width        : 100%;
                color        : #0a4a5b;
                border-right : 0px;
                padding-block: 15px;
            }
        }

        &--info {
            font-weight   : 500;
            font-size     : 16px;
            font-family   : 'Montserrat';
            padding-inline: 80px;
            padding-block : 15px;
            text-align    : justify;

            @media (max-width: $sm) {
                font-size     : 14px;
                padding-inline: 40px;
                padding-block : 15px 65px;
            }
        }
    }

}

.gn {
    right: 25px !important;
    @media(max-width: $sm){
        right: -0px !important;
    }
}

.gp {
    left: 0px !important;
    @media(max-width: $sm){
        left: 0px !important;
    }
}

.carrosel-vantagens {

    &__button {
        background: #ededed;
    }

    .carousel-institucional__content-text>h3 {
        text-align: center;
    }

    &__texto {
        text-align: center !important;
    }

    .carousel-institucional {
        background: $neutral-lightest;

        &__content {
            height: 100%;

            &-text {
                height         : 100%;
                justify-content: space-around;
                align-items    : center;
            }
        }
    }

    h1 {
        padding-inline: 100px;
        padding-block : 20px;
        font-size     : 26px;
        font-weight   : 900;
        font-family   : 'Montserrat';
        color         : #0A4A5B;

        max-width     : 1180px;
        padding-inline: 1.5rem;
        margin-inline : auto;
        width         : 100%;
        @media(max-width: $sm){
            font-size     : 20px;
        }
    }

    .glider .draggable {
        padding-inline: 70px;

        @media (max-width: $sm) {
            cursor        : grab !important;
            padding-inline: 0px;
        }
    }

    .glider.draggable .glider-slide img {
        @media(max-width: $sm) {
            align-self: center;
            width     : 70px;
            height    : 70px;
        }
    }

    .draggable {
        cursor: default !important;

        @media (max-width: $sm) {
            cursor: grab !important;
        }
    }

    .container {
        max-width: none;
    }

    .glider-contain {
        width         : 100% !important;
        padding-inline: 60px;
        max-width     : 1180px;
        padding-inline: 1.5rem;
        margin-inline : auto;

        @media (max-width: $sm) {
            padding-inline: 0px;
        }
    }

    .glider-next {
        display: none;

        @media (max-width: $sm) {
            display: flex;
        }
    }

    .glider-prev {
        display: none;

        @media (max-width: $sm) {
            display: flex;
        }
    }

    .carousel-institucional:before {
        content: unset;
    }

    .glider-track {
        width          : 100% !important;
        display        : flex !important;
        flex-wrap      : wrap;
        justify-content: center;

        @media (max-width: $sm) {
            width  : max-content !important;
            display: flex !important;

        }
    }

    .carousel-institucional__carousel {
        width           : 344px;
        padding         : 30px;
        height          : 320px !important;
        margin-inline   : 0px 15px;
        margin-block    : 20px;
        background-color: #fff;
        border-radius   : 60px 0px 60px 60px;

        @media ($min-xl) {
            width: 344px !important;
        }

        @media (max-width: $sm) {
            margin-inline: 0px 0px;
        }
    }

}

.carrosel-vantagens--segundo {
    .carrosel-vantagens__button {
        background: #ffffff;
    }

    .carousel-institucional__content-text>h3 {
        text-align: center;
    }

    .carousel-institucional {
        background: white;

        &__content {
            height: 100%;

            &-text {
                height         : 100%;
                justify-content: space-around;
                align-items    : center;
            }
        }
    }

    h1 {
        padding-inline: 100px;
        padding-block : 20px;
        font-size     : 26px;
        font-weight   : 900;
        font-family   : 'Montserrat';
        color         : #0A4A5B;

        max-width     : 1180px;
        padding-inline: 1.5rem;
        margin-inline : auto;
        width         : 100%;
        @media(max-width: $sm){
            font-size     : 20px;
        }
    }

    .glider .draggable {
        padding-inline: 70px;

        @media (max-width: $sm) {
            cursor        : grab !important;
            padding-inline: 0px;
        }
    }

    .glider.draggable .glider-slide img {
        @media(max-width: $sm) {
            align-self: center;
            width     : 70px;
            height    : 70px;
        }
    }

    .draggable {
        cursor: default !important;

        @media (max-width: $sm) {
            cursor: grab !important;
        }
    }

    .container {
        max-width: none;
    }

    .glider-contain {
        width         : 100% !important;
        padding-inline: 60px;
        max-width     : 1180px;
        padding-inline: 1.5rem;
        margin-inline : auto;

        @media (max-width: $sm) {
            padding-inline: 0px;
        }
    }

    .glider-next {
        display: none;

        @media (max-width: $sm) {
            display: flex;
        }
    }

    .glider-prev {
        display: none;

        @media (max-width: $sm) {
            display: flex;
        }
    }

    .carousel-institucional:before {
        content: unset;
    }

    .glider-track {
        width          : 100% !important;
        display        : flex !important;
        flex-wrap      : wrap;
        justify-content: center;

        @media (max-width: $sm) {
            width  : max-content !important;
            display: flex !important;

        }
    }

    .carousel-institucional__carousel {
        width           : 344px;
        padding         : 30px;
        height          : 320px !important;
        margin-inline   : 0px 15px;
        margin-block    : 20px;
        background-color: #fff;
        border-radius   : 60px 0px 60px 60px;
        box-shadow      : 2px 1px 5px 4px #EDEDED;

        @media ($min-xl) {
            width     : 344px !important;
            box-shadow: 2px 1px 5px 4px #EDEDED;
        }

        @media (max-width: $sm) {
            box-shadow   : 2px 1px 5px 4px #EDEDED;
            margin-inline: 0px 0px;
        }
    }
}

.carrosel-vantagens--terceiro {
    background: #f1f1f1;

    &__button {
        background: #f1f1f1;
    }

    .carrosel-vantagens__button {
        background: #f1f1f1;
    }

    .carousel-institucional__content-text>h3 {
        text-align: center;
    }

    .carousel-institucional {
        background: #F1F1F1;

        &__content {
            height: 100%;

            &-text {
                height         : 100%;
                justify-content: space-around;
                align-items    : center;
            }
        }
    }

    h1 {
        padding-inline: 100px;
        padding-block : 20px;
        font-size     : 26px;
        font-weight   : 900;
        font-family   : 'Montserrat';
        color         : #0A4A5B;

        max-width     : 1180px;
        padding-inline: 1.5rem;
        margin-inline : auto;
        width         : 100%;
        @media(max-width: $sm){
            font-size     : 20px;
        }
    }

    .glider .draggable {
        padding-inline: 70px;

        @media (max-width: $sm) {
            cursor        : grab !important;
            padding-inline: 0px;
        }
    }

    .glider.draggable .glider-slide img {
        @media(max-width: $sm) {
            align-self: center;
            width     : 70px;
            height    : 70px;
        }
    }

    .draggable {
        cursor: default !important;

        @media (max-width: $sm) {
            cursor: grab !important;
        }
    }

    .container {
        max-width: none;
    }

    .glider-contain {
        width         : 100% !important;
        padding-inline: 60px;
        max-width     : 1180px;
        padding-inline: 1.5rem;
        margin-inline : auto;

        @media (max-width: $sm) {
            padding-inline: 0px;
        }
    }

    .glider-next {
        display: none;

        @media (max-width: $sm) {
            display: flex;
        }
    }

    .glider-prev {
        display: none;

        @media (max-width: $sm) {
            display: flex;
        }
    }

    .carousel-institucional:before {
        content: unset;
    }

    .glider-track {
        width          : 100% !important;
        display        : flex !important;
        flex-wrap      : wrap;
        justify-content: center;

        @media (max-width: $sm) {
            width  : max-content !important;
            display: flex !important;

        }
    }

    .carousel-institucional__carousel {
        width           : 344px;
        padding         : 30px;
        height          : 320px !important;
        margin-inline   : 0px 15px;
        margin-block    : 20px;
        background-color: #fff;
        border-radius   : 60px 0px 60px 60px;
        box-shadow      : 2px 1px 5px 4px #EDEDED;

        @media ($min-xl) {
            width     : 344px !important;
            box-shadow: 2px 1px 5px 4px #EDEDED;
        }

        @media (max-width: $sm) {
            box-shadow   : 2px 1px 5px 4px #EDEDED;
            margin-inline: 0px 0px;
        }
    }


    .banner-header-template__container--text__button {
        color           : #fff;
        background-color: #0A4A5B !important;
        border          : 1px solid #0A4A5B !important;
    }

}


.secao-itens {
    background-color: #ededed;
    display         : flex;
    flex-wrap       : wrap;
    padding-block   : 45px;

    @media (max-width: $sm) {
        padding-block: 45px 0px;
    }

    &__banner {
        width             : 423px;
        //background-color: #636464;
        margin-right      : 50px;
        border-radius     : 0px 0px 157px 0px;
        height            : 354px;

        @media (max-width: $sm) {
            margin-bottom: 30px;
        }

        &--frase {
            position   : absolute;
            background : white;
            font-size  : 24px;
            font-weight: 900;
            font-family: 'Montserrat';
            padding    : 11px 0px 10px 50px;
            width      : 356px;
            left       : 10rem;
            color      : #00586F;
            clip-path  : polygon(10% 0, 100% 0%, 100% 100%, 0% 100%);
            margin-top : 60px;

            @media (max-width: $sm) {
                width: auto;
                left : 0rem;
            }


        }

        &--imagem {

            width        : inherit;
            height       : 100%;
            border-radius: 0px 0px 157px 0px;

            @media (max-width: $sm) {
                border-radius: 0px 0px 157px 0px;
                height       : 354px;
                width        : 100%;
            }

            @media(max-width : 280px) {
                border-radius: 0px 0px 157px 0px;
                height       : 355px;
                width        : 260px;
            }
        }
    }

    &__item {
        width          : 50%;
        display        : flex;
        flex-direction : column;
        justify-content: space-between;
        max-width      : 1180px;
        padding-inline : 1.5rem;
        margin-inline  : auto;

        @media (max-width: $sm) {
            width          : 100%;
            display        : flex;
            flex-direction : column;
            justify-content: space-between;
            max-width      : 1180px;
            padding-inline : 1.5rem;
            margin-inline  : auto;
        }

        &--detalhe {
            position: absolute;
            right   : 0;

            @media (max-width: $sm) {
                display: none;
            }
        }

        &--lista {
            div {
                display     : flex;
                align-items : center;
                margin-block: 0px;
            }

            span {
                background   : #F5C132;
                padding      : 12px 20px;
                margin-right : 10px;
                border-radius: 40px;
                font-size    : 20px;
                font-weight  : 700;
                color        : #fff;
            }

            h2 {
                font-size  : 18px;
                font-family: 'Montserrat';
                font-weight: 600;
                color      : #0A4A5b;
            }

        }
    }

    &__frase {
        font-weight   : 400;
        font-size     : 14px;
        color         : #4f4f4f;
        font-family   : 'Montserrat';
        padding-block : 60px 20px;
        max-width     : 1180px;
        padding-inline: 1.5rem;
        margin-inline : auto;

        @media(max-width: $sm) {
            font-size: 12px;
        }
    }
}

.secao-itens--segundo {

    background-color: #fff;
    display         : flex;
    flex-wrap       : wrap;
    padding-block   : 45px;

    @media (max-width: $sm) {
        padding-block: 45px 0px;
    }

    &__banner {
        width           : 480px;
        background-color: #636464;
        margin-right    : 50px;
        border-radius   : 0px 0px 157px 0px;
        height          : 354px;

        @media (max-width: $sm) {
            margin-bottom: 70px;
            width        : 325px;
            height       : 300px;
        }

        &--frase {
            position   : absolute;
            background : white;
            font-size  : 24px;
            font-weight: 900;
            font-family: 'Montserrat';
            padding    : 11px 0px 10px 50px;
            width      : 356px;
            left       : 10rem;
            color      : #00586F;
            clip-path  : polygon(10% 0, 100% 0%, 100% 100%, 0% 100%);
            margin-top : 60px;

            @media (max-width: $sm) {
                width: auto;
                left : 0rem;
            }


        }
    }

    &__item {
        width          : 50%;
        display        : flex;
        flex-direction : column;
        justify-content: space-between;
        max-width      : 1180px;
        padding-inline : 1.5rem;
        margin-inline  : auto;

        @media (max-width: $sm) {
            width          : 100%;
            display        : flex;
            flex-direction : column;
            justify-content: space-between;
            max-width      : 1180px;
            padding-inline : 1.5rem;
            margin-inline  : auto;
        }

        &--detalhe {
            position: absolute;
            right   : 0;

            @media (max-width: $sm) {
                display: none;
            }
        }

        &--lista {
            div {
                display     : flex;
                align-items : center;
                margin-block: 0px;
            }

            span {
                background   : #F5C132;
                padding      : 12px 20px;
                margin-right : 10px;
                border-radius: 40px;
                font-size    : 20px;
                font-weight  : 700;
                color        : #fff;
            }

            h2 {
                font-size  : 18px;
                font-family: 'Montserrat';
                font-weight: 600;
                color      : #0A4A5b;
            }

        }
    }

    &__frase {
        font-weight   : 400;
        font-size     : 14px;
        color         : #4f4f4f;
        font-family   : 'Montserrat';
        padding-block : 60px 20px;
        max-width     : 1180px;
        padding-inline: 1.5rem;
        margin-inline : auto;

        @media(max-width: $sm) {
            font-size: 12px;
        }
    }

}

.secao-itens--terceiro {

    background-color: #f1f1f1;
    display         : flex;
    flex-wrap       : wrap;
    padding-block   : 45px;

    @media (max-width: $sm) {
        padding-block: 45px 0px;
    }

    .secao-itens__item--lista span {
        background: #7FABB7;
    }

    &__frase {
        font-weight   : 400;
        font-size     : 14px;
        color         : #4f4f4f;
        font-family   : 'Montserrat';
        padding-block : 60px 20px;
        max-width     : 1180px;
        padding-inline: 1.5rem;
        margin-inline : auto;

        @media(max-width: $sm) {
            font-size: 12px;
        }
    }

    .banner-header-template__container--text__button {
        color           : #fff;
        background-color: #0A4A5B !important;
        border          : 1px solid #0A4A5B !important;
    }
}

.secao-carrosel {
    background-color: #ededed;

    &__titulo {
        font-family   : 'Montserrat';
        font-size     : 26px;
        color         : #0A4A5B;
        font-weight   : 900;
        padding-block : 0px 40px;
        padding-left  : 120px;
        max-width     : 1180px;
        padding-inline: 1.5rem;
        margin-inline : auto;
        @media(max-width: $sm){
            font-size     : 20px;
        }
    }

    .carousel-steps-lp__content-title{
        @media(max-width: $sm){
            flex-direction: row;
                padding-inline: 30px;
    text-align: left;
        }
    }
    .carousel-steps-lp__content-description{
        text-align: left;
    }
    .glider.draggable {
        background   : linear-gradient(360deg, rgb(255, 255, 255) 92%, rgba(255, 255, 255, 0) 50%);
        border-radius: 0px 17% 60px 60px;
    }

    .glider-dots {
        @media(max-width: $sm) {
            margin-top: 15px;
        }
    }

    .glider-contain::before,
    .glider-contain::after {
        content      : "";
        height       : 100%;
        width        : 50px;
        position     : absolute;
        top          : 38px;
        z-index      : 1;
        bottom       : 0;
        left         : -10px;
        border-left  : 10px solid #ffbc00;
        height       : 135px;
        border-radius: 10px 0px 0px 0px;

        @media (max-width: $sm) {
            top: 55px;
        }

        @media (max-width: 280px) {
            top: 0px;
        }
    }

    .carousel-steps-lp .glider-prev {
        left: 10px;

        @media (max-width: $sm) {
            top: 45%;
        }

    }

    .carousel-steps-lp .glider-next {
        right: 10px;

        @media (max-width: $sm) {
            top: 50%;
        }
    }
    .carousel-steps-lp__content{
        max-width: 455px;
    }
    .carousel-steps-lp__content-title::before {
        color: #0A4A5B;
    }

    .carousel-steps-lp__content-description {
        color: #4C4F4F;

        @media(max-width: $sm) {
            padding-inline: 35px;
            padding-bottom: 25px;
        }
    }
}

.secao-carrosel--segundo {
    background-color: #fff;

    &__titulo {
        font-family   : 'Montserrat';
        font-size     : 26px;
        color         : #0A4A5B;
        font-weight   : 900;
        padding-block : 0px 40px;
        padding-left  : 120px;
        max-width     : 1180px;
        padding-inline: 1.5rem;
        margin-inline : auto;
        @media(max-width: $sm){
            font-size     : 20px;
        }
    }
    .carousel-steps-lp__content{
        max-width: 455px;
    }
    .carousel-steps-lp__image {
        margin: 15px;
    }
     .carousel-steps-lp__content-description{
        text-align: left;
    }
    .carousel-steps-lp__content-title{
        @media(max-width: $sm){
            flex-direction: row;
            padding-inline: 30px;
            text-align: left;
        }
    }
    .glider.draggable {
        box-shadow   : 2px 3px 14px -2px #9E9E9E;
        background   : linear-gradient(360deg, rgb(255, 255, 255) 92%, rgba(255, 255, 255, 0) 50%);
        border-radius: 0px 60px 60px 60px;
    }

    .glider-dots {
        @media(max-width: $sm) {
            margin-top: 15px;
        }
    }

    .glider-contain::before,
    .glider-contain::after {
        content      : "";
        height       : 100%;
        width        : 50px;
        position     : absolute;
        top          : 0px;
        z-index      : 1;
        bottom       : 0;
        left         : -10px;
        border-left  : 10px solid #ffbc00;
        height       : 135px;
        border-radius: 10px 0px 0px 0px;

        @media (max-width: $sm) {
            top: 0px;
        }

        @media (max-width: 280px) {
            top: 0px;
        }
    }

    .carousel-steps-lp .glider-prev {
        left: 10px;

        @media (max-width: $sm) {
            top: 45%;
        }

    }

    .carousel-steps-lp .glider-next {
        right: 10px;

        @media (max-width: $sm) {
            top: 45%;
        }
    }

    .carousel-steps-lp__content-title::before {
        color: #0A4A5B;
    }

    .carousel-steps-lp__content-description {
        color: #4C4F4F;

        @media(max-width: $sm) {
            padding-inline: 35px;
            padding-bottom: 25px;
        }
    }
}

.secao-carrosel--terceiro {
    background-color: #f1f1f1;

    &__titulo {
        font-family   : 'Montserrat';
        font-size     : 26px;
        color         : #0A4A5B;
        font-weight   : 900;
        padding-block : 0px 40px;
        padding-left  : 120px;
        max-width     : 1180px;
        padding-inline: 1.5rem;
        margin-inline : auto;
        @media(max-width: $sm){
            font-size     : 20px;
        }
    }
    .carousel-steps-lp__content-title{
        @media(max-width: $sm){
            flex-direction: row;
            padding-inline: 30px;
            text-align: left;
        }
    }
    .carousel-steps-lp__content{
        max-width: 455px;
    }
    .carousel-steps-lp__content-description{
        text-align: left;
    }
    .carousel-steps-lp .carousel-steps-lp-dots .glider-dot.active {
        background: #0a4a5b;
    }

    .glider.draggable {
        background   : linear-gradient(360deg, rgb(255, 255, 255) 92%, rgba(255, 255, 255, 0) 50%);
        border-radius: 0px 17% 60px 60px;
    }

    .glider-dots {
        @media(max-width: $sm) {
            margin-top: 15px;
        }
    }

    .glider-contain::before,
    .glider-contain::after {
        content      : "";
        height       : 100%;
        width        : 50px;
        position     : absolute;
        top          : 38px;
        z-index      : 1;
        bottom       : 0;
        left         : -10px;
        border-left  : 10px solid #0a4a5b;
        height       : 135px;
        border-radius: 10px 0px 0px 0px;

        @media (max-width: $sm) {
            top: 55px;
        }

        @media (max-width: 280px) {
            top: 0px;
        }
    }

    .carousel-steps-lp .glider-prev {
        left: 10px;

        @media (max-width: $sm) {
            top: 45%;
        }

    }

    .carousel-steps-lp .glider-next {
        right: 10px;

        @media (max-width: $sm) {
            top: 45%;
        }
    }

    .carousel-steps-lp__content-title::before {
        color: #0A4A5B;
    }

    .carousel-steps-lp__content-description {
        color: #4C4F4F;

        @media(max-width: $sm) {
            padding-inline: 35px;
            padding-bottom: 25px;
        }
    }
}

.secao-carrosel--quatro {
    background-color: #f1f1f1;

    &--titulo {
        background-color: #ffffff;

        @media(max-width: $sm) {
            background-color: #f1f1f1;
            
        }
    }
    .carousel-steps-lp__content-title{
        @media(max-width: $sm){
            flex-direction: row;
            padding-inline: 30px;
            text-align: left;
        }
    }
    .carousel-steps-lp__content-description{
        text-align: left;
    }
    .carousel-steps-lp__content{
        max-width: 455px;
    }
    &__titulo {
        font-family   : 'Montserrat';
        font-size     : 26px;
        color         : #0A4A5B;
        font-weight   : 900;
        padding-block : 0px 40px;
        padding-left  : 120px;
        max-width     : 1180px;
        padding-inline: 1.5rem;
        margin-inline : auto;
        @media(max-width: $sm){
            font-size     : 20px;
        }
    }

    .carousel-steps-lp .carousel-steps-lp-dots .glider-dot.active {
        background: #0a4a5b;
    }

    .glider.draggable {
        background   : linear-gradient(360deg, rgb(255, 255, 255) 92%, rgba(255, 255, 255, 0) 50%);
        border-radius: 0px 17% 60px 60px;
    }

    .glider-dots {
        @media(max-width: $sm) {
            margin-top: 15px;
        }
    }

    .glider-contain::before,
    .glider-contain::after {
        content      : "";
        height       : 100%;
        width        : 50px;
        position     : absolute;
        top          : 38px;
        z-index      : 1;
        bottom       : 0;
        left         : -10px;
        border-left  : 0px solid #7fabb7;
        height       : 135px;
        border-radius: 10px 0px 0px 0px;

        @media (max-width: $sm) {
            top: 55px;
        }

        @media (max-width: 280px) {
            top: 0px;
        }
    }

    .carousel-steps-lp .glider-prev {
        left: 10px;

        @media (max-width: $sm) {
            top: 45%;
        }

    }

    .carousel-steps-lp .glider-next {
        right: 10px;

        @media (max-width: $sm) {
            top: 45%;
        }
    }

    .carousel-steps-lp__content-title::before {
        color: #7fabb7;
    }

    .carousel-steps-lp__content-description {
        color: #4C4F4F;

        @media(max-width: $sm) {
            padding-inline: 35px;
            padding-bottom: 25px;
        }
    }

    .carousel-steps-lp {
        border-radius: 0px 0px 254px 0px;
        background   : #fff;

        @media(max-width: $sm) {
            border-radius: 0px 0px 230px 0px;

            background: linear-gradient(360deg, rgb(255, 255, 255) 88%, rgba(255, 255, 255, 0) 50%);
        }
    }
}
.secao-carrosel--quinto{
    .carousel-steps-lp__content-title{
        @media(max-width: $sm){
            flex-direction: row;
            padding-inline: 30px;
            text-align: left;
        }
    }
    .secao-carrosel--quinto .carousel-steps-lp .carousel-steps-lp-dots .glider-dot.active{
        background-color: #F5C132 !important;
    }
    .carousel-steps-lp .glider-prev, .carousel-steps-lp .glider-next{
        @media(max-width: $sm){
            top:45%;
        }
    }
    .carousel-steps-lp__content{
        max-width: 455px;
    }
   .carousel-steps-lp__content-description{
        text-align: left;
    }
    background-color: #f1f1f1;

    &--titulo {
        background-color: #ffffff;

        @media(max-width: $sm) {
            background-color: #f1f1f1;
           
        }
    }
    .carousel-steps-lp__content-title{
        @media(max-width: $sm){
            flex-direction: row;
           
        }
    }
    &__titulo {
        font-family   : 'Montserrat';
        font-size     : 26px;
        font-weight   : 900;
        padding-block : 0px 40px;
        padding-left  : 120px;
        max-width     : 1180px;
        padding-inline: 1.5rem;
        margin-inline : auto;
        @media(max-width: $sm){
            font-size     : 20px;
        }
    }

    .glider.draggable {
        background   : linear-gradient(360deg, rgb(255, 255, 255) 92%, rgba(255, 255, 255, 0) 50%);
        border-radius: 0px 17% 60px 60px;
    }

    .glider-dots {
        @media(max-width: $sm) {
            margin-top: 15px;
        }
    }

    .glider-contain::before,
    .glider-contain::after {
        content      : "";
        height       : 100%;
        width        : 50px;
        position     : absolute;
        top          : 38px;
        z-index      : 1;
        bottom       : 0;
        left         : -10px;
        height       : 135px;
        border-radius: 10px 0px 0px 0px;

        @media (max-width: $sm) {
            top: 55px;
        }

        @media (max-width: 280px) {
            top: 0px;
        }
    }

    .carousel-steps-lp .glider-prev {
        left: 10px;

        @media (max-width: $sm) {
            top: 45%;
        }

    }

    .carousel-steps-lp .glider-next {
        right: 10px;

        @media (max-width: $sm) {
            top: 45%;
        }
    }



    .carousel-steps-lp__content-description {
        color: #4C4F4F;

        @media(max-width: $sm) {
            padding-inline: 35px;
            padding-bottom: 25px;
        }
    }

    .carousel-steps-lp {
        border-radius: 0px 0px 254px 0px;
        background   : #fff;

        @media(max-width: $sm) {
            border-radius: 0px 0px 230px 0px;

            background: linear-gradient(360deg, rgb(255, 255, 255) 88%, rgba(255, 255, 255, 0) 50%);
        }
    }
    .carousel-steps-lp__content-title::before{
        color: #00586F;
    }
}
.secao-banner {
    background-color: #ededed;
    height          : 500px;

    @media (max-width: $sm) {
        height: auto;
    }

    &__imagem {
        height          : 400px;
        background-color: #636464;
        border-radius   : 0px 0px 188px 0px;
        position        : absolute;
        width           : 50%;
        margin-top      : 80px;

        @media (max-width: $sm) {
            height            : 250px;
            //background-color: #636464;
            border-radius     : 0px 93px 0px 0px;
            position          : static;
            width             : 100%;
            margin-top        : 0px;
        }

        .banner-header-template__container--banner--desktop {
            @media (max-width: $sm) {
                border-radius: 0px 94px 0px 0px !important;
            }
        }

        &--detalhe {
            position: absolute;
            top     : 15%;
            left    : 0px;

            @media (max-width: $sm) {
                display: none;
            }
        }
    }

    &__titulo {
        min-height      : 405px;
        height          : auto;
        width           : 60%;
        background-color: #D8D8D8;
        border-radius   : 0px 202.5px 0px 0px;
        position        : absolute;
        right           : 0;
        clip-path       : polygon(0 0, 100% 0%, 100% 100%, 5% 100%);
        padding         : 40px 0px 40px 140px;
        display         : flex;
        flex-direction  : column;
        justify-content : space-evenly;

        @media (max-width: $sm) {
            width        : 100%;
            border-radius: 0px;
            position     : static;

            clip-path      : polygon(0 0, 100% 0%, 100% 100%, 0 100%);
            padding        : 0px 0px 40px 0px;
            padding-inline : 1.5rem;
            display        : flex;
            flex-direction : column;
            justify-content: space-evenly;
        }

        &--titulo {


            h1 {
                font-family: 'Montserrat';
                font-size  : 26px;
                font-weight: 900;
                color      : #0A4A5B;
                @media(max-width: $sm){
                    font-size     : 20px;
                }
            }

            h3 {
                font-size  : 18px;
                font-family: 'Montserrat';
                font-weight: 500;
                color      : #0A4A5B;
            }
        }

        &--container {
            display       : flex;
            flex-direction: column;

        }

        &--subtitulo {
            display     : flex;
            align-items : center;
            margin-block: 0px 10px;

            img {
                margin-right: 20px;
            }

            h2 {
                font-size  : 18px;
                font-weight: 800;
                font-family: 'Montserrat';
                color      : #0A4A5B;
            }

            span {
                font-size  : 16px;
                font-weight: 500;
                font-family: 'Montserrat';
                color      : #4f4f4f;
            }
        }

        p {
            font-weight: 400;
            font-size  : 12px;
            font-family: 'Montserrat';
            color      : #4F4F4F;
        }

        .banner-header-template__container--text__button {
            align-self: center;
            width     : 325px;

            @media (max-width: $sm) {
                width: auto;
            }
        }
    }
}

.secao-banner--segundo {

    background-color: #fff;
    height          : 500px;

    @media (max-width: $sm) {
        height: auto;
    }

    &__imagem {
        height          : 400px;
        background-color: #636464;
        border-radius   : 0px 0px 188px 0px;
        position        : absolute;
        width           : 50%;
        margin-top      : 80px;

        @media (max-width: $sm) {
            height            : 320px;
            //background-color: #636464;
            border-radius     : 0px 93px 0px 0px;
            position          : static;
            width             : 100%;
            margin-top        : 0px;
        }

        .banner-header-template__container--banner--desktop {
            @media (max-width: $sm) {
                border-radius: 0px 94px 0px 0px !important;
            }
        }

        &--detalhe {
            position: absolute;
            top     : 15%;

            @media (max-width: $sm) {
                display: none;
            }
        }
    }

    &__titulo {

        min-height      : 405px;
        height          : auto;
        width           : 60%;
        background-color: #D8D8D8;
        border-radius   : 0px 202.5px 0px 0px;
        position        : absolute;
        right           : 0;
        clip-path       : polygon(0 0, 100% 0%, 100% 100%, 5% 100%);
        padding         : 40px 0px 40px 140px;
        display         : flex;
        flex-direction  : column;
        justify-content : space-evenly;

        @media (max-width: $sm) {
            width        : 100%;
            border-radius: 0px;
            position     : static;

            clip-path      : polygon(0 0, 100% 0%, 100% 100%, 0 100%);
            padding        : 40px 0px 40px 0px;
            padding-inline : 1.5rem;
            display        : flex;
            flex-direction : column;
            justify-content: space-evenly;
        }

        &--titulo {


            h1 {
                font-family: 'Montserrat';
                font-size  : 26px;
                font-weight: 900;
                color      : #0A4A5B;
                @media(max-width: $sm){
                    font-size     : 20px;
                }
            }

            h3 {
                font-size  : 18px;
                font-family: 'Montserrat';
                font-weight: 500;
                color      : #0A4A5B;
            }
        }

        &--subtitulo {
            display     : flex;
            align-items : center;
            margin-block: 0px 10px;

            img {
                margin-right: 20px;
            }

            h2 {
                font-size  : 18px;
                font-weight: 800;
                font-family: 'Montserrat';
                color      : #0A4A5B;
            }

            span {
                font-size  : 16px;
                font-weight: 500;
                font-family: 'Montserrat';
                color      : #4f4f4f;
            }
        }

        p {
            font-weight: 400;
            font-size  : 12px;
            font-family: 'Montserrat';
            color      : #4F4F4F;
        }

        .banner-header-template__container--text__button {
            align-self: center;
            width     : 325px;

            @media (max-width: $sm) {
                width: auto;
            }
        }
    }

}

.secao-banner--terceiro {

    background-color: #f1f1f1;
    height          : 500px;

    @media (max-width: $sm) {
        height: auto;
    }

    &__imagem {
        height          : 400px;
        background-color: #636464;
        border-radius   : 0px 0px 188px 0px;
        position        : absolute;
        width           : 50%;
        margin-top      : 80px;

        @media (max-width: $sm) {
            height            : 320px;
            //background-color: #636464;
            border-radius     : 0px 93px 0px 0px;
            position          : static;
            width             : 100%;
            margin-top        : 0px;
        }

        .banner-header-template__container--banner--desktop {
            @media (max-width: $sm) {
                border-radius: 0px 94px 0px 0px !important;
            }
        }

        &--detalhe {
            position: absolute;
            top     : 15%;

            @media (max-width: $sm) {
                display: none;
            }
        }
    }

    .banner-header-template__container--text__button {
        color           : #fff;
        background-color: #0A4A5B !important;
        border          : 1px solid #0A4A5B !important;
    }

}

.template-duvidas {
    background   : #ededed;
    padding-block: 40px;

    .financiamento-solar-faq__title {
        font-size  : 26px;
        font-weight: 900;
        font-family: 'Montserrat';
        color      : #0A4A5B;
        @media(max-width: $sm){
            font-size     : 20px;
        }
    }

    .accordion__header,
    .accordion__description {
        background-color: #ededed !important;
    }

    .financiamento-solar-faq__accordion-container .accordion .accordion__header {
        font-size: 18px;

        @media(max-width: $sm) {
            font-size: 16px;
        }
    }

    .financiamento-solar-faq__accordion-container .accordion__description {
        font-size: 14px;

        @media(max-width: $sm) {
            font-size: 14px;
        }
    }
}

.template-duvidas--segundo {
    background   : #fff;
    padding-block: 40px;

    .financiamento-solar-faq__title {
        font-size  : 26px;
        font-weight: 900;
        font-family: 'Montserrat';
        color      : #0A4A5B;
        @media(max-width: $sm){
            font-size     : 20px;
        }
    }

    .accordion__header,
    .accordion__description {
        background-color: #fff !important;
    }

    .financiamento-solar-faq__accordion-container .accordion .accordion__header {
        font-size: 18px;

        @media(max-width: $sm) {
            font-size: 16px;
        }
    }

    .financiamento-solar-faq__accordion-container .accordion__description {
        font-size: 14px;

        @media(max-width: $sm) {
            font-size: 14px;
        }
    }
}

.template-duvidas--terceiro {
    background   : #f1f1f1;
    padding-block: 40px;

    .financiamento-solar-faq__title {
        font-size  : 26px;
        font-weight: 900;
        font-family: 'Montserrat';
        color      : #0A4A5B;
        @media(max-width: $sm){
            font-size     : 20px;
        }
    }

    .accordion__header,
    .accordion__description {
        background-color: #f1f1f1 !important;
    }

    .financiamento-solar-faq__accordion-container .accordion .accordion__header {
        font-size: 18px;

        @media(max-width: $sm) {
            font-size: 16px;
        }
    }

    .financiamento-solar-faq__accordion-container .accordion__description {
        font-size: 14px;

        @media(max-width: $sm) {
            font-size: 14px;
        }
    }
}

.secao-disclamer {
    background-color: #ededed;
    padding-bottom  : 20px;

    p {
        max-width     : 1180px;
        padding-inline: 1.5rem;
        margin-inline : auto;
    }
}

.secao-disclamer--terceiro {
    background-color: #f1f1f1;
    padding-bottom  : 20px;

    p {
        max-width     : 1180px;
        padding-inline: 1.5rem;
        margin-inline : auto;
    }
}


.secao-banner-medio {
    background: linear-gradient(90deg, #053542 0%, #00586F 100%);

    @media ($min-md) {
        background: linear-gradient(#f1f1f1, #f1f1f1) no-repeat border-box,
            radial-gradient(111.03% 245.85% at 47.61% -10.77%,
                #053542 0,
                $brand-primary-medium 55.65%) no-repeat border-box;
        background-color   : #f1f1f1;
        background-origin  : padding-box, padding-box;
        background-position: 0 0, 0 4%;

        background-size: 100% 4%, 100% 97%;
    }

    &__container {
        // padding-block: 25px;
        align-items   : center;
        display       : flex;
        flex-direction: column;
        margin        : auto;
        max-width     : 1180px;
        padding-inline: 25px;

        @media ($min-lg) {
            flex-direction: row;
        }
    }

    &__text-content {
        margin-block : 25px;
        margin-inline: 8px;
    }

    &__title {
        color      : $neutral-white;
        font-size  : 32px;
        font-weight: 900;
        line-height: 39.01px;
        font-family: 'Montserrat';
        @media(max-width: $sm){
            font-size     : 20px;
        }
    }

    &__description {
        color      : $neutral-white;
        font-size  : 18px;
        font-weight: 500;
        line-height: 24px;
        font-family: 'Montserrat';
        margin-block: 20px;
    }

    &__img-mobile {
        margin-bottom: 25px;

        @media ($min-md) {
            display: none;
        }
    }
    &__link{
        margin-top: 65px;

        @media(max-width: $sm){
            margin-top: 0px;
        }
    }
    picture {
        display      : flex;
        border-radius: 109px 0px 107px 105px;

        img {
            max-width    : 320px;
            border-radius: 109px 0px 107px 105px;
            
            @media ($min-xs) {
                max-width: none;
            }
            @media(max-width: $sm){
                margin-bottom: -25px;
            z-index: 2;
            }
        }
    }
}
.carrossel-secundario{
    .glider-dot.active{
        background: #4F9CB0;
    }
    h2{
        max-width: 1180px;
        padding-inline: 1.5rem;
        margin-inline: auto;
        color:#0A4A5B;
        font-size:26px;
        font-weight: 900;
        font-family: 'Montserrat';
        margin-block: 25px;
        @media(max-width: $sm){
            margin-top: 35px;
            font-size: 20px;
        }
        
    }
    .carousel-institucional__carousel img{
        clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 0);
        border-radius: 60px 0px 60px 60px;
        @media(max-width: $sm){
            width: 250px;
            height: 160px;
        }
    }
    .carousel-institucional__content-text>p{
        @media(max-width: $sm){
            text-align: left;
        }
    }
    
    .carousel-institucional:before{
        content: none;
    }
    .carousel-institucional{
        background-color: #f1f1f1;
    }
    .carousel-institucional__carousel{
        justify-content: space-between
 
    }
    .carousel-institucional__container .glider-contain .glider-prev {
        left: 33%;
        @media(max-width: $sm){
            left:    0px;
        }
    }
    .carousel-institucional__container .glider-contain .glider-next{
        right: -20px;
        @media(max-width: $sm){
            right: 0px;
        }
    }
    .carousel-institucional__content-text>h3{
        text-align: start;
    }
    .glider-dots{
        margin-top: 20px;
    }
}
.secao-como{
    background-color: #f1f1f1;
    padding-block: 35px;
    .secao-como--fundo{
        max-width: 1180px;
        padding-inline: 1.5rem;
        margin-inline: auto;
        display: flex;
        align-items: flex-start;
        @media(max-width : $sm){
            flex-direction: column;
        }
    }
    &__principal{
        width: 40%;
        height: 390px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @media(max-width : $sm){
            width: 100%;
            padding-block-end: 40px;
        }
        img{
            @media(max-width: $sm){    align-self: center;}
            
        }
        h1{
            font-size: 26px;
            font-weight: 900;
            font-family: 'Montserrat';
            color: #0A4A5B;
        }
        div{
            h2{
                font-size: 18px;
                font-weight: 700;
                color: #0A4A5B;
                font-family: 'Montserrat';
            }
            span{
                font-size: 18px;
                font-weight: 500;
                font-family: 'Montserrat';
                color: #0A4A5B;
            }
        }
    }
    &__informacao{
        .banner-header-template__container--text__button {
            color: #fff;
            background-color: #0a4a5b ;
            border: 1px solid #0a4a5b ;
        }
        width: 60%;
        @media(max-width : $sm){
            width: 100%;
        }
        &--itens{
            display: flex;
            margin-bottom: 30px;
            @media(max-width : $sm){
                flex-direction: column;
            }
            div{
                padding-inline: 0px 15px;
                @media(max-width : $sm){
                    padding-block: 10px;
                }
                h1{
                    color: #7FABB7;
                    font-size: 36px;
                    font-weight: 800;
                    font-family: 'Montserrat'; 
                }
                h2{
                    color: #0A4A5B;
                    font-size: 20px;
                    font-weight: 800;
                    font-family: 'Montserrat';
                }
                p{
                    color: #1A1A1A;
                    font-size: 14px;
                    font-family: 'Montserrat';
                    font-weight: 400;
                }
                }
            }
        &--disclamer{
            color: #1A1A1A;
            font-size: 14px;
            font-family: 'Montserrat';
            font-weight: 400;
        }
    }
   
}
.secao-como-quinto{
    .secao-como{
        background-color: #f1f1f1;
        padding-block: 35px;
        .secao-como--fundo{
            max-width: 1180px;
            padding-inline: 1.5rem;
            margin-inline: auto;
            display: flex;
            align-items: flex-start;
            @media(max-width : $sm){
                flex-direction: column;
            }
        }
        &__principal{
            width: 40%;
            height: 390px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            @media(max-width : $sm){
                width: 100%;
                padding-block-end: 40px;
            }
            img{
                @media(max-width: $sm){    align-self: center;}
                
            }
            h1{
                font-size: 26px;
                font-weight: 900;
                font-family: 'Montserrat';
                color: #0A4A5B;
            }
            div{
                h2{
                    font-size: 18px;
                    font-weight: 700;
                    color: #0A4A5B;
                    font-family: 'Montserrat';
                }
                span{
                    font-size: 18px;
                    font-weight: 500;
                    font-family: 'Montserrat';
                    color: #0A4A5B;
                }
            }
        }
        &__informacao{
            .banner-header-template__container--text__button {
                color: #0A4A5B;
                background-color: #F5C132 ;
                border: 1px solid  #F5C132 ;     
            }
            width: 60%;
            @media(max-width : $sm){
                width: 100%;
            }
            &--itens{
                display: flex;
                margin-bottom: 30px;
                @media(max-width : $sm){
                    flex-direction: column;
                }
                div{
                    padding-inline: 0px 15px;
                    @media(max-width : $sm){
                        padding-block: 10px;
                    }
    
                    h1{
                        color: #7FABB7;
                        font-size: 36px;
                        font-weight: 800;
                        font-family: 'Montserrat'; 
                    }
                    h2{
                        color: #0A4A5B;
                        font-size: 20px;
                        font-weight: 800;
                        font-family: 'Montserrat';
                    }
                    p{
                        color: #1A1A1A;
                        font-size: 14px;
                        font-family: 'Montserrat';
                        font-weight: 400;
                    }
                    }
                }
            &--disclamer{
                color: #1A1A1A;
                font-size: 14px;
                font-family: 'Montserrat';
                font-weight: 400;
            }
        }
    }
}
.linha{
    margin-right: 50px;
    margin-top: 105px;
    width: 1px;
    height: 125px;
        border: 1px solid #7FABB7;
        @media(max-width: $sm){
         display: none;
        }
}
.imagem-como{
    align-self: center;
    width: fit-content;
    @media(max-width: $sm){
        
       }
}
.secao-carrosel-cards{
    background: #f1f1f1;

    .fundo{
        display: flex;
    max-width: 1180px;
    padding-inline: 1.5rem;
    margin-inline: auto;
    @media(max-width: $sm){
        flex-direction: column;

    }
    }

    .carrosel-vantagens__texto{
        text-align: left !important;
        font-size: 14px;
        font-family: 'Montserrat';
        @media(max-width: $sm){
            font-size: 16px;
            line-height: 18px;
        }
    }
    &--titulo{
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            text-align-last: center;
            text-align-last: center;
            flex-direction: column;
            height: 400px;
            background: #f1f1f100;
            @media(max-width: $sm){
                height: auto;   
                padding-block: 40px;
            }
        h1{
            font-family: 'Montserrat';
            font-weight: 800;
            font-size: 24px;
            color: #0A4A5B;
            text-align: left;
            line-height: 29.26px;

        }
        a{
            color: #fff;
            background-color: #0a4a5b ;
            border: 1px solid #0a4a5b ;     
        }
    }   
    .carrosel-vantagens--terceiro{
        background: #ffffff00;
    }
    .carrosel-vantagens--terceiro .carousel-institucional{
        background: #f1f1f100;
    }
    .carrosel-vantagens--terceiro .glider-contain{
        width: 980px !important;
        @media(max-width: $sm){
            width: 100% !important;
        }
    }
    .carrosel-vantagens--terceiro .glider-track{
        width: calc(100% + 60px) !important;
        display: -webkit-box !important;
        flex-wrap: nowrap !important;
        justify-content: flex-start; 

    }
    
    .carrosel-vantagens--terceiro .glider-next,
    .carrosel-vantagens--terceiro .glider-prev{
        display: block;
    }
    .carrosel-vantagens--terceiro .carousel-institucional__carousel{
        // !important;
        // @media(max-width: $sm) {
        //     width: 85% !important;
        // }
        @media($min-sm){
            width: 290px !important
        }
    }
    .saiba-mais{

        @media(max-width: $sm ){
            
        height: 16px !important;
        width: 16px !important;
        }
    }
    .mobile-btn{
        color: #fff;
        background-color: #0a4a5b ;
        border: 1px solid #0a4a5b ;     
        display: none;
        @media(max-width: $sm){
            display: block;
            margin-block: 20px 40px;
        }
    }
    .desktop-btn{
        display: block;
        @media(max-width: $sm){
            display: none;
            
        }
    }
    
    .carousel-institucional__content-text{
        align-items: start;
        @media(max-width: $sm){
            padding-inline: 50px;
        }
    }
}

.secao-carrosel-cards--quinto{
    background: #f1f1f1;

        .fundo{
            display: flex;
        max-width: 1180px;
        padding-inline: 1.5rem;
        margin-inline: auto;
        @media(max-width: $sm){
            flex-direction: column;
    
        }
        }
     
        &--titulo{
                display: flex;
                align-items: center;
                justify-content: space-evenly;
                text-align-last: center;
                text-align-last: center;
                flex-direction: column;
                height: 400px;
                background: #f1f1f100;
                @media(max-width: $sm){
                    height: auto;   
                    padding-block: 40px;
                }
            h1{
                font-family: 'Montserrat';
                font-weight: 800;
                font-size: 24px;
                color: #0A4A5B;
                text-align: left;
                line-height: 29.26px;
    
            }
            a{
                color: #0A4A5B;
                background-color: #F5C132 ;
                border: 1px solid  #F5C132 ;        
            }
        }   
        .carrosel-vantagens--terceiro{
            background: #ffffff00;
        }
        .carrosel-vantagens--terceiro .carousel-institucional{
            background: #f1f1f100;
        }
        .carrosel-vantagens--terceiro .glider-contain{
            width: 980px !important;
            @media(max-width: $sm){
                width: 100% !important;
            }
        }
        .carrosel-vantagens--terceiro .glider-track{
            width: calc(100% + 60px) !important;
            display: -webkit-box !important;
            flex-wrap: nowrap !important;
            justify-content: flex-start; 
    
        }
        
        .carrosel-vantagens--terceiro .glider-next,
        .carrosel-vantagens--terceiro .glider-prev{
            display: block;
        }
        .carrosel-vantagens--terceiro .carousel-institucional__carousel{
            // !important;
            // @media(max-width: $sm) {
            //     width: 85% !important;
            // }
            @media($min-sm){
                width: 290px !important
            }
        }
        .saiba-mais{
    
            @media(max-width: $sm ){
                
            height: 18px !important;
            width:  16px !important;
            }
        }
        .mobile-btn{
            color: #0A4A5B;
            background-color: #F5C132 ;
            border: 1px solid  #F5C132 ;      
            display: none;
            @media(max-width: $sm){
                display: block;
                margin-block: 20px 40px;
            }
        }
        .desktop-btn{
            display: block;
            color: #0A4A5B;
                background-color: #F5C132 ;
                border: 1px solid  #F5C132 ;    
            @media(max-width: $sm){
                display: none;
                
            }
        }
        .carousel-institucional__content-text{
            margin: 0px 0px;
        }
        .carrosel-vantagens__texto{
            text-align: left !important;
            font-size: 14px;
            font-family: 'Montserrat';
            @media(max-width: $sm){
                font-size: 16px;
                line-height: 18px;
            }
        }
        .carousel-institucional__content-text{
            align-items: start;
            @media(max-width: $sm){
                padding-inline: 50px;
            }
        }
    
}
.saiba-mais-texto{
    font-size: 14px;
    font-family: 'Montserrat';
    color: #00586F;
    font-weight: 800;
    margin-right: 10px;    
   
}
.saiba-mais-texto-banner{
    font-size: 14px;
    font-family: 'Montserrat';
    color: #FFFFFF;
    font-weight: 800;
    margin-right: 10px; 
}
.carrossel-secundario--quinto{
   
    .glider-dot.active{
        background: #F5C132;
    }
        h2{
            max-width: 1180px;
            padding-inline: 1.5rem;
            margin-inline: auto;
            color:#0A4A5B;
            font-size:26px;
            font-weight: 900;
            font-family: 'Montserrat';
            margin-block: 25px ;
            @media(max-width: $sm){
                margin-top: 35px;
            }
            
        }
        .carousel-institucional__carousel img{
            clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 0);
            border-radius: 60px 0px 60px 60px;
            @media(max-width: $sm){
                width: 250px;
                height: 160px;
            }
        }
        .carousel-institucional__content-text>p{
            @media(max-width: $sm){
                text-align: left;
            }
        }
        
        .carousel-institucional:before{
            content: none;
        }
        .carousel-institucional{
            background-color: #f1f1f1;
        }
        .carousel-institucional__carousel{
            justify-content: space-between
     
        }
        .carousel-institucional__container .glider-contain .glider-prev {
            left: 33%;
            @media(max-width: $sm){
                left:    0px;
            }
        }
        .carousel-institucional__container .glider-contain .glider-next{
            right: -20px;
            @media(max-width: $sm){
                right: 0px;
            }
        }
        .carousel-institucional__content-text>h3{
            text-align: start;
        }
        .glider-dots{
            margin-top: 20px;
        }
    
    .carousel-institucional__container .glider-contain .carousel-institucional-dots .glider-dot.active{
        background-color: #F5C132 !important;
    }
}
.btn-color{
    background-color: #F5C132 !important;
    border-color: #F5C132 !important;
    color: #0A4A5B !important;
    
} 
.btn-color2{
    background-color: #0A4A5B !important;
    border-color: #0A4A5B !important;
    color: #ffffff !important;
}
.btn-color3{
    background-color: #ffffff !important;
    border-color: #ffffff !important;
    color: #0A4A5B !important;
} 


.fundo-color{
    background-color: #EDEDED !important;
} 
.fundo-color2{
    background-color: #fff !important;
} 
.fundo-color3{
    background-color: #DDDDDD !important;
}

.container-color{
    background-color: #fff !important;
}
.container-color2{
    background-color: #EDEDED !important;
}
.container-color3{
    background-color: #DDDDDD !important;
}

.item-color{
    background-color: #F5C132 !important;
    border-color: #F5C132 !important;
}
.item-color2{
    background-color: #7FABB7 !important;
    border-color: #7FABB7 !important;
}
.banenr-fundo{
    background-color: #d8d8d8 !important;
}

.banenr-fundo2{
    background-color: #EDEDED !important;
}

.color-text{
    color: #00586F !important;
}
.color-text2{
    color: #ffffff !important;
}
.faixa-lateral{
    .glider-contain::before, .glider-contain::after{
        border-left: 10px solid #FCB516 !important;
    }
    
     .glider-dot.active {
        background:  #FCB516 !important;
    }
}
.faixa-lateral2{
    .glider-contain::before, .glider-contain::after{
        border-left: 10px solid #0A4A5B !important;
    }
    
     .glider-dot.active {
        background: #0a4a5b !important;
    }
}